import React, { useState, useCallback, useEffect, useRef } from 'react'
import { antdFormError, borderColumnColor, borderColumnErrorColor, borderColumnWidth, columnDefaultDisposition, columnsDispositions, containerBackground, ItemTypes, pageBackground, roundButtonWidth, viewerTypes } from '../../constants/constants'
import AdderColumns from '../Container/AdderColumns'
import { useStore } from '../../store/store'
import { useDrop } from 'react-dnd'
import { isAOneRowBox, isATableBox } from '../../utils/boxes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button } from 'antd'
import DeleteButton from '../Table/DeleteButton'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'

const getWidthAndPosition = ({ position, columnIndex, willBoxBeReplaced, containerColumnsLength }) => {
  if (position === "left") {
    if (columnIndex === 0 ||
      willBoxBeReplaced) {
      return borderColumnWidth
    } else {
      return 2
    }
  } else {
    if (columnIndex + 1 === containerColumnsLength ||
      willBoxBeReplaced) {
      return borderColumnWidth
    } else {
      return 2
    }
  }
}

const borderCommonStyle = ({
  isVisible,
  position,
  willBoxBeReplaced,
  boxCanDropInColumn,
  isDragging,
  containerColumnsLength,
  columnIndex,
}) => {
  const style = {
    position: 'absolute',
    height: "100%",
    backgroundColor: boxCanDropInColumn || !isDragging ? borderColumnColor : borderColumnErrorColor,
    top: 0,
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 200ms ease',
    display: "flex"
  }

  if (position === "left") {
    if (columnIndex === 0 ||
      willBoxBeReplaced) {
      style.width = borderColumnWidth
    } else {
      style.width = 2
    }
  } else {
    if (columnIndex + 1 === containerColumnsLength ||
      willBoxBeReplaced) {
      style.width = borderColumnWidth
    } else {
      style.width = 2
    }
    style.right = 0
  }

  return style
}

const selector = (columnId) => ({
  containers,
  updateColumnsInContainer,
  selectedBoxId,
  boxes,
  boxVariableSelectedId,
  currentProposal,
  selectedContainer,
  updateContainer,
  deleteColumns,
  columns
}) => {
  const container = containers.find((c) => c.columnsIds.includes(columnId));
  // const box = boxes?.find((b) => b.columnId === columnId);
  return {
    container,
    updateColumnsInContainer,
    columnIndex: container.columnsIds.findIndex((id) => id === columnId),
    isBoxSelected: !!selectedBoxId && boxes.some((b) => selectedBoxId === b.id && container.columnsIds.includes(columnId)),
    isATableBoxSelected: !!selectedBoxId && isATableBox(boxes.find(b => b.id === selectedBoxId)?.type),
    boxVariableSelectedId,
    currentProposal,
    selectedBoxId,
    boxes,
    box: boxes.find((b) => b.columnId === columnId),
    isContainerSelected: selectedContainer && selectedContainer.id === container.id,
    selectedContainer,
    updateContainer,
    deleteColumns,
    column: columns.find((c) => c.id === columnId),
    columnsInContainer: columns.filter((c) => container.columnsIds.includes(c.id)),
  }
};

const BorderColumn = ({
  position,
  willBoxBeReplaced,
  containerColumnsLength,
  columnId,
  dropCommonProps,
  isContainerHovered,
  boxCanDropInColumn,
  hasAOneRowBox,
  setControlCol,
  setControlActionHovered,
  setDeleteButtonHovered,
  controlCol,
  deleteButtonHovered,
  controlActionHovered,
  isLastColumn,
  isFirstColumn,
  isMiddleColumn,
  setControlRowClicked,
  fromViewer

}) => {
  const {
    columnIndex,
    container,
    updateColumnsInContainer,
    isBoxSelected,
    boxVariableSelectedId,
    currentProposal,
    isContainerSelected,
    box,
    selectedContainer,
    updateContainer,
    deleteColumns,
    column,
    isATableBoxSelected,
  } = useStore(selector(columnId));
  const [isHovered, setIsHovered] = useState(false)

  const onBorderDrop = (box) => {
    const columnsDisposition = columnsDispositions.find((cd) => {
      return cd.key === container.columnsIds.length
    });
    const columnsSizes = columnsDisposition.dispositions.find((d) => {
      return d.sizes.every((size) => size === d.sizes[0])
    }).sizes
    updateColumnsInContainer({
      columnsSizes,
      index: position === 'right' ? columnIndex : null,
      containerId: container.id,
      newBoxes: [box]
    });
  }

  const showColumn = position === 'right' || columnIndex === 0

  const canAddColumn =
    // !isBoxSelected &&
    container.columnsIds.length < 4 &&
    showColumn &&
    // If dropped box take full size
    !hasAOneRowBox

  const boxCanDropBetweenColumn = (_, monitor) => {
    const box = monitor.getItem();
    // If dragged box need full size
    if (isAOneRowBox(box.type)) {
      return false;
    }
    if (!boxCanDropInColumn) return false;
    if (!canAddColumn) return false;
    return true
  }

  const [{ boxIsOver, canDrop, isDragging }, dropColumnBorder] = useDrop({
    ...dropCommonProps,
    canDrop: boxCanDropBetweenColumn,
    drop: onBorderDrop,
  });

  const isVisible = !!(isContainerHovered || isDragging || willBoxBeReplaced || isContainerSelected)

  const boderPosition = position === 'left' ? {
    left: -getWidthAndPosition({ position, columnIndex, willBoxBeReplaced, containerColumnsLength })
  } : { right: -getWidthAndPosition({ position, columnIndex, willBoxBeReplaced, containerColumnsLength }) }

  const transform = () => {
    if (columnIndex === 0 && position === 'left') return 2;
    if (columnIndex + 1 === containerColumnsLength && position === 'right') return 30 - 2
    return 30
  }

  const zIndexBoder = () => {
    if (!isHovered && !isDragging) return 0
    return position === "right" ? 3 : 2
  }

  const canAddColumnOnClick = canAddColumn && !isDragging
  const canDisplayBorderColumn = useCallback(() => {
    if (currentProposal.id === null && container?.from_linked_section) return false;
    if (box?.type === ItemTypes.SPACE) return false
    if (currentProposal.id === null) return true
    if (currentProposal.id !== null && !container.hasOwnProperty('editable')) return true
    if (currentProposal.id !== null && container?.editable) return true
    else return false
  }, [isDragging, isBoxSelected, canAddColumn, box])

  const divRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setControlCol(null);
    }
  }, [setControlCol, divRef]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const controlActionColor = () => {
    if (deleteButtonHovered) return "red"
    if (controlCol || controlActionHovered) return "#c1e7ff"
    else return "#efefef"
  }

  return (
    <>
      {fromViewer !== viewerTypes.VARIABLES && isContainerSelected && !isATableBoxSelected && (isFirstColumn || isLastColumn || isMiddleColumn) && (
        <div
          ref={divRef}
          style={{
            position: "absolute",
            left: -2,
            top: -18,
            width: "calc(100% + 4px)",
            height: 20,
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
          onMouseEnter={() => setControlActionHovered(true)}
          onMouseLeave={() => setControlActionHovered(false)}
          onClick={() => {
            setControlCol(columnId)
          }}
        >
          {controlCol && !column?.from_linked_section && <div>
            <Button
              isFromHeader
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 20,
                height: 20,
                padding: 0,
                position: "absolute",
                top: -15,
                marginLeft: -10,
                backgroundColor: isHovered ? antdFormError : undefined,
                borderColor: isHovered ? "white" : undefined,
                borderRadius: 20
              }}
              icon={<FontAwesomeIcon icon={faXmark}
                style={{
                  transition: "color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
                  color: isHovered ? "white" : "rgba(0, 0, 0, 0.88)",
                }} />}
              setControlRowClicked={setControlRowClicked}
              onMouseEnter={() => setDeleteButtonHovered(true)}
              onMouseLeave={() => setDeleteButtonHovered(false)}
              onClick={() => {
                updateContainer({ id: selectedContainer.id, fn: c => c.columnsIds = c.columnsIds.filter(cId => cId !== columnId) })
                updateColumnsInContainer({
                  containerId: selectedContainer.id,
                  columnsSizes: columnDefaultDisposition[selectedContainer.columnsIds.length - 1],
                  index: columnIndex
                })
                deleteColumns([columnId])
              }}
            /></div>}
          <div
            style={{
              width: "100%", height: 10, backgroundColor: controlActionColor(),
              border: "1px solid black",
              borderRadius: `${isFirstColumn ? 4 : 0}px ${isLastColumn ? 4 : 0}px 0 0`,
              borderLeft: isFirstColumn ? "display" : "none",
              position: "absolute",
              bottom: 0,
              cursor: "pointer"
            }}
          ></div>
        </div>
      )}
      <div
        ref={dropColumnBorder}
        data-role={pageBackground}
        data-parent={containerBackground}
        style={{
          position: 'absolute',
          height: "100%",
          top: 0,
          display: "flex",
          width: roundButtonWidth,
          ...boderPosition
        }}
      >
        <div
          data-role={pageBackground}
          data-parent={containerBackground}
          style={{
            ...borderCommonStyle({
              isVisible,
              position,
              willBoxBeReplaced,
              boxCanDropInColumn,
              isDragging,
              containerColumnsLength,
              columnIndex,
            }),
          }} />
        {(canAddColumnOnClick || canDrop) && !boxVariableSelectedId &&
          <div style={{
            zIndex: 2,
            transform: `translateX(calc(-50% + ${transform()}px))`,
          }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {canDisplayBorderColumn() && <AdderColumns
              columnId={columnId}
              columnIndex={position === 'right' ? columnIndex : null}
              boxIsOver={boxIsOver}
              canDrop={canDrop}
              defaultFontSizeIcon={15}
            />}
          </div>
        }
      </div>
    </>
  )
}

export default BorderColumn;
