import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useStore } from '../../store/store'
import { DEFAULT_COLUMN_WIDTH, MIN_COLUMN_WIDTH } from '.'
import { colId, rowId } from '../../constants/constants'
import { useTranslation } from 'react-i18next'

const dotStyle = {
  position: 'absolute',
  width: 5,
  height: 5,
  borderRadius: 20,
  backgroundColor: "#9e9e9e",
}

const { confirm } = Modal;


const AdderButton = ({
  isLeftButton,
  isFromHeader,
  isControlRowHovered,
  isControlRowAfterHovered,
  setParentLineHovered,
  isRowLineHovered,
  isColumnLineHovered,
  columnLength,
  boxId,
  placementIndex,
  isRowLineClicked
}) => {
  const updateBox = useStore(({ updateBox }) => updateBox)
  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false)
  const showButton = isControlRowHovered || isHovered ||
    (!isLeftButton && isControlRowAfterHovered) ||
    isRowLineHovered || isColumnLineHovered
  const basePosition = useMemo(() => {
    if (isLeftButton) return isFromHeader ? "left" : "top"
    return isFromHeader ? "right" : "bottom"
  }, [isLeftButton, isFromHeader])

  const secondBasePosition = useMemo(() => {
    return isFromHeader ? "top" : "left"
  }, [isFromHeader])

  const transform = useMemo(() => {
    if (isLeftButton) return `translate${isFromHeader ? "X" : "Y"}(-50%)`
    return `translate${isFromHeader ? "X" : "Y"}(50%)`
  }, [isLeftButton, isFromHeader])

  const handleOnMouseEnter = () => {
    setIsHovered(true);
    setParentLineHovered({ isHovered: true, isLeftButton })
  }

  const handleOnMouseLeave = () => {
    setIsHovered(false);
    setParentLineHovered({ isHovered: false })
  }

  const handleAddCol = () => {
    const colName =
      'Col ' + String.fromCharCode(65 + columnLength);

    updateBox(boxId, (box) => {
      if (box.content.columns.length !== 0) {
        const newColumnWidth = DEFAULT_COLUMN_WIDTH;
        const maxSubstractable = box.content.columns.reduce((acc, col) => acc + col.width - MIN_COLUMN_WIDTH, 0);
        const canAddColumn = maxSubstractable > newColumnWidth;
        if (canAddColumn) {
          const boxCurrentWidth = box.content.columns.reduce((acc, col) => acc + col.width, 0);
          box.content.columns.forEach((col) => {
            const colProportion = (col.width - MIN_COLUMN_WIDTH) / (boxCurrentWidth - (box.content.columns.length * MIN_COLUMN_WIDTH));
            const toRemove = newColumnWidth * colProportion;
            const newWidth = col.width - toRemove;
            if (newWidth >= MIN_COLUMN_WIDTH) {
              col.width = newWidth;
            } else {
              console.error('Not enough space to add column')
            }
          });
          box.content.columns.splice(placementIndex, 0, {
            key: colId(),
            title: colName,
            width: newColumnWidth,
          });
        } else {
          confirm({
            title: t('table.cantAddColumn'),
            okText: 'Ok',
          });
        }
      }
    });
  };

  const handleAddRow = useCallback(() => {
    updateBox(boxId, (selectedBox) => {
      const nextRow = Object.fromEntries(selectedBox.content.columns.map((col) => [col.key, '']));
      nextRow.key = rowId();
      selectedBox.content.data.splice(placementIndex, 0, nextRow);
    });
  }, [boxId, placementIndex, updateBox])

  const handleOnClick = () => {
    if (isFromHeader) {
      handleAddCol()
    } else {
      handleAddRow()
    }
  }

  useEffect(() => {
    setIsHovered(isRowLineHovered)
  }, [isRowLineHovered])

  useEffect(() => {
    if (isRowLineClicked) {
      handleAddRow()
      setParentLineHovered({ isHovered: false })
      setParentLineHovered({ isHovered: true, isLeftButton })
    }
  }, [isRowLineClicked, handleAddRow, setParentLineHovered, isLeftButton])

  return (
    <div>
      <div
        onMouseEnter={handleOnMouseEnter}
        style={{
          [`${basePosition}`]: 0,
          [`${secondBasePosition}`]: -5,
          transform,
          ...dotStyle,
        }} />
      {showButton && <Button
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
        icon={<FontAwesomeIcon icon={faPlus}
          style={{
            transition: "color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
            color: isHovered ? "white" : "rgba(0, 0, 0, 0.88)",
          }} />}
        style={{
          position: "absolute",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 20,
          height: 20,
          [`${basePosition}`]: 0,
          [`${secondBasePosition}`]: -15,
          transform,
          padding: 0,
          backgroundColor: isHovered ? "#388bff" : undefined,
          borderColor: isHovered ? "white" : undefined,
        }}
      />}
    </div>
  )
}

export default AdderButton
