import { CaretDownFilled } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useModalOpener } from '../../hooks';
import { useOutsideClickHandler } from '../../utils/browser';
import { navbarButtonStyle } from '../../utils/styles';
import ColorButton from '../TextStylingComponent/ColorButton';
import { PRIMARY_DARK_ADMIN } from '../../constants/constants';

export const NavbarListButton = ({
  title,
  disabled,
  icon,
  onListElementClick,
  listElements,
  selectedElement,
  children
}) => {

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [displayList, setDisplayList] = useModalOpener(false);
  const listRef = useRef(null);
  const listDisplayRef = useRef(null);
  useOutsideClickHandler(listRef, () => {
    if (displayList) {
      setDisplayList(false);
      setTooltipVisible(false);
    }
  });

  const buttonDisplay = (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      width: '100%',
      height: '100%',
      opacity: disabled ? 0.3 : 1,
    }}>
      {icon}
      {selectedElement && selectedElement.style && (
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '5px',
          backgroundColor: selectedElement.style
        }}></div>
      )}
    </div>
  )

  useEffect(() => {
    const updateTopPosition = () => {
      if (listDisplayRef.current && displayList) {
        const { height, top } = listDisplayRef.current.getBoundingClientRect();
        if (top + height >= window.innerHeight) {
          listDisplayRef.current.style.top = '0'
          listDisplayRef.current.style.flexDirection = 'column-reverse'
          listDisplayRef.current.style.transform = 'translateY(-100%)'
          const carets = listDisplayRef.current.getElementsByClassName("dislayed-caret")
          if (carets.length > 0) {
            carets[0].style.transform = 'rotate(180deg)'
          }
        }
      }
    };

    // Initial top position update
    updateTopPosition();

    // Create a ResizeObserver to watch for changes
    const observer = new ResizeObserver(updateTopPosition);
    observer.observe(listDisplayRef.current);

    // Optionally, you can also track scrolling
    window.addEventListener('scroll', updateTopPosition);

    // Cleanup on unmount
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', updateTopPosition);
    };
  }, [displayList]);

  return (
    <Tooltip
      open={tooltipVisible}
      placement='bottom'
      mouseLeaveDelay='0'
      title={title}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        ref={listRef}
      >
        <div>
          <Button
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            style={navbarButtonStyle}
            disabled={disabled}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              setDisplayList(!displayList)
              setTooltipVisible(false)
            }}
          >
            {buttonDisplay}
          </Button>
        </div>
        <div ref={listDisplayRef} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', position: 'absolute', top: '42px' }}>
          {displayList &&
            <>
              <CaretDownFilled style={{ color: PRIMARY_DARK_ADMIN }} className='dislayed-caret' />
              <div
                style={{
                  zIndex: 15,
                  display: 'grid',
                }}
                className='dislayed-list'
              >
                {children}
                {!children && listElements && listElements.map((element, index, arr) => (
                  <ColorButton
                    key={element.label}
                    active={
                      element.label === selectedElement?.label
                    }
                    label={element.label}
                    onToggle={(elementValue) => onListElementClick(elementValue)}
                    colorStyle={element.style}
                    positionStyle={{
                      gridColumn: Math.ceil((index + 1) / 5),
                      gridRow: (index % 5) + 1,
                    }}
                    size='32px'
                  />
                ))}
              </div>
            </>}
        </div>
      </div>
    </Tooltip>
  );
};
