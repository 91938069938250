import React from 'react'
import { useStore } from '../../store/store';
import { ItemTypes, MASKS_TYPE } from '../../constants/constants';
import { isADefaultBox, isADrawBox, isAOneRowBox, isAVariableBox } from '../../utils/boxes';
import { Button, Tooltip } from 'antd';
import { useDrag } from 'react-dnd';


const CopyContent = (copy) => {
  if (copy.type === ItemTypes.CONTAINER_COPIED) {
    return {
      drawMode: copy.drawMode,
      copiedContainer: copy.copiedContainer,
      maskId: copy.maskId,
      boxes: copy.boxes.map((box) =>
      (
        { ...box, copied: true, colSize: copy.columnsDisposition.find((col) => col.id === box.columnId).size }
      )
      )
    };
  } else {
    return {
      ...copy,
      copied: true,
    };
  }
}

const BoxButton = ({ type, text, icon, bgcolor, disp, label, color = '#000000', onDragStart,
  onClick, size = 38, canDrag = true, marginLeft = 5, marginBottom = 8, copy
}) => {
  const maskEditing = useStore(({ maskEditing }) => maskEditing);
  const isInMask = maskEditing.length > 0
  const isInHeaderFooter = isInMask &&
    (maskEditing.includes(MASKS_TYPE.HEADER.id) || maskEditing.includes(MASKS_TYPE.FOOTER.id))
  const disabled = (isInMask && (isADrawBox(type) || isADefaultBox(type) || isAVariableBox(type))) || (isInHeaderFooter && isAOneRowBox(type))
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration);
  const [, drag] = useDrag({
    type: type === ItemTypes.CONTAINER_COPIED ? ItemTypes.CONTAINER_COPIED : 'newBox',
    item: copy ? CopyContent(copy) : { type, ...gridConfiguration[type], copied: false },
    canDrag: !disabled && canDrag
  });

  return (
    <Tooltip
      placement='bottom'
      mouseLeaveDelay='0'
      title={text}
      mouseEnterDelay={0.5}
    >
      <li
        ref={drag}
        onDragStart={onDragStart}
        style={{
          display: disp,
          marginLeft,
          marginBottom,
          width: size,
          height: size,
          backgroundColor: bgcolor,
          borderStyle: 'none',
          borderRadius: '5px',
          fontSize: '9px',
          fontFamily: 'Montserrat',
          color,
          textAlign: 'center',
        }}
      >
        <Button
          onClick={onClick}
          style={{
            borderStyle: 'none',
            backgroundColor: bgcolor,
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: '100%',
            color,
          }}
          icon={icon}
          disabled={disabled}
        >{label}</Button>
      </li>
    </Tooltip>
  );
};

export default BoxButton
