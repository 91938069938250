import { produce } from 'immer';
import { uuid } from '../constants/constants';
import { mutateCreateMask, mutateInjectMask } from '../utils/masks';

export const masksActions = (set, get) => ({
  updateMask: ({ id, fn }) => {
    set(
      produce((draft) => {
        const mask = draft.masks.find((c) => c.id === id);
        fn(mask, draft);
      }),
      false,
      `update Mask ${id}`
    );
  },
  deleteMask: (id) => {
    set(
      produce((state) => {
        state.masks = state.masks.filter((mask) => mask.id !== id);
      }),
      false,
      'deleteMask'
    );
  },
  renameMask: (id, name) => {
    set(
      produce((state) => {
        state.masks.forEach((mask) => {
          if (mask.id === id) {
            mask.name = name;
          }
        });
      }),
      false,
      'renameMask'
    );
  },
  setEditableMask: (id, editableValue) => {
    set(
      produce((state) => {
        state.masks.forEach((mask) => {
          if (mask.id === id) {
            mask.editableMask = editableValue;
          }
        });
      }),
      false,
      'setEditableMask'
    );
  },
  duplicateMask: (id, name) => {
    set(
      produce((state) => {
        const duplicatedMask = state.masks.find((mask) => mask.id === id);
        const maskDuplicatedId = uuid();
        const duplicatedMaskBoxes = duplicatedMask.boxes.map((box) => {
          return {
            ...box,
            id: uuid(),
            maskId: maskDuplicatedId,
          };
        });

        state.masks = [
          ...state.masks,
          { id: maskDuplicatedId, name, boxes: duplicatedMaskBoxes },
        ];
      }),
      false,
      'duplicateMask'
    );
  },
  createMask: ({ name}) => {
    set(
      produce((draft) => {
        mutateCreateMask({draft, name})
      }),
      false,
      'createMask'
    );
  },
  setMaskEditing: (ids) => {
    set(
      produce((state) => {
        state.maskEditing = ids;
        state.selectedBoxId = null;
        state.boxVariableSelectedId = null;
      }),
      false,
      `setMaskEditing ${ids.join(', ')}`
    );
  },
  moveMaskBox: (left, top, boxId, maskId) => {
    set(
      produce((state) => {
        const box = state.masks
          .find((mask) => mask.id === maskId)
          .boxes.find((box) => box.id === boxId);
        box.top = top;
        box.left = left;
      }),
      false,
      'moveMaskBox'
    );
  },
  injectMask: ({ id, boxId }) => {
    set(
      produce((state) => {
        mutateInjectMask({ draft: state, maskId: id, boxId });
      }),
      false,
      'injectMask'
    );
  },
});
