import React, { useMemo, useState } from 'react'
import { ItemTypes, getPrimaryDark, pageBackground } from '../../../constants/constants'
import { useStore } from '../../../store/store'
import { variableStyle } from '../../../utils/styles'
import FormItemsArray from './FormItemsArray'
import FormViewer from '../FormViewer/FormViewer'
import FormBoxEditor from './FormBoxEditor'
import { transformTextToEditorState } from '../../../utils/dataSync'
import { useWindowDimensions } from '../../../hooks'

const FormCreator = ({ variablesGroupedByContainer, columnToShow, hidePreview }) => {
  const boxVariableSelectedId = useStore(({ boxVariableSelectedId }) => boxVariableSelectedId)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  const { width } = useWindowDimensions()


  const [createItemType, setCreateItemType] = useState()

  const variablesGroupedByContainerSelected = useMemo(() => variablesGroupedByContainer.find((vInfos) => {
    if (createItemType?.type === ItemTypes.VARIABLE_GROUP || createItemType?.type === ItemTypes.CUSTOM_TEXT_VARIABLE || !boxVariableSelectedId) return null;
    if (vInfos.variableGroupBox && vInfos.variableGroupBox.id === boxVariableSelectedId) {
      return true
    }
    return vInfos.variablesBoxes.some((b) => b.id === boxVariableSelectedId)
  }), [boxVariableSelectedId, createItemType, variablesGroupedByContainer])

  const box = useMemo(() => {
    if (createItemType?.type === ItemTypes.VARIABLE_GROUP) {
      return {
        name: "",
        type: ItemTypes.VARIABLE_GROUP,
        remark: transformTextToEditorState(),
        id: "create-group",
        from: createItemType.from
      }
    }

    if (createItemType?.type === ItemTypes.CUSTOM_TEXT_VARIABLE) {
      return {
        name: "",
        type: ItemTypes.CUSTOM_TEXT_VARIABLE,
        remark: transformTextToEditorState(),
        id: "create-variable",
        "linked-group": createItemType["linked-group"],
        from: createItemType.from
      }
    }

    if (!variablesGroupedByContainerSelected) return;

    if (variablesGroupedByContainerSelected.variableGroupBox && variablesGroupedByContainerSelected.variableGroupBox.id === boxVariableSelectedId) {
      return variablesGroupedByContainerSelected.variableGroupBox
    }
    return variablesGroupedByContainerSelected.variablesBoxes.find((b) => b.id === boxVariableSelectedId)
  }, [boxVariableSelectedId, createItemType, variablesGroupedByContainerSelected])

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: width >= 1860 && !hidePreview ? '20% 50% 30%' : '30% 70%',
      flex: 1,
      overflow: 'hidden',
      backgroundColor: getPrimaryDark(isTemplate),
      width: "100%",
      padding: '60px 16px 16px 16px',
      flexDirection: 'column',
      height: "100%",
    }}
      data-role={pageBackground}>
      <div style={variableStyle["left"]}>
        <FormItemsArray variablesGroupedByContainer={variablesGroupedByContainer} setCreateItemType={setCreateItemType} />
      </div>
      <FormBoxEditor
        box={box}
        key={box?.id}
        variablesGroupedByContainer={variablesGroupedByContainer}
        setCreateItemType={setCreateItemType}
        variablesGroupedByContainerSelected={variablesGroupedByContainerSelected}
        columnToShow={columnToShow}
      />
      {width >= 1860 && !hidePreview && <div style={variableStyle["right"]}>
        <FormViewer variablesGroupedByContainer={variablesGroupedByContainer} />
      </div>}
    </div>
  )
}

export default FormCreator
