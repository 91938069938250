import { ItemTypes, variableHandlingType } from '../constants/constants';

export const mutateInsertVariableBox = (draft, box) => {
  if (
    box.from_linked_section &&
    draft.variableOrderArray.some(({ id }) => id === box.id)
  ) {
    if (box.columnId) {
      const boxContainerIdx = draft.containers.findIndex((c) =>
        c.columnsIds.includes(box.columnId)
      );
      const vOrderIdx = draft.variableOrderArray.findIndex(
        ({ id }) => id === box.id
      );
      draft.variableOrderArray[vOrderIdx].containerId =
        draft.containers[boxContainerIdx].id;
    }
    return;
  }
  if (box.boxSectionId) return;
  if (!box.columnId) {
    draft.variableOrderArray.unshift({
      id: box.id,
      containerId: box.id,
    });
    return;
  }
  const boxContainerIdx = draft.containers.findIndex((c) =>
    c.columnsIds.includes(box.columnId)
  );
  const afterContainer = draft.containers.find(
    (c, idx) =>
      boxContainerIdx <= idx &&
      draft.variableOrderArray.some(({ containerId }) => containerId === c.id)
  );
  if (afterContainer) {
    const afterOrderIdx = draft.variableOrderArray.findIndex(
      ({ containerId }) => containerId === afterContainer.id
    );
    draft.variableOrderArray = [
      ...draft.variableOrderArray.slice(0, afterOrderIdx),
      { id: box.id, containerId: draft.containers[boxContainerIdx].id },
      ...draft.variableOrderArray.slice(afterOrderIdx),
    ];
  } else {
    draft.variableOrderArray.push({
      id: box.id,
      containerId: draft.containers[boxContainerIdx].id,
    });
  }
};

export const mutateTakeOutItem = (draft, vId, parentId) => {
  const idx = draft.variableOrderArray.findIndex(
    ({ id, parentId: vParentId }) =>
      id === vId && (!parentId || parentId === vParentId)
  );
  if (idx !== -1) draft.variableOrderArray.splice(idx, 1);
};

export const mutateRemoveUnusedVariableGroup = (draft) => {
  draft.variableGroups.forEach((vG) => {
    const vGIsUsed = vG.variableIds.some((id) => !draft.variables[id]?.notUsed);
    if (!vGIsUsed) {
      mutateTakeOutItem(draft, vG.id);
    }
  });
};

const getIdAndContainerIdFromSection = ({
  boxesToAdd,
  id,
  containerId,
  draft,
}) => {
  if (boxesToAdd.some(({ boxSectionId }) => boxSectionId === id)) {
    const b = boxesToAdd.find(({ boxSectionId }) => boxSectionId === id);
    if (b.columnId) {
      const boxContainerIdx = draft.containers.findIndex((c) =>
        c.columnsIds.includes(b.columnId)
      );
      return { id: b.id, containerId: draft.containers[boxContainerIdx].id };
    }
    return { id: b.id, containerId: b.id };
  }
  return { id, containerId };
};

export const mutateSortVariableFromSection = ({
  draft,
  sectionContent,
  boxesToAdd,
  containerIdx,
  parentContainerId,
  parentId,
  isDocumentLoading,
}) => {
  const afterContainer = draft.containers.find(
    (c, idx) =>
      c.id !== parentContainerId &&
      containerIdx <= idx &&
      draft.variableOrderArray.some(({ containerId }) => containerId === c.id)
  );
  if (afterContainer) {
    let afterOrderIdx = draft.variableOrderArray.findIndex(
      ({ containerId }) => containerId === afterContainer.id
    );
    while (
      afterOrderIdx > 0 &&
      draft.boxes.find(
        ({ id }) =>
          id === draft.variableOrderArray[afterOrderIdx - 1].id &&
          !boxesToAdd.some(({ id: bId }) => id === bId)
      )?.type === ItemTypes.QUESTION
    ) {
      afterOrderIdx -= 1;
    }
    let firstIdxVariable = draft.variableOrderArray.findIndex(
      ({ id }) => id === boxesToAdd.some(({ id: bId }) => id === bId)
    );
    firstIdxVariable = firstIdxVariable === -1 ? 0 : firstIdxVariable;
    const sectionDiff = afterOrderIdx - firstIdxVariable;
    sectionContent.variableOrderArray
      .filter(({ id }) => {
        if (
          draft.currentDocument.type !== 'template' &&
          !isDocumentLoading &&
          !draft.variableOrderArray.some(
            ({ id: vId, parentId: vParentId }) =>
              id === vId && parentId === vParentId
          ) &&
          !!draft.variables[id]
        )
          return true;
        const idToUse =
          boxesToAdd.find(({ boxSectionId }) => boxSectionId === id)?.id ?? id;
        return !draft.variableOrderArray.some(({ id: vId }) => idToUse === vId);
      })
      .forEach(({ id: vId, containerId: vContainerId }, idx) => {
        const { id, containerId } = getIdAndContainerIdFromSection({
          boxesToAdd,
          id: vId,
          containerId: vContainerId,
          draft,
        });
        draft.variableOrderArray = [
          ...draft.variableOrderArray.slice(0, sectionDiff + idx),
          { id, containerId, parentId },
          ...draft.variableOrderArray.slice(sectionDiff + idx),
        ];
      });
  } else {
    sectionContent.variableOrderArray.forEach(({ id, containerId }) => {
      if (
        !draft.variableOrderArray.some(({ id: vId }) => id === vId) ||
        (draft.currentDocument.type !== 'template' &&
          !isDocumentLoading &&
          !draft.variableOrderArray.some(
            ({ id: vId, parentId: vParentId }) =>
              id === vId && parentId === vParentId
          ) &&
          !!draft.variables[id])
      ) {
        const { id: realId, containerId: realContainerId } =
          getIdAndContainerIdFromSection({
            boxesToAdd,
            id: id,
            containerId: containerId,
            draft,
          });
        draft.variableOrderArray.push({
          id: realId,
          containerId: realContainerId,
          parentId,
        });
      }
    });
  }
};

export const mutateInsertVariableInOrderArray = (draft, variableKey) => {
  if (draft.variableGroups.some((vG) => vG.variableIds.includes(variableKey))) {
    const vG = draft.variableGroups.find((vG) =>
      vG.variableIds.includes(variableKey)
    );
    if (!draft.variableOrderArray.some(({ id }) => id === vG.id)) {
      draft.variableOrderArray.unshift({ id: vG.id, containerId: vG.id });
      return true;
    }
  } else {
    if (!draft.variableOrderArray.some(({ id }) => id === variableKey)) {
      draft.variableOrderArray.unshift({
        id: variableKey,
        containerId: variableKey,
      });
      return true;
    }
  }
  return false;
};

export const sortAndFilterVariablesGroupedByContainer = ({
  variablesGroupedByContainer,
  variableOrderArray,
  filterHiddenBoxes = false,
}) => {
  let variablesToUse = variablesGroupedByContainer.filter((vInfos) => {
    if (vInfos.type === variableHandlingType.FORM)
      return (
        !filterHiddenBoxes ||
        vInfos.variablesBoxes.some(({ hideInForm }) => !hideInForm)
      );
    if (vInfos.variableGroupBox)
      return (
        vInfos.variablesBoxes.length > 0 &&
        vInfos.variablesBoxes.some((v) => !v.notUsed) &&
        (!filterHiddenBoxes ||
          vInfos.variablesBoxes.some(({ hideInForm }) => !hideInForm))
      );
    return (
      !vInfos.variablesBoxes[0].notUsed &&
      (!filterHiddenBoxes || !vInfos.variablesBoxes[0].hideInForm)
    );
  });
  if (filterHiddenBoxes) {
    variablesToUse = variablesToUse.map((v) => {
      return {
        ...v,
        variablesBoxes: v.variablesBoxes.filter(
          ({ hideInForm }) => !hideInForm
        ),
      };
    });
  }
  const array = variableOrderArray
    .map(({ id }) => {
      return variablesToUse.find((vInfos) => {
        if (vInfos.variableGroupBox) return vInfos.variableGroupBox.id === id;
        return vInfos.variablesBoxes[0].id === id;
      });
    })
    .filter((obj) => obj !== undefined);

  return variablesToUse
    .filter((vInfos) => {
      if (vInfos.type === variableHandlingType.FORM) return false;
      if (vInfos.variableGroupBox)
        return !array.some(
          ({ containerId }) => containerId === vInfos.variableGroupBox.id
        );
      return !array.some(
        ({ containerId }) => containerId === vInfos.variablesBoxes[0].id
      );
    })
    .concat(array);
};

const reorderArray = (referenceArray, arrayToReorder) => {
  return referenceArray.map((id) => arrayToReorder.find((el) => el.id === id));
};

export const sortVariableOrderArray = (draft) => {
  const currentOrderBoxes = draft.boxes
    .filter(
      (box) =>
        box.type === ItemTypes.TEXT_VARIABLE ||
        box.type === ItemTypes.CHECKBOXS_VARIABLE ||
        box.type === ItemTypes.CUSTOM_TEXT_VARIABLE ||
        box.type === ItemTypes.VARIABLE_GROUP ||
        box.type === ItemTypes.SECTION_VARIABLE ||
        box.type === ItemTypes.SELECTOR_VARIABLE ||
        box.type === ItemTypes.IMG_VARIABLE ||
        box.type === ItemTypes.TABLE_VARIABLE ||
        box.type === ItemTypes.FILE_PDF_VARIABLE
    )
    .map(({ id }) => id);
  const variableOrderArray = draft.variableOrderArray;
  draft.variableOrderArray = reorderArray(
    currentOrderBoxes,
    variableOrderArray
  );
};

export const removeDuplicatesInVariableOrderArray = (variableOrderArray) => {
  return Object.values(
    variableOrderArray.reduce((acc, item) => {
      const key = JSON.stringify({
        id: item.id,
        containerId: item.containerId,
      });

      // If we haven't seen this combination before, add it
      if (!acc[key]) {
        acc[key] = item;
        return acc;
      }

      // If current item doesn't have parentId and existing one does, replace it
      if (!item.parentId && acc[key].parentId) {
        acc[key] = item;
      }

      return acc;
    }, {})
  );
};
