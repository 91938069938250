import { useEffect } from 'react';
import ActionCable from "actioncable";
import { useStore } from '../../store/store';
import { getUrlParamsObject } from '../../utils/browser';
import { message } from 'antd';

const selector = (state) => ({
  fetchDatas: state.fetchDatas,
  getTemplates: state.getTemplates,
  currentDocument: state.currentDocument,
  proposals: state.proposals,
  isAdmin: state.isAdmin,
  handlePdfReponse: state.handlePdfReponse,
  setLoaderPdfToImage: state.setLoaderPdfToImage,
  removePdfContainer: state.removePdfContainer,
  addContainerPdfToImage: state.addContainerPdfToImage,
});

const getActionCableUrl = () => {
  let appDomain = `${process.env.REACT_APP_API_URL.replace(/\/api/g, '')}`
  const websocketStart = appDomain.includes("localhost") ? 'ws' : 'wss'
  if (appDomain.includes("localhost")) {
    appDomain = appDomain.replace(/http:\/\//, '')
  } else {
    appDomain = appDomain.replace(/https:\/\//, '')
  }
  return `${websocketStart}://${appDomain}/cable`
}

const Providers = () => {
  const { fetchDatas, getTemplates, handlePdfReponse, addContainerPdfToImage, setLoaderPdfToImage, removePdfContainer } =
    useStore(selector);

  useEffect(() => {
    const jwt = getUrlParamsObject().jwt || window.localStorage.getItem('jwt');
    let cable;
    if (jwt) {
      ActionCable.INTERNAL.protocols.push(jwt)
      cable = ActionCable.createConsumer(getActionCableUrl())
    }
    if (cable) {
      const channel = cable.subscriptions.create({
        channel: "EditorsChannel",
      },
        {
          received: (data) => {
            if ("image_url" in data) {
              addContainerPdfToImage({ data })
            }
            if (data?.end_socket === true) {
              setLoaderPdfToImage(false);
              removePdfContainer()
            }
            if (data?.pdf_url || data?.pdf_error) {
              handlePdfReponse(data)
            }
          }
        });

      return () => {
        if (channel) {
          cable.subscriptions.remove({
            channel: "EditorsChannel",
          })
        }
        cable.disconnect()
      };
    }
  }, [handlePdfReponse])

  const handleUnload = (event) => {
    const message =
      'Are you sure you want to quit without saving your changes?';

    if (typeof event === 'undefined') {
      event = window.event;
    } else if (event) {
      event.returnValue = message;
    }
  };

  useEffect(() => {
    fetchDatas();
    getTemplates();
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', handleUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [getTemplates, fetchDatas]);

  return null;
};

export default Providers;
