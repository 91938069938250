import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableWithDrag from '../../common/TableWithDrag'
import { ItemTypes, tableColor, tableHeaderSecondaryBgColor } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import "./TriggerArray.less"
import { useStore } from '../../../store/store';
import TriggerTypeSelect from './TriggerTypeSelect';
import TriggerNameSelect from './TriggerNameSelect';
import TriggerValueWrapper from './TriggerValueWrapper';
import TriggerResultPopover from './TriggerResultPopover';
import TriggerArrayFooter from './TriggerArrayFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { faBan } from '@fortawesome/pro-solid-svg-icons';

const TriggerArray = ({ box, variablesGroupedByContainer }) => {
  const { t } = useTranslation()
  const [dataSource, setDataSource] = useState([])
  const [isFooterHovered, setIsFooterHovered] = useState(false)
  const triggers = useStore(({ triggers }) => triggers)
  const reorderTriggers = useStore(({ reorderTriggers }) => reorderTriggers)
  const deleteTrigger = useStore(({ deleteTrigger }) => deleteTrigger)
  const addTrigger = useStore(({ addTrigger }) => addTrigger)

  const onAdd = useCallback((_, clickedId) => {
    const index = triggers[box.id].findIndex(({ id }) => id === clickedId) - 1
    addTrigger(box, index)
  }, [addTrigger, box, triggers])

  useEffect(() => {
    if (triggers[box.id]) {
      let toUse = triggers[box.id]
      if (triggers[box.id].some(({ from_linked_section }) => from_linked_section)) {
        toUse = toUse.filter(({ from_linked_section }) => from_linked_section)
      }
      setDataSource(toUse.map((res) => ({
        ...res,
        onAdd: !res.from_linked_section ? onAdd : undefined,
        canDrag: !res.from_linked_section,
        canDrop: !res.from_linked_section,
      })))
    }
    else setDataSource([])
  }, [box.id, triggers, onAdd])

  const variablesBoxes = useMemo(() => {
    return variablesGroupedByContainer.map(({ variablesBoxes }) => variablesBoxes.filter((vB) => !vB.notUsed)).flat()
  }, [variablesGroupedByContainer])

  const columns = useMemo(() => {
    return [
      {
        title: t('type'),
        dataIndex: 'triggerType',
        width: 40,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
        render: (triggerType, record) => <TriggerTypeSelect box={box} triggerType={triggerType} record={record} />,
      },
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('name')}
        </div>,
        width: "100%",
        dataIndex: 'triggerId',
        render: (triggerId, record) => <>
          {record.triggerType &&
            record.triggerType !== "optional-zone" &&
            record.triggerType !== "zone-name" &&
            <TriggerNameSelect box={box} triggerId={triggerId} record={record} variablesBoxes={variablesBoxes} />}
          {record.triggerType && (record.triggerType === "optional-zone" || record.triggerType === "zone-name") &&
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <FontAwesomeIcon icon={faBan} style={{ fontSize: 14, color: "#cacaca" }} />
            </div>
          }
        </>,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      },
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('value')}
        </div>,
        dataIndex: 'value',
        width: "100%",
        render: (value, record) => <>
          {record.triggerType &&
            record.triggerType !== "optional-zone" &&
            (record.triggerId || record.triggerType === "zone-name") &&
            <TriggerValueWrapper triggerType={record.triggerType} box={box} value={value} record={record} variablesBoxes={variablesBoxes} />}
          {record.triggerType && record.triggerType === "optional-zone" &&
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <FontAwesomeIcon icon={faBan} style={{ fontSize: 14, color: "#cacaca" }} />
            </div>
          }
        </>,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      },
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('result')}
        </div>,
        dataIndex: 'result',
        width: 60,
        render: (result, record) => <TriggerResultPopover variablesBoxes={variablesBoxes} box={box} result={result} record={record} />,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      },
      {
        dataIndex: 'delete',
        width: 30,
        render: (_, record) => <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
          cursor: record.from_linked_section ? 'not-allowed' : 'pointer'
        }}
          onClick={() => deleteTrigger(record.id, box.id)}
        >
          <FontAwesomeIcon
            icon={icon({ name: 'trash', style: 'regular' })}
            style={{ margin: 0, fontSize: 15, color: record.from_linked_section ? "#b2b2b2" : 'red' }} />
        </div >
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      },
    ]
  }, [box, deleteTrigger, t, variablesBoxes]);

  const onDrop = useCallback(() => {
    reorderTriggers(box.id, dataSource.map(({ id }) => id))
  }, [box.id, dataSource, reorderTriggers])

  return (
    <>
      <div
        className='variable-editor__middle-title'
      >
        <span>Valeurs conditionnelles</span>
      </div>
      <div style={{ height: "calc(100% - 220px - 64px)", }}>
        <TableWithDrag
          columns={columns}
          dataSource={dataSource}
          setDataSource={setDataSource}
          backgroundColor={tableHeaderSecondaryBgColor}
          color={tableColor}
          onDrop={onDrop}
          scroll={{ y: `calc(100vh - 54px - 62px - 32px ${box.type !== ItemTypes.CUSTOM_TEXT_VARIABLE ? "- 234px - 32px" : ""} - 8px - 31px - 35px` }}
          className="trigger-array"
          footer={() => (
            dataSource.length > 0 && dataSource[0].from_linked_section ? null :
              <TriggerArrayFooter box={box} setIsFooterHovered={setIsFooterHovered} isFooterHovered={isFooterHovered} />
          )}
        />
      </div>
    </>
  )
}

export default TriggerArray
