import React, { useMemo, useState } from 'react'
import { RESIZING_BORDER } from '../../constants/constants'
import { boxActionColor } from '../../utils/styles'
import { selectComponent } from './Box'
import { useStore } from '../../store/store'

const SimulatedBox = ({ box, isSelected, setIsSelected, fromViewer }) => {
  const [isHovered, setIsHovered] = useState(false)
  const handleUnSelectBox = useStore(({ handleUnSelectBox }) => handleUnSelectBox)

  const commonBox = useMemo(() => ({
    ...box,
    width: "100%",
    fromViewer,
    isSelected,
  }), [box, isSelected, fromViewer])

  return <div
    style={{
      outline: isSelected ? '2px solid ' + RESIZING_BORDER : `2px solid ${boxActionColor({
        hovered: isHovered
      })}`,
    }}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={(e) => {
      handleUnSelectBox();
      setIsSelected(true);
      e.stopPropagation()
    }}
  >
    {selectComponent({ ...commonBox }, false)}
  </div>
}

export default SimulatedBox
