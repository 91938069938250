import React from 'react'
import FormBoxName from './FormBoxName'
import { variableStyle } from '../../../utils/styles'
import FormBoxRemark from './FormBoxRemark'
import TriggerArray from './TriggerArray'
import DefaultValueBox from './DefaultValueBox'
import HideInFormSwitcher from '../../common/HideInFormSwitcher'

const LeftPart = ({ box, onBlur, form }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <div style={{ marginBottom: 16, fontWeight: "bold" }}>
        <HideInFormSwitcher boxId={box.id} />
      </div>
      <FormBoxName box={box} onBlur={onBlur} form={form} />
      <FormBoxRemark box={box} onBlur={onBlur} form={form} />
    </div>
  )
}

const RightPart = ({ box, variablesGroupedByContainer }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <DefaultValueBox box={box} />
      <TriggerArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} />
    </div>
  )
}

const FormTable = ({ box, onBlur, form, formPart, variablesGroupedByContainer }) => {
  return (
    <>
      {formPart === 'left' && <LeftPart box={box} onBlur={onBlur} form={form} />}
      {formPart === 'right' && <RightPart box={box} variablesGroupedByContainer={variablesGroupedByContainer} />}
    </>
  )
}

export default FormTable
