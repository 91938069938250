//ANT Design
import { message } from 'antd';

// Axios
import { instance } from './axios.instance';

import { formatResContent, textToRaw, triggersToRaw } from '../utils/dataSync';
import axios from 'axios';

export const getTemplates = async () => {
  try {
    const res = await instance.get('/proposals/templates/');
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal templates status code ${res.status}`);
  } catch (error) {}
};

export const getOpportunityTemplates = async (id) => {
  try {
    const res = await instance.get(`/proposals/opportunities/${id}/templates/`);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal templates status code ${res.status}`);
  } catch (error) {}
};

export const getOfferTemplates = async (id) => {
  try {
    const res = await instance.get(`/proposals/offers/${id}/templates/`);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal templates status code ${res.status}`);
  } catch (error) {}
};

export const deleteTemplate = async (id) => {
  try {
    const res = await instance.delete(`/proposals/templates/${id}`);
    if (res.status === 204) {
      message.success('Le template a bien été supprimé.');
      return true;
    }
    throw new Error(`DELETE proposal template ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le template n'a pas pu être supprimé.");
    return false;
  }
};

export const postTemplate = async ({
  name,
  templatable,
  pattern,
  landscape,
  boxes,
  pages,
  masks,
  configuration,
  summaryConfiguration,
  work,
  documentTypeIds,
  variables,
  variableGroups,
  variableOrderArray,
  triggers,
}) => {
  if (!boxes) return;
  try {
    const res = await instance.post('/proposals/templates/', {
      name: name,
      document_type_ids: documentTypeIds,
      templatable,
      pattern,
      landscape,
      work,
      content: {
        boxes: textToRaw(boxes),
        pages,
        configuration,
        summaryConfiguration,
        variables,
        variableGroups,
        variableOrderArray,
        triggers: triggersToRaw(triggers, boxes),
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
    });
    if (res.status === 200) {
      return res.data;
    }

    throw new Error(`POST proposal template  status code ${res.status}`);
  } catch (error) {
    throw error;
  }
};

export const updateTemplate = async ({
  id,
  name,
  pattern,
  landscape,
  templatable,
  boxes,
  masks,
  configuration,
  summaryConfiguration,
  gridConfiguration,
  work,
  containers,
  columns,
  document_type_ids,
  variables,
  variableGroups,
  variableOrderArray,
  triggers,
}) => {
  if (!containers || !columns) return;
  try {
    const res = await instance.patch(`/proposals/templates/${id}`, {
      name: name,
      templatable,
      landscape,
      pattern,
      work,
      document_type_ids: document_type_ids,
      content: {
        variables,
        variableGroups,
        variableOrderArray,
        triggers: triggersToRaw(triggers, boxes),
        boxes: textToRaw(boxes.filter((b) => b?.from_linked_section !== true)),
        containers: containers.filter((c) => c?.from_linked_section !== true),
        columns: columns.filter((c) => c?.from_linked_section !== true),
        configuration: (({ zoom, ...restConfiguration }) => restConfiguration)(
          configuration
        ),
        summaryConfiguration,
        gridConfiguration,
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
    });

    if (res.status === 200) {
      return res.data;
    }

    throw new Error(`PATCH proposal template ${id} status code ${res.status}`);
  } catch (error) {
    throw error;
  }
};

export const getTemplate = async (
  id,
  defaultVariablesApi,
  currentDocIsTemplate = false
) => {
  try {
    const res = await instance.get(`/proposals/templates/${id}`);
    if (res.status === 200) {
      // return res.data;
      res.data.content = formatResContent(
        res.data.content,
        defaultVariablesApi,
        currentDocIsTemplate,
        res.data.landscape
      );
      return res.data;
    }
    throw new Error(`GET proposal template ${id} status code ${res.status}`);
  } catch (error) {
    return {
      error,
    };
  }
};
export const updateTemplateInfos = async (id, { name, document_type_ids }) => {
  try {
    const res = await instance.post(`/proposals/templates/${id}/update_infos`, {
      name,
      document_type_ids,
    });
    if (res.status === 200) {
      message.success(`Le modèle ${res.name} a bien été modifé.`);
      return res.data;
    }
    throw new Error(`MODIFY proposal ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le modèle n'a pas pu être modifié.");
    return false;
  }
};

export const duplicateTemplate = async (id, newName) => {
  try {
    const res = await instance.post(`/proposals/templates/${id}/duplicate`, {
      name: newName,
    });
    if (res.status === 200) {
      message.success(`Le modèle  a bien été dupliqué.`);
      return res.data;
    }
    throw new Error(`DUPLICATE template ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le modèle n'a pas pu être dupliqué.");
    return false;
  }
};

export const postTemplateAndFetchPdf = async ({
  currentDocument,
  boxes,
  masks,
  configuration,
  summaryConfiguration,
  gridConfig,
  name,
  containers,
  columns,
  landscape,
  isArchive,
  variables,
  variableGroups,
  variableOrderArray,
  triggers,
  currentTemplate,
}) => {
  if (!containers || !columns || !currentDocument.id) return;
  try {
    const commonPayload = {
      content: {
        boxes: textToRaw(boxes),
        configuration: (({ zoom, ...restConfiguration }) => restConfiguration)(
          configuration
        ),
        containers,
        columns,
        summaryConfiguration,
        gridConfiguration: gridConfig,
        variables,
        variableGroups,
        variableOrderArray,
        triggers: triggersToRaw(triggers, boxes),
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
      landscape,
      name,
      must_be_saved: isArchive,
    };
    // In case you want to test with local pdf generator, fill this variable
    // with your local pdf url server in your .env (should be http://localhost:5010 normally)
    if (process.env.REACT_APP_LOCAL_PDF_URL) {
      await axios
        .post(
          `${process.env.REACT_APP_LOCAL_PDF_URL}/generate_pdf`,
          {
            ...commonPayload,
            editor_url: window.location.origin,
          },
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `${name}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
      return { ok: true };
    }
    const url = `/proposal/templates/${currentDocument.id}/generate_pdf`;

    const response = updateTemplate({
      id: currentDocument.id,
      name,
      boxes,
      masks,
      configuration,
      summaryConfiguration,
      gridConfiguration: gridConfig,
      templatable: currentTemplate.templatable,
      landscape: currentTemplate.landscape,
      pattern: currentTemplate.pattern,
      work: currentTemplate.work,
      containers,
      columns,
      document_type_ids: currentTemplate.document_type_ids,
      variables,
      variableGroups,
      variableOrderArray,
      triggers,
    });

    if (response) {
      const res = await instance.post(url, commonPayload);
      if (res.status === 202) {
        return res.data;
      } else {
        throw new Error(`POST proposal template status code ${res.status}`);
      }
    }
  } catch (error) {
    throw error;
  }
};

export const postPdfInTemplate = async ({
  id,
  boxId,
  file,
  setLoaderPdfToImage,
  currentDocumentType,
}) => {
  try {
    // Utilisez FormData pour encapsuler le fichier
    const formData = new FormData();
    formData.append('file', file);
    formData.append('box_id', boxId);

    // Envoyez le fichier en utilisant axios avec multipart/form-data
    let urlBase = '';
    if (currentDocumentType === 'template') {
      urlBase = '/proposal/templates';
    } else {
      urlBase = '/proposal';
    }
    const res = await instance.post(`${urlBase}/${id}/pdf`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (res.status === 200) {
      setLoaderPdfToImage(true);

      message.success('Le PDF a bien été enregistré');
      return res.data;
    }
    throw new Error(`POST proposal template status code ${res.status}`);
  } catch (error) {
    message.error('Une erreur est survenue lors de la sauvegarde du PDF');
    return {};
  }
};
