import React from 'react'
import { useStore } from '../../store/store';
import { ItemTypes, variablesIcons } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import BoxButton from './BoxButton';
import GenerateDivider from './GenerateDivider';
import CopyItem from './CopyItem';

const size = 38
const iconFontSize = 25
const secondIconFontSizeReduction = 10



const BoxButtonsWrapper = ({ onClick, showCopy = true, columnId, canDrag }) => {
  const { t } = useTranslation();
  const currentDocument = useStore(({ currentDocument }) => currentDocument);
  const copy = useStore(({ copy }) => copy);


  return (
    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        {showCopy && <>
          <GenerateDivider text='Élément copié' />
          {copy != null ? (
            <CopyItem copy={copy} onClick={(type) => onClick({ type, fromCopied: true })} />
          ) : (
            <div
              style={{
                marginLeft: '5px',
                width: size,
                height: size,
                borderRadius: '5px',
                fontSize: '9px',
                fontFamily: 'Montserrat',
                color: '#000000',
                textAlign: 'center',
              }}
            ></div>
          )}
        </>}
        <GenerateDivider text='Eléments disponibles' />
        {!columnId && <>
          <div style={{ display: "flex", flexWrap: "wrap", flex: "1 1 '17%'" }}>
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.SECTION })}
              type={ItemTypes.SECTION}
              text={t('section')}
              icon={< FontAwesomeIcon icon={icon({ name: 'rectangle-history-circle-plus', style: 'light' })} style={{ fontSize: iconFontSize }} />}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.LINE_BREAK })}
              type={ItemTypes.LINE_BREAK}
              text={t('line_break')}
              icon={< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: iconFontSize }} />}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.SPACE })}
              type={ItemTypes.SPACE}
              text={t('space')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })} style={{ fontSize: iconFontSize - 14, transform: 'rotate(135deg)' }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.MASK })}
              type={ItemTypes.MASK}
              text={t('mask')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: 'masks-theater', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.DRAW })}
              type={ItemTypes.DRAW}
              text={t('drawing')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: 'scribble', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.TITLE })}
              type={ItemTypes.TITLE}
              text={t('title1')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.TITLE_2 })}
              type={ItemTypes.TITLE_2}
              text={t('title2')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: '2', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.TITLE_3 })}
              type={ItemTypes.TITLE_3}
              text={t('title3')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: '3', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.TITLE_4 })}
              type={ItemTypes.TITLE_4}
              text={t('title4')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: '4', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.SUMMARY })}
              type={ItemTypes.SUMMARY}
              text={t('summary')}
              icon={<span className="fa-layers" style={{ width: '21px' }}>
                <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
                <FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
              </span>}
              bgcolor='#FFFFFF'
              disp='block'
            />
          </div>
        </>}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <BoxButton
          canDrag={canDrag}
          onClick={() => onClick && onClick({ type: ItemTypes.TEXT })}
          type={ItemTypes.TEXT}
          text={t('text')}
          icon={< FontAwesomeIcon icon={icon({ name: 'pen-fancy', style: 'light' })} style={{ fontSize: iconFontSize }} />}
          bgcolor='#FFFFFF'
          disp='inline-block'
        />
        {!columnId && <>
          <BoxButton
            canDrag={canDrag}
            onClick={() => onClick && onClick({ type: ItemTypes.TABLE })}
            type={ItemTypes.TABLE}
            text={t('table')}
            icon={< FontAwesomeIcon icon={icon({ name: 'table', style: 'light' })} style={{ fontSize: iconFontSize }} />}
            bgcolor='#FFFFFF'
            disp='inline-block'
          /></>}
        <BoxButton
          canDrag={canDrag}
          onClick={() => onClick && onClick({ type: ItemTypes.IMG })}
          type={ItemTypes.IMG}
          text={t('image')}
          icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={{ fontSize: iconFontSize }} />}
          bgcolor='#FFFFFF'
          disp='inline-block'
        />
        <BoxButton
          canDrag={canDrag}
          onClick={() => onClick && onClick({ type: ItemTypes.SHAPE })}
          type={ItemTypes.SHAPE}
          text={t('shape')}
          icon={< FontAwesomeIcon icon={icon({ name: 'star', style: 'light' })} style={{ fontSize: iconFontSize }} />}
          bgcolor='#FFFFFF'
          disp='inline-block'
        />
        <BoxButton
          canDrag={canDrag}
          onClick={() => onClick && onClick({ type: ItemTypes.LINE })}
          type={ItemTypes.LINE}
          text={t('line')}
          icon={< FontAwesomeIcon icon={icon({ name: 'dash', style: 'light' })} style={{ fontSize: iconFontSize }} />}
          bgcolor='#FFFFFF'
          disp='inline-block'
        />
        {!columnId && <>
          <BoxButton
            canDrag={canDrag}
            onClick={() => onClick && onClick({ type: ItemTypes.FILE_PDF })}
            type={ItemTypes.FILE_PDF}
            text={"PDF"}
            icon={< FontAwesomeIcon icon={icon({ name: 'file-pdf', style: 'light' })} style={{ fontSize: iconFontSize }} />}
            bgcolor='#FFFFFF'
            disp='inline-block'
          />
        </>}
        {currentDocument.type === 'template' &&
          <>
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.SELECTOR_VARIABLE })}
              type={ItemTypes.SELECTOR_VARIABLE}
              text={t('selector_variable')}
              icon={< FontAwesomeIcon
                icon={variablesIcons[ItemTypes.SELECTOR_VARIABLE]}
                style={{ fontSize: iconFontSize }} />}
              disp='inline-block'
            />
            <BoxButton
              canDrag={canDrag}
              onClick={() => onClick && onClick({ type: ItemTypes.CHECKBOXS_VARIABLE })}
              type={ItemTypes.CHECKBOXS_VARIABLE}
              text={t('checkboxs_variable')}
              icon={< FontAwesomeIcon
                icon={variablesIcons[ItemTypes.CHECKBOXS_VARIABLE]}
                style={{ fontSize: iconFontSize }} />}
              disp='inline-block'
            />
          </>}
      </div>
    </div>
  )
}

export default BoxButtonsWrapper
