import React, { useLayoutEffect, useRef, useState } from 'react'
import TextStylingComponent from '../TextStylingComponent/TextStylingComponent';
import FloatyStylingWrapper from './FloatyStylingWrapper';

const FloatyTextStyling = ({
  editorState,
  setEditorState,
  defaultFontSize,
  defaultFontFamily,
  defaultColor,
  boxId,
  updateBox,
  hasAlignement,
  textStyle,
  hasForm,
  hasList
}) => {
  const [menuPosition, setMenuPosition] = useState({ top: null, left: null });
  const ref = useRef()

  useLayoutEffect(() => {
    const selection = window.getSelection && window.getSelection();
    if (selection && selection.rangeCount > 0 && editorState.getSelection().getStartOffset() !== editorState.getSelection().getEndOffset()) {
      const selectionRange = selection.getRangeAt(0);
      const { top, left, right } = selectionRange.getBoundingClientRect()
      setMenuPosition((oldPos) => {
        if (oldPos.top === null || oldPos.left === null) {
          return { top: top - 50, left: editorState.getSelection().getIsBackward() ? right : left }
        }
        return oldPos
      })
    } else {
      setMenuPosition({ top: null, left: null })
    }
  }, [editorState]);

  useLayoutEffect(() => {
    if (menuPosition.top !== null && menuPosition.left !== null) {
      if (ref.current && menuPosition.left + ref.current.clientWidth > window.innerWidth) {
        setMenuPosition({ top: menuPosition.top, left: window.innerWidth - ref.current.clientWidth })
      }
    }
  }, [menuPosition])

  if (menuPosition.top === null || menuPosition.left === null) return null

  return (
    <FloatyStylingWrapper menuPosition={menuPosition} ref={ref}>
      <TextStylingComponent
        editorState={editorState}
        setEditorState={setEditorState}
        defaultFontSize={defaultFontSize}
        defaultFontFamily={defaultFontFamily}
        defaultColor={defaultColor}
        updateBoxOnStylingChange={() => null}
        hasOptionsAfter={false}
        boxId={boxId}
        updateBox={updateBox}
        hasAlignement={hasAlignement}
        hasForm={hasForm}
        textStyle={textStyle}
        hasList={hasList}
      />
    </FloatyStylingWrapper>
  )
}

export default FloatyTextStyling
