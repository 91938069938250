// ANT Design
import {
  BorderOuterOutlined,
} from '@ant-design/icons';
import { InputNumber, Select, Checkbox, Form, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ColorSelector from './ColorSelector';
import TitleFontSelector from './TitleFontSelector';
import './Utils.css';

import { marginOnPageButton, maxFontSize, minFontSize, selectorMaxWidth, unitName } from '../../constants/gridConfig';
import { getTitleStyle } from '../Title/titleUtils';
import { handleTitleIndex } from '../RightSide/configurationModal/utils';
import { useStore } from '../../store/store';
import { contentPadding } from '../../constants/gridConfig';
import { borderIcons } from '../../constants/constants';

const selector = ({ configuration }) => ({
  configuration,
});

const indicators = {
  letterMin: 'a',
  letterMaj: 'A',
  chiffre: '1',
  romain: 'I',
  '': '',
};

export const handleTransformIndicator = (indicator) => {
  for (const [key, value] of Object.entries(indicators)) {
    if (key === indicator) return value;
  }
};

const TitlePreviewDisplay = (text) => {
  return (
    <div style={{ marginLeft: '1%' }}>
      {text.text}
      {text.number}
    </div>
  );
};

export const BlockTextStyle = ({
  rules: styleRules,
  changeHandler,
}
) => {
  const { t } = useTranslation();

  return (
    <Form.Item label='Style du texte'>
      <Form.Item label={t('bold')} style={{ display: 'inline-block', margin: '0 8px' }}>
        <Checkbox
          key='bold'
          style={{ fontWeight: 'bold' }}
          checked={styleRules.fontWeight !== ''}
          onChange={changeHandler.fontWeight}
        />
      </Form.Item>
      <Form.Item label={t('italic')} style={{ display: 'inline-block', margin: '0 8px' }}>
        <Checkbox
          key='italic'
          style={{ fontStyle: 'italic' }}
          checked={styleRules.fontStyle !== ''}
          onClick={changeHandler.fontStyle}
        />
      </Form.Item>
      <Form.Item label={t('underline')} style={{ display: 'inline-block', margin: '0 8px' }}>
        <Checkbox
          key='underline'
          style={{ textDecoration: 'underline' }}
          checked={styleRules.textDecoration !== ''}
          onChange={changeHandler.textDecoration}
        />
      </Form.Item>
    </Form.Item>
  );
}

export const BlockTextColor = ({ name, onChangeColor, styleRules }) => {
  return (
    <Form.Item
      label='Couleur du texte'
      name={name}
    >
      <ColorSelector
        onChange={onChangeColor}
        forcedValue={styleRules?.color?.label}
      />
    </Form.Item>
  );
}

const TitleConfiguration = ({
  onChangeWeight,
  onChangeColor,
  onChangeFontStyle,
  onChangeTextDecoration,
  onChangeFontSize,
  onChangeVisibility,
  onChangeBackgroundColor,
  onChangeBorderColor,
  onChangeBorderPlace,
  styleRules,
  title,
  level,
  onChangeType,
  titleType,
  type,
  onChangeMarginTop,
  onChangeMarginBottom,
}) => {
  const { t } = useTranslation();
  const { configuration } = useStore(selector);
  const titleString = 'Style du titre de niveau ';

  const changeHandler = {
    fontWeight: onChangeWeight,
    fontStyle: onChangeFontStyle,
    textDecoration: onChangeTextDecoration,
  }

  return (
    <div style={{
      border: '1px solid lightgray',
      borderRadius: '5px',
      padding: '1rem 0',
      margin: '1rem 0',
    }}>
      {type === 'title' ? (
        <div
          style={{
            height: '80px',
            overflow: 'hidden',
            margin: 'auto',
            marginLeft: contentPadding,
            fontFamily: configuration[titleType].fontFamily !== '' ? configuration[titleType].fontFamily : configuration.fontFamily
          }}
        >
          <span
            style={getTitleStyle(
              configuration[titleType].backgroundColor,
              configuration[titleType].borders,
              configuration[titleType],
              configuration[titleType].color.style,
              configuration[titleType].rest
            )}
          >
            {handleTitleIndex(title)}
            <TitlePreviewDisplay text={titleString} number={level} />
          </span>
        </div>
      ) : (
        <Form.Item
          label={
            <div style={{ fontSize: 16, fontWeight: 'bold' }}>
              {`Titres de niveau ${level}`}
            </div>
          }
        >
        </Form.Item>
      )}
      {onChangeVisibility && level !== '1' && (
        <Form.Item
          label="Visibilité du titre"
          name={`${type}-${level}-visibility`}
        >
          <Checkbox
            checked={styleRules.visibility !== ''}
            onChange={onChangeVisibility}
          >
          </Checkbox>
        </Form.Item>
      )}
      {
        (type === 'title' || (type === 'summary' && styleRules.visibility === 'visible')) &&
        <>
          {type !== 'summary' && <Form.Item
            label="Format de la numérotation"
            name={`${type}-${level}-format`}
            initialValue={styleRules.typeTitle}
          >
            <Select
              disabled={configuration.hideTitleIndex && titleType === 'title1'}
              onChange={onChangeType}
              style={{
                maxWidth: selectorMaxWidth,
                width: '90px',
              }}
              options={[
                { label: 'IV', value: 'romain' },
                { label: '1', value: 'chiffre' },
                { label: 'A', value: 'letterMaj' },
                { label: 'a', value: 'letterMin' },
              ]}
            >
            </Select>
          </Form.Item>}
          <Form.Item
            label="Police du titre"
            name={`${type}-${level}-font`}
          >
            <TitleFontSelector selectorType={titleType} currentTab={type} />
          </Form.Item>
          <Form.Item
            label={t('font-size')}
            name={`${type}-${level}-font-size`}
            initialValue={styleRules.fontSize}
          >
            <InputNumber
              min={minFontSize}
              max={maxFontSize}
              onChange={onChangeFontSize}
              addonAfter={unitName}
              style={{ width: 150, marginLeft: '5px' }}
            />
          </Form.Item>
          <BlockTextStyle
            rules={styleRules}
            changeHandler={changeHandler}
          />
          <BlockTextColor name={`${type}-${level}-color`} styleRules={styleRules} onChangeColor={onChangeColor} />
        </>
      }
      {
        onChangeBorderPlace && (
          <Form.Item
            label="Affichage de la bordure"
          >
            {['top', 'bottom', 'left', 'right'].map((border) => (
              <Form.Item
                name={`${type}-${level}-border-${border}`}
                style={{
                  display: 'inline-block',
                  marginBottom: '0px',
                }}
              >
                <Button
                  key={`border-${border}`}
                  style={{ marginRight: marginOnPageButton, marginBottom: 0 }}
                  onClick={onChangeBorderPlace(title, border)}
                  className={`RichEditor-styleButton ${styleRules.borders[border]
                    ? '  RichEditor-activeButton'
                    : ''
                    }`}
                  icon={borderIcons[border]}
                />
              </Form.Item>
            ))}

            <Form.Item
              name={`${type}-${level}-border-all`}
              style={{
                display: 'inline-block',
                marginBottom: '0px'
              }}
            >
              <Button
                className={`RichEditor-styleButton ${styleRules.borders.right === true &&
                  styleRules.borders.left === true &&
                  styleRules.borders.top === true &&
                  styleRules.borders.bottom === true
                  ? '  RichEditor-activeButton'
                  : ''
                  }`}
                onMouseDown={onChangeBorderPlace(title, 'all')}
                icon={<BorderOuterOutlined />}
              />
            </Form.Item>
          </Form.Item>
        )
      }
      {
        onChangeBorderColor && (
          <Form.Item
            label="Couleur des bordures"
            name={`${type}-${level}-borderColor`}
          >
            <ColorSelector
              onChange={onChangeBorderColor}
              forcedValue={styleRules.borders.color.label}
            />
          </Form.Item>
        )
      }
      {
        onChangeBackgroundColor && (
          <Form.Item
            label="Couleur de fond"
            name={`${type}-${level}-backgroundColor`}
          >
            <ColorSelector
              onChange={onChangeBackgroundColor}
              forcedValue={styleRules.backgroundColor.label}
            />
          </Form.Item>
        )
      }
      <Form.Item
        label={'Marge supérieure'}
      >
        <InputNumber value={styleRules.marginTop}
          onChange={onChangeMarginTop}
          min={0}
          max={60}
          addonAfter={unitName}
          style={{ width: 150 }}
        />
      </Form.Item>
      <Form.Item
        label={'Marge inférieure'}
      >
        <InputNumber value={styleRules.marginBottom}
          onChange={onChangeMarginBottom}
          min={0}
          max={60}
          addonAfter={unitName}
          style={{ width: 150 }}
        />
      </Form.Item>
    </div >
  );
};

export default TitleConfiguration;
