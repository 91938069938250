import React from 'react'
import { Switch } from 'antd'
import { useStore } from '../../store/store'
import { isAVariableBox } from '../../utils/boxes'

const HideInFormSwitcher = ({ boxId }) => {
  const box = useStore(({ boxes, }) => boxes.find((b) => b.id === boxId))
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  const boxVariableSwitcher = useStore(({ boxVariableSwitcher }) => boxVariableSwitcher)
  const boxHideInFormSwitcher = useStore(({ boxHideInFormSwitcher }) => boxHideInFormSwitcher)

  if (!isTemplate || !box) return null

  return (
    <div style={{
      display: 'flex',
      marginRight: 4,
      alignItems: 'center',
      color: 'white'
    }}>
      <span>Afficher dans le formulaire : </span>
      <Switch
        checked={isAVariableBox(box.type) && !box.hideInForm}
        style={{
          backgroundColor: isAVariableBox(box.type) && !box.hideInForm ? undefined : 'gray'
        }}

        onChange={() => {
          if (!isAVariableBox(box.type))
            boxVariableSwitcher(box.id)
          else
            boxHideInFormSwitcher(box.id)
        }
        }
      />
    </div>
  )
}

export default HideInFormSwitcher
