
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button,
  Input,
  Row,
  Switch,
  Table,
  Col,
  Tooltip,
  Popconfirm,
  Form,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import { useCallback, useState } from 'react';
import { useStore } from '../../../store/store';
import ColorSelector from '../../common/ColorSelector';
import './tableConfig.css';
import { ItemTypes, borderIcons, collapseConfigPoint, defaultSelectorConfiguration, styleCheckbox } from '../../../constants/constants';
import TitleFontSelector from '../../common/TitleFontSelector';
import { useTranslation } from 'react-i18next';
import { maxFontSize, minFontSize } from '../../../constants/gridConfig';
import { BlockTextColor, BlockTextStyle } from '../../common/TitleConfiguration';
import { BorderOuterOutlined } from '@ant-design/icons';
import { getBackgroundBorderStyle, iconButtonStyle, navbarButtonStyle } from '../../../utils/styles';
import { NavbarListButton } from '../../Layout/NavbarListButton';
import CheckboxCustom from '../../common/CheckboxCustom';
import FontPicker from '../../common/FontPicker';
const selector = ({
  templates,
  configuration,
  setSelectedStyle,
  currentTemplate,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
  styleRules,
}) => ({
  selectorStyles: templates.filter((t) => t.pattern === 'style'),
  templates,
  configuration,
  setSelectedStyle,
  templatable: currentTemplate.templatable,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
  styleRules
});

export const SelectorTabs = () => {
  const { configuration, setConfiguration } = useStore(selector);
  const [selectedStyle, setSelectedStyle] = useState(configuration.selectorStyles[0]);
  const [newStyleName, setNewStyleName] = useState('');
  const [isEditingStyleName, setIsEditingStyleName] = useState(false);
  const { t } = useTranslation();
  let styleList = [...configuration.selectorStyles];
  const styleData = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: (18 + 16) * 3 + 16,
    },
    {
      title: 'Style',
      dataIndex: 'style',
      key: 'style',
      onCell: (element, rowIndex) => {
        return {
          key: `style-config-${rowIndex}`,
          onClick: (event) => {
            handleSelectedStyle(element);
          },
        };
      },
    }
  ];

  const handleRemoveStyle = (ID) => {
    styleList.forEach((element, index) => {
      if (element.id === ID) {
        styleList.splice(index, 1);
        setConfiguration({
          ...configuration.selectorStyles,
          selectorStyles: styleList,
        });
      }
    });
  };

  const handleRenameStyle = (ID, event) => {
    let styleToRename = {
      ...configuration.selectorStyles.find((element) => element.id === ID),
      name: event.target.value,
    };
    setConfiguration({
      selectorStyles: [
        ...configuration.selectorStyles.map((tableStyle) => {
          if (tableStyle.id === ID) {
            return styleToRename;
          }
          return tableStyle;
        }),
      ],
    });
    setIsEditingStyleName(null);
    setEditedName('');
    setSelectedStyle(styleToRename);
  };

  const handleCopyStyle = (copiedId) => {
    const copiedStyle = configuration.selectorStyles.find((s) => s.id === copiedId);
    let newStyle = {
      ...copiedStyle,
      id: configuration.selectorStylesIdCount + 1,
      name: `${copiedStyle.name} - Copie`,
    };
    let selectorStyles = [
      ...configuration.selectorStyles,
      newStyle,
    ];

    setConfiguration({
      ...configuration,
      selectorStyles,
      selectorStylesIdCount: configuration.selectorStylesIdCount + 1,
    });
  };

  const [editedName, setEditedName] = useState('');
  const dataSourceMapped = [];

  const iconStyle = {
    margin: '.5rem',
    fontSize: '1.5em',
  }
  configuration.selectorStyles.forEach((element) => {
    dataSourceMapped.push({
      key: `tableStyle-${element.id}`,
      id: element.id,
      style:
        isEditingStyleName === element.id ? (
          <Input
            value={editedName}
            id='styleNameInput2'
            style={{ width: '60%' }}
            onChange={(e) => setEditedName(e.target.value)}
            onPressEnter={(e) => {
              if (element.id !== 0 && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
            onBlur={(e) => {
              if (element.id !== 0 && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
          />
        ) : (
          element.name
        ),
      actions: (
        <div>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Renommer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'edit', style: 'light' })}
              style={{
                ...iconStyle,
                color: element.id === 0 ? 'lightgray' : 'black',
              }}
              onClick={element.id === 0 ? null : (e) => {
                setIsEditingStyleName(element.id);
                setEditedName(element.name);
              }}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Dupliquer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'copy', style: 'light' })}
              style={iconStyle}
              onClick={(e) => handleCopyStyle(element.id)}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Supprimer'}
          >
            {element.id === 0 ? (
              <FontAwesomeIcon
                icon={icon({ name: 'trash', style: 'light' })}
                style={{
                  ...iconStyle,
                  color: 'lightgray'
                }}
              />
            ) : (
              <Popconfirm
                title='Voulez vous vraiment supprimer ce style ?'
                onConfirm={() => { handleRemoveStyle(element.id); }}
                okText='Oui'
                cancelText='Non'
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'trash', style: 'light' })}
                  style={{
                    ...iconStyle,
                    color: 'red'
                  }}
                />
              </Popconfirm>
            )}
          </Tooltip>
        </div>
      ),
    });
  });

  const [isAddTableStyleLoading, setIsAddTableStyleLoading] = useState(false);

  const handleSelectedStyle = (e) => {
    configuration.selectorStyles.forEach((element) => {
      if (element.id === e.id) {
        setSelectedStyle(element);
      }
    });
    return selectedStyle;
  };

  const loadingAnimation = () => {
    setIsAddTableStyleLoading(true);
    setTimeout(() => {
      setIsAddTableStyleLoading(false);
    }, 1000);
  };

  // const handleChange
  const handleSaveChanges = (updatedTableStyle) => {
    const updatedTableStyleIndex = configuration.selectorStyles.findIndex(
      (element) => element.id === updatedTableStyle.id
    );
    let newselectorStyles = [...configuration.selectorStyles];
    newselectorStyles[updatedTableStyleIndex] = updatedTableStyle;
    setSelectedStyle(updatedTableStyle);
    setConfiguration({
      ...configuration.selectorStyles,
      selectorStyles: newselectorStyles
    });
  };

  const handleAddTableStyle = (e) => {
    loadingAnimation();
    if (newStyleName.length > 0) {
      const newStyle = {
        ...defaultSelectorConfiguration,
        id: configuration.selectorStylesIdCount,
        name: newStyleName,
      };
      let selectorStyles = [
        ...configuration.selectorStyles,
        newStyle,
      ];
      setConfiguration({
        ...configuration,
        selectorStyles,
        selectorStylesIdCount: configuration.selectorStylesIdCount + 1,
      });
      setSelectedStyle({ ...newStyle });
      setNewStyleName('');
    } else {
      alert('Un nom de template est obligatoire');
    }
  };

  const handleChangeFontSize = useCallback(() => (value) => {

    handleSaveChanges({
      ...selectedStyle,
      fontSize: value,
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeFontWeight = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontWeight: value.target.checked ? 'bold' : '',
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeFontStyle = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontStyle: value.target.checked ? 'italic' : '',
    });
  }, [handleSaveChanges, selectedStyle]);
  const handleChangeFontDecoration = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      textDecoration: value.target.checked ? 'underline' : '',
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeStyleBox = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      styleBox: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBackgroundColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      backgroundColor: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeSizeBox = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      box: {
        ...selectedStyle.box,
        width: value,
        height: value,
      }
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeDirectionLabel = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      box: {
        ...selectedStyle.box,
        direction: value.target.checked ? "rtl" : "ltr",
      }
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBorderColor = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      borderColor: { "label": value, "style": configuration.colors.find((color) => color.label === value).style },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBorderSize = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      bordersSize: value,
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeBorderRadius = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      borderRadius: value,
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeColorCheck = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      check: {
        ...selectedStyle.check,
        ...{ color: { "label": value, "style": configuration.colors.find((color) => color.label === value).style } }
      },
    });
  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangeSeparator = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      separator: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeWidth = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      width: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeBorderPlace = useCallback(() => (value) => {
    if (value === 'all') {
      handleSaveChanges({
        ...selectedStyle,
        borders: {
          ...selectedStyle.borders,
          top: true,
          bottom: true,
          left: true,
          right: true,
        },
      });
    } else {
      handleSaveChanges({
        ...selectedStyle,
        borders: {
          ...selectedStyle.borders,
          [value]: !selectedStyle.borders[value],
        },
      });
    }

  }, [handleSaveChanges, selectedStyle, configuration]);

  const handleChangePaddingTop = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      paddingTopBottom: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeLeftRight = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      paddingLeftRight: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangePosition = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      position: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const handleChangeFontFamily = useCallback(() => (value) => {
    handleSaveChanges({
      ...selectedStyle,
      fontFamily: value,
    });
  }, [handleSaveChanges, selectedStyle]);

  const changeHandler = useCallback(() => ({
    borderRadius: handleChangeBorderRadius(),
    backgroundColor: handleChangeBackgroundColor(),
    borderSize: handleChangeBorderSize(),
    borderColor: handleChangeBorderColor(),
    color: handleChangeColor(),
    fontSize: handleChangeFontSize(),
    paddingLeftRight: handleChangeLeftRight(),
    paddingTop: handleChangePaddingTop(),
    position: handleChangePosition(),
    width: handleChangeWidth(),
    separator: handleChangeSeparator(),
    fontFamily: handleChangeFontFamily(),
    fontWeight: handleChangeFontWeight(),
    fontStyle: handleChangeFontStyle(),
    textDecoration: handleChangeFontDecoration(),
  }), [handleChangeBorderRadius, handleChangeBackgroundColor, handleChangeBorderSize, handleChangeBorderColor, handleChangeColor, handleChangeFontSize, handleChangeLeftRight, handleChangePaddingTop, handleChangePosition, handleChangeWidth, handleChangeSeparator, handleChangeFontFamily, handleChangeFontWeight, handleChangeFontStyle, handleChangeFontDecoration])



  const getStyle = () => {
    return {
      backgroundColor: selectedStyle?.backgroundColor?.style,
      borderRadius: selectedStyle?.borderRadius,
      border: `${selectedStyle?.bordersSize}px solid ${selectedStyle?.borderColor?.style}`,
      color: selectedStyle?.color?.style,
      fontSize: selectedStyle?.fontSize,
      display: 'flex',
      justifyContent: selectedStyle?.position,
      width: `${selectedStyle?.width}px`,
      fontFamily: selectedStyle?.fontFamily,
      padding: `${selectedStyle?.paddingTopBottom}px ${selectedStyle?.paddingLeftRight}px`,
      fontWeight: `${selectedStyle?.fontWeight}`,
      textDecoration: `${selectedStyle?.textDecoration}`,
      fontStyle: `${selectedStyle?.fontStyle}`,
    }
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: window.innerWidth <= collapseConfigPoint ? "column" : "row",
      width: "100%"
    }}>
      <div style={{ textAlign: 'center', width: window.innerWidth <= collapseConfigPoint ? "100%" : "40%" }}>
        <h5>Liste des styles des choix</h5>
        <div
          style={{
            margin: '2rem 0',
            padding: '0 2rem',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Input
            placeholder='Nom du nouveau Style'
            value={newStyleName}
            onChange={(e) => {
              e.preventDefault();
              setNewStyleName(e.target.value);
            }}
            onPressEnter={handleAddTableStyle}
          />
          <Button
            type='primary'
            htmlType='submit'
            loading={isAddTableStyleLoading}
            style={{ marginLeft: '.5rem' }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={handleAddTableStyle}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'plus', style: 'light' })}
              style={{ fontSize: '1.5em' }}
            />
          </Button>
        </div>
        <Table
          columns={styleData}
          dataSource={dataSourceMapped}
          rowClassName={(record, _rowIndex) => { return record.style === selectedStyle.name ? 'tableSelectedRow' : '' }}
          pagination={false}
        />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: window.innerWidth <= collapseConfigPoint ? "100%" : "60%", margin: 20 }}>
        <div style={{
          ...getStyle(),
        }}>
          <div>preview</div>
        </div>
        <div style={{ width: "100%" }}>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style du choix
            </legend>
            <Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Couleur de fond"
                name={`backgroundColor`}
              >
                <ColorSelector
                  onChange={(e) => changeHandler().backgroundColor(e)}
                  forcedValue={selectedStyle?.backgroundColor?.label}
                />
              </Form.Item>

              <Form.Item
                label="Couleur des bordures"
                name={`borderColorCheckBox`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <ColorSelector
                  onChange={(e) => changeHandler().borderColor(e)}
                  forcedValue={selectedStyle?.borderColor?.label}
                />
              </Form.Item>
              <Form.Item
                label="Taille de la bordure"
                name={`borderSize-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}

              >
                <InputNumber
                  defaultValue={selectedStyle?.bordersSize}
                  min={0}
                  max={20}
                  onChange={(e) => changeHandler().borderSize(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Radius de la bordure"
                name={`borderRadius-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={styleRules.fontSize}
              >
                <InputNumber
                  defaultValue={selectedStyle?.borderRadius}
                  min={0}
                  max={20}
                  onChange={(e) => changeHandler().borderRadius(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Marge hauteur"
                name={`paddingTop-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <InputNumber
                  defaultValue={selectedStyle?.paddingTopBottom}
                  min={0}
                  max={40}
                  onChange={(e) => changeHandler().paddingTop(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Marge du latérale"
                name={`paddingLeft-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={styleRules.fontSize}
              >
                <InputNumber
                  defaultValue={selectedStyle?.paddinngLeftRight}
                  min={0}
                  max={40}
                  onChange={(e) => changeHandler().paddingLeftRight(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item
                label="Taille du choix dans les colonnes dessins"
                name={`widthSelector-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              >
                <div style={{ display: 'flex', alignItems: "center", gap: 5 }}>
                  <InputNumber
                    defaultValue={selectedStyle?.width}
                    min={0}
                    max={800}
                    onChange={(e) => changeHandler().width(e)}
                    style={{ marginLeft: '5px' }}
                  />
                </div>
              </Form.Item>
            </Form.Item>
          </fieldset>
          <fieldset style={{
            border: `1px dashed "#b1b1b1"`,
            marginBottom: '15px'
          }}>
            <legend style={{
              width: "fit-content",
              marginLeft: 16,
              fontSize: 14,
              padding: '0 8px',
              fontStyle: 'italic',
              color: "#b1b1b1",
              fontWeight: "bold",
            }}>
              Style du texte
            </legend>
            <Form.Item>
              <Form.Item style={{ display: 'inline-block', margin: '0 8px' }}
                label='Couleur du texte'
                name={`color-${selectedStyle?.id}`}
              >

                <ColorSelector
                  onChange={(e) => changeHandler().color(e)}
                  disabled={selectedStyle.id === 0}
                  forcedValue={selectedStyle.id === 0 ? configuration.baseFontColor.label : selectedStyle?.color?.label}
                />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label="Police des labels"
                name={`fontFamily-${selectedStyle?.id}`}
              >
                <FontPicker
                  disabled={selectedStyle.id === 0}
                  forcedValue={selectedStyle.id === 0 ? configuration.fontFamily : selectedStyle?.fontFamily}
                  onChange={(e) => changeHandler().fontFamily(e)} />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', margin: '0 8px' }}
                label={t('font-size')}
                name={`fontSize-selector-variables-${selectedStyle?.id}`}
              >
                <InputNumber
                  min={minFontSize}
                  max={maxFontSize}
                  disabled={selectedStyle.id === 0}
                  defaultValue={selectedStyle.id === 0 ? configuration.fontSize : selectedStyle?.fontSize}
                  onChange={(e) => changeHandler().fontSize(e)}
                  style={{ marginLeft: '5px' }}
                />
              </Form.Item>
              <Form.Item label={t('bold')} style={{ display: 'inline-block', margin: '0 8px' }}>
                <Checkbox
                  key='bold'
                  style={{ fontWeight: 'bold' }}
                  checked={selectedStyle?.fontWeight === 'bold'}
                  onChange={(e) => changeHandler().fontWeight(e)}
                />
              </Form.Item>
              <Form.Item label={t('italic')} style={{ display: 'inline-block', margin: '0 8px' }}>
                <Checkbox
                  key='italic'
                  style={{ fontStyle: 'italic' }}
                  checked={selectedStyle?.fontStyle === 'italic'}
                  onChange={(e) => changeHandler().fontStyle(e)}
                />
              </Form.Item>
              <Form.Item label={t('underline')} style={{ display: 'inline-block', margin: '0 8px' }}>
                <Checkbox
                  key='underline'
                  style={{ textDecoration: 'underline' }}
                  checked={selectedStyle?.textDecoration === 'underline'}
                  onChange={(e) => changeHandler().textDecoration(e)}
                />
              </Form.Item>
              <Form.Item
                label="Position du texte"
                name={`position-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={styleRules.fontSize}
              >
                <Select
                  options={[{ value: "start", label: 'Gauche' }, { value: "center", label: 'Centre' }, { value: "end", label: 'Droite' }]}
                  onChange={(e) => changeHandler().position(e)}
                  defaultValue={selectedStyle?.position}
                ></Select>
              </Form.Item>
              <Form.Item
                label="Séparateur si choix multiple"
                name={`separator-${selectedStyle?.id}`}
                style={{ display: 'inline-block', margin: '0 8px' }}
              // initialValue={styleRules.fontSize}
              >
                <Select
                  options={[{ value: "\n", label: 'Saut de ligne' }, { value: " ", label: 'Espace' }, { value: "|", label: '|' }, { value: ";", label: ';' }, { value: ",", label: ',' }, { value: ":", label: ':' }, { value: ".", label: '.' }, { value: "-", label: '-' }]}
                  onChange={(e) => changeHandler().separator(e)}
                  defaultValue={selectedStyle?.separator}
                  style={{
                    minWidth: 126
                  }}
                ></Select>
              </Form.Item>
            </Form.Item>
          </fieldset>
        </div>

      </div>
    </div>
  );
};
export default SelectorTabs;
