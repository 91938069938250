import React from 'react'
import FormBoxName from './FormBoxName'
import { variableStyle } from '../../../utils/styles'
import SelectionMiddle from '../SelectionMiddle'
import LabelsArray from '../LabelsArray'
import FormBoxRemark from './FormBoxRemark'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../../store/store'
import TriggerArray from './TriggerArray'
import DefaultValueBox from './DefaultValueBox'
import HideInFormSwitcher from '../../common/HideInFormSwitcher'

const LeftPart = ({ box, onBlur, form }) => {
  const { t } = useTranslation();
  const configuration = useStore(({ configuration }) => configuration)
  const isDisabled = box.from_linked_section
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <div style={{ marginBottom: 16, fontWeight: "bold" }}>
        <HideInFormSwitcher boxId={box.id} />
      </div>
      <FormBoxName box={box} onBlur={onBlur} form={form} />
      <SelectionMiddle
        box={box}
        onBlur={onBlur}
        form={form}
        arrayKey="options"
        selectionKey="selected"
      />
      <Form.Item
        label={t('selector-style')}
        name="selectorStyleId"
        initialValue={box?.selectorStyleId ?? configuration.selectorStyles[0].id}
      >
        <Select
          onSelect={onBlur}
          options={configuration.selectorStyles.map(style => ({ value: style.id, label: style.name }))}
          disabled={isDisabled}
        />
      </Form.Item>
      <FormBoxRemark box={box} onBlur={onBlur} form={form} />
      <LabelsArray
        box={box}
        onBlur={onBlur}
        form={form}
        arrayKey="options"
        selectionKey="selected"
      />
    </div>
  )
}

const RightPart = ({ box, variablesGroupedByContainer }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <DefaultValueBox box={box} />
      <TriggerArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} />
    </div>
  )
}

const FormSelector = ({ box, onBlur, form, formPart, variablesGroupedByContainer }) => {
  return <>
    {formPart === 'left' && <LeftPart box={box} onBlur={onBlur} form={form} />}
    {formPart === 'right' && <RightPart box={box} variablesGroupedByContainer={variablesGroupedByContainer} />}
  </>
}

export default FormSelector
