import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import './TableWithDrag.less'

const DraggableRow = ({
  children,
  moveRow,
  style,
  onDrop,
  canDrag = true,
  canDrop = true,
  onAdd,
  onAddBottom,
  dragIconTitle,
  ...props }) => {
  const trRef = useRef();
  const lineRef = useRef();
  const trBottomRef = useRef();


  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredBottom, setIsHoveredBottom] = useState(false);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "table-row",
      item: { id: props["data-row-key"] },
      collect: (monitor) => ({
        isDragging: props["data-row-key"] === monitor.getItem()?.id,
      }),
      canDrag,
      end: () => onDrop(props["data-row-key"]),
    }),
    [props["data-row-key"], onDrop, canDrag]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "table-row",
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover({ id: draggedId }) {
        if (draggedId !== props["data-row-key"]) {
          moveRow(props["data-row-key"], draggedId)
        }
      },
    }),
    [moveRow, props["data-row-key"]]
  );

  const opacity = isDragging ? 0.8 : 1;

  const buttonHeight = 12

  useEffect(() => {
    const check = (event) => {
      if (onAdd && lineRef.current) {

        const { top, width } = lineRef.current.getBoundingClientRect();
        const mouseY = event.clientY;
        const relativeY = mouseY - top;

        if (relativeY >= 0 && relativeY <= buttonHeight &&
          (event.target.closest(".table-drag-row") || event.target.closest(".table-add-row"))
        ) {
          trRef.current.style.top = `${top}px`
          trRef.current.style.width = `${width}px`
          setIsHovered(true);
        } else {
          setIsHovered(false);
        }
      }
    };
    window.addEventListener('mousemove', check);
    return () => {
      window.removeEventListener('mousemove', check);
    };
  }, [onAdd]);

  useEffect(() => {
    const check = (event) => {
      if (onAddBottom && lineRef.current) {

        const { bottom, width } = lineRef.current.getBoundingClientRect();
        const mouseY = event.clientY;

        const relativeY = mouseY - bottom;

        if (relativeY >= 0 && relativeY <= buttonHeight
        ) {
          trBottomRef.current.style.top = `${bottom}px`
          trBottomRef.current.style.width = `${width}px`
          setIsHoveredBottom(true);
        } else {
          setIsHoveredBottom(false);
        }
      }
    };
    window.addEventListener('mousemove', check);
    return () => {
      window.removeEventListener('mousemove', check);
    };
  }, [onAddBottom]);


  return (
    <>
      {onAdd && <tr
        ref={trRef}
        style={{
          position: 'fixed',
          height: buttonHeight,
          justifyContent: "center",
          cursor: "pointer",
          zIndex: 1,
          display: isHovered ? 'flex' : 'none'
        }}
        className='table-add-row'
        onMouseLeave={() => setIsHovered(false)}
        onClick={(e) => onAdd(e, props["data-row-key"])}
      >
        <td
          ref={(node) => {
            if (node) {
              node.style.setProperty("padding", "0", "important");
              node.style.setProperty("border", "none", "important");
            }
          }}
          style={{
            width: '100%',
            height: 6,
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isHovered ? '#4096ff63' : 'transparent',
            transition: "ease-in-out 0.2s",
          }}
        >
          {isHovered && <Button shape="circle" size={14}
            style={{
              width: buttonHeight,
              height: buttonHeight,
              minWidth: buttonHeight,
              minHeight: buttonHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
            }}
            icon={< FontAwesomeIcon icon={icon({ name: 'plus', style: 'light' })}
              style={{ margin: 0, fontSize: 9 }} />}
          />}
        </td>
      </tr>}
      <tr {...props}
        className='table-drag-row'
        style={{ ...style, opacity, position: 'relative' }} ref={(el) => {
          lineRef.current = el
          if (canDrop) {
            drop(el);
            preview(el);
          }
        }}
      >
        {React.Children.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  touchAction: 'none',
                  cursor: !canDrag ? "not-allowed" : 'grab',
                }}
                  ref={drag}
                  title={dragIconTitle}
                >
                  <FontAwesomeIcon
                    icon={icon({ name: 'arrows-up-down-left-right', style: 'solid' })}
                    style={{
                      fontSize: 14,
                      opacity: canDrag ? 1 : 0.3
                    }}
                  />
                </div>

              ),
            });
          }
          return child;
        })}
      </tr>
      {onAddBottom && <tr
        ref={trBottomRef}
        className='table-add-bottom-row'
        style={{
          position: 'fixed',
          height: buttonHeight,
          justifyContent: "center",
          cursor: "pointer",
          zIndex: 1,
          display: isHoveredBottom ? 'flex' : 'none'
        }}
        onMouseLeave={() => setIsHoveredBottom(false)}
        onClick={(e) => onAddBottom(e, props["data-row-key"])}
      >
        <td
          ref={(node) => {
            if (node) {
              node.style.setProperty("padding", "0", "important");
              node.style.setProperty("border", "none", "important");
            }
          }}
          style={{
            width: '100%',
            height: 6,
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isHoveredBottom ? '#4096ff63' : 'transparent',
            transition: "ease-in-out 0.2s",
          }}
        >
          {isHoveredBottom && <Button shape="circle" size={14}
            style={{
              width: buttonHeight,
              height: buttonHeight,
              minWidth: buttonHeight,
              minHeight: buttonHeight,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
            }}
            icon={< FontAwesomeIcon icon={icon({ name: 'plus', style: 'light' })}
              style={{ margin: 0, fontSize: 9 }} />}
          />}
        </td>
      </tr>}
    </>
  );
}

const TableWithDrag = ({
  columns = [],
  dataSource = [],
  setDataSource,
  backgroundColor = "#0B2239",
  color = "white",
  onDrop,
  scroll,
  rowSelectedId,
  withDragger = true,
  footer,
  className = '',
  pagination = false,
  rowKey = "id",
  rowClassName
}) => {

  const moveRow = useCallback(
    (hoverId, draggedId) => {
      setDataSource((old) => {
        const dragRowIdx = old.findIndex((item) => draggedId === item[rowKey]);
        const hoverIdx = old.findIndex((item) => hoverId === item[rowKey]);
        const dragRow = old[dragRowIdx]
        return update(old, {
          $splice: [
            [dragRowIdx, 1],
            [hoverIdx, 0, dragRow],
          ],
        })
      }
      );
    },
    [rowKey, setDataSource],
  );

  const columnsWithDragger = useMemo(() => {
    if (!withDragger) return columns
    return [
      {
        title: <></>,
        dataIndex: 'sort',
        key: 'sort',
        width: 30,
        onHeaderCell: () => ({
          style: {
            backgroundColor,
            color,
          },
        }),
      },
      ...columns
    ]
  }, [backgroundColor, color, columns, withDragger])

  const onDropRow = useCallback((recordId) => {
    if (onDrop) onDrop(recordId)
  }, [onDrop])

  return (
    <Table
      className={`table-with-drag ${className}`}
      pagination={pagination}
      columns={columnsWithDragger}
      dataSource={dataSource}
      rowKey={rowKey}
      bordered
      scroll={scroll}
      components={{
        body: {
          row: DraggableRow,
        },
      }}
      rowClassName={(record) => {
        let base = ""
        if (typeof rowClassName === "function") base = rowClassName(record)
        if (rowSelectedId && record.id && rowSelectedId === record.id) base += " table-with-drag-row-selected"
        return base
      }}
      onRow={(record) => {
        const attr = {
          moveRow,
          onDrop: onDropRow,
          canDrag: record.canDrag,
          canDrop: record.canDrop,
          onAdd: record.onAdd,
          onAddBottom: record.onAddBottom,
          dragIconTitle: record.dragIconTitle,
        };
        return attr;
      }}
      footer={footer}
    />
  )
}

export default TableWithDrag
