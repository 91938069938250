import React, { useCallback } from 'react'
import FloatyMenu from '../../common/FloatyMenu'
import { ItemTypes, MASKS_TYPE } from '../../../constants/constants';
import { useStore } from '../../../store/store';
import { newQuestionBox, } from '../../../utils/boxes';

const options = [
  { id: 1, label: 'Question' },
  { id: 2, label: 'Variable texte' },
  { id: 3, label: 'Groupe de variables' },
];

const FormCreateItemButton = ({ menuStatus, setMenuStatus, getPositions, setCreateItemType }) => {
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration)
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const configuration = useStore(({ configuration }) => configuration)
  const landscape = useStore(({ landscape }) => landscape)

  const addBox = useStore(({ addBox }) => addBox)


  const onItemClick = useCallback((e, { id }) => {
    handleBoxVariableSelectedId()
    if (id === 1) {
      const newBox = newQuestionBox({
        gridConfiguration,
        landscape,
        configuration,
        header,
        footer,
      })
      addBox(newBox, (box, draft) => {
        draft.boxVariableSelectedId = box.id;
        if (menuStatus.from.id) {
          const idx = draft.variableOrderArray.findIndex(({ id }) => id === menuStatus.from.id)
          if (idx !== -1) {
            const element = draft.variableOrderArray[0];
            draft.variableOrderArray.splice(0, 1);
            draft.variableOrderArray.splice(menuStatus.from.isAddedFromBottom ? idx : idx - 1,
              0, element);
          }
        }
      })
    } else if (id === 2) {
      setCreateItemType({ type: ItemTypes.CUSTOM_TEXT_VARIABLE, from: menuStatus.from })
    } else {
      setCreateItemType({ type: ItemTypes.VARIABLE_GROUP, from: menuStatus.from })
    }
    setMenuStatus({ isOpen: false, from: null })
  }, [handleBoxVariableSelectedId, setMenuStatus, gridConfiguration, landscape, configuration, header, footer, addBox, menuStatus.from, setCreateItemType])

  const { top, left } = getPositions()

  return (
    <FloatyMenu menuIsOpen={menuStatus.isOpen} onClose={() => setMenuStatus({ isOpen: false, from: null })} onItemClick={onItemClick} left={left + 8} top={top}
      options={menuStatus.from?.id ? options.filter(({ id }) => id !== 3) : options} />
  )
}

export default FormCreateItemButton
