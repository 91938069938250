import { ItemTypes, MASKS_TYPE, uuid } from '../constants/constants';
import { gridUnit } from '../constants/gridConfig';
import {
  isATitleBox,
  isAVariableBox,
  mutateAddBoxes,
  mutateDefaultBoxValue,
  reinitializeBoxWidthAndHeight,
} from './boxes';
import { computeColumnWidth, mutateAddColumns } from './columns';
import {
  mutateRemmoveSectionContainers,
  mutateRemoveContainer,
} from './containers';
import { indentTitles } from './titles';
import {
  mutateExecuteParentTriggers,
  mutateInjectTriggersSection,
  mutateSearchAndLaunchTrigger,
  mutateUpdateTriggerIds,
} from './triggers';
import {
  mutateSortVariableFromSection,
  mutateTakeOutItem,
} from './variableOrderArray';
import { mutateInjectVariablesInBox } from './variables';

export const mutateFormatSectionBox = ({ box, type, draft }) => {
  if (type === ItemTypes.CHECKBOXS_VARIABLE || type === ItemTypes.QUESTION) {
    box = {
      ...box,
      checkboxStyleId: 0,
    };
  }
  if (type === ItemTypes.SELECTOR_VARIABLE) {
    box = {
      ...box,
      selectorStyleId: 0,
    };
  }
  if (type === ItemTypes.TEXT_VARIABLE) {
    box = {
      ...box,
      textStyleId: 0,
    };
  }
  box = mutateInjectVariablesInBox({
    variables: draft.variables,
    box,
  });
};

export const mutateInjectSection = ({
  draft,
  sectionContent,
  boxId,
  sectionId,
  linked_section = false,
  areItemsChangedByTriggers = false,
  fromSectionId = sectionId,
  dateTimestamp = Date.now(),
  fromBoxId = boxId,
  from_linked_section = linked_section &&
    draft.currentDocument.type === 'template',
  sectionsIds = [sectionId],
  wasDocumentLoading,
}) => {
  if (fromBoxId === boxId) {
    draft.oldTriggers = { ...draft.triggers };
    draft.oldVariableOrderArray = [...draft.variableOrderArray];
  }
  const box = draft.boxes.find((b) => b.id === boxId);
  const containerIdx = draft.containers.findIndex((c) =>
    c.columnsIds.includes(box.columnId)
  );
  const parentContainerId = draft.containers[containerIdx].id;
  const columnsIdDict = {};
  const columnsToAdd = sectionContent.columns.map((c) => {
    const newId = uuid();
    columnsIdDict[c.id] = newId;
    return {
      ...c,
      id: newId,
      from_linked_section,
    };
  });
  if (from_linked_section) {
    linkSectionContainer({
      draft,
      sectionId,
      box,
    });
  }
  const containersToAdd = sectionContent.containers.map((c) => {
    let containerEditability = true;
    if (typeof c?.editable !== 'boolean' || c?.editable)
      containerEditability = true;
    else containerEditability = false;
    let baseContainer = {
      ...c,
      id: uuid(),
      sectionId: fromSectionId,
      from_linked_section,
      dateTimestamp: dateTimestamp,
      editable: draft.configuration.editableTemplate && containerEditability,
      columnsIds: c.columnsIds.map((cId) => columnsIdDict[cId]),
      fromBoxId,
    };
    if (box.type === ItemTypes.SECTION_VARIABLE) {
      return {
        ...baseContainer,
        fromBoxId,
      };
    }
    return baseContainer;
  });
  const boxesToAdd = mutateDefaultBoxValue(
    sectionContent.boxes.map((b) => {
      let baseBox = {
        ...b,
        id: `${b.id}-${boxId}`,
        from_linked_section,
        columnId: columnsIdDict[b.columnId],
        boxSectionId: b.id,
        fromBoxId,
        dateTimestamp,
        fromSectionId,
      };
      const column = columnsToAdd.find((c) => c.id === baseBox.columnId);
      if (column && !column.drawMode) {
        const container = containersToAdd.find((c) =>
          c.columnsIds.includes(column.id)
        );
        baseBox = reinitializeBoxWidthAndHeight({
          header: draft.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
          footer: draft.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
          box: baseBox,
          onResize: true,
          columnWidth: computeColumnWidth({
            landscape: draft.landscape,
            marginLeft: draft.configuration.margins.left * gridUnit,
            marginRight: draft.configuration.margins.right * gridUnit,
            containerColumnsLength: container.columnsIds.length,
            size: column.size,
          }),
        });
      }
      mutateFormatSectionBox({ box, type: box.type, draft });
      if (isATitleBox(baseBox.type)) {
        baseBox = indentTitles({
          box: baseBox,
          columns: columnsToAdd,
          margins: draft.configuration.margins,
          indentationTitles: draft.configuration.indentationTitles,
        });
      }
      return baseBox;
    }),
    draft.currentDocument.type === 'template'
  );

  draft.containers.splice(containerIdx + 1, 0, containersToAdd);
  draft.containers = draft.containers.flat();
  mutateAddColumns(draft, columnsToAdd);
  mutateAddBoxes({ draft, newBoxes: boxesToAdd });
  mutateInjectTriggersSection({
    sectionContent,
    draft,
    boxesToAdd,
    linked_section,
    from_linked_section,
    dateTimestamp: dateTimestamp,
    fromSectionId,
  });
  mutateSortVariableFromSection({
    draft,
    sectionContent,
    boxesToAdd,
    containerIdx: containerIdx,
    parentContainerId,
    parentId: fromBoxId,
    isDocumentLoading: draft.isDocumentLoading || wasDocumentLoading,
  });
  boxesToAdd.forEach((box) => {
    mutateUpdateTriggerIds(draft, box);
  });
  if (box.type !== ItemTypes.SECTION_VARIABLE && !from_linked_section)
    mutateRemoveContainer(draft, draft.containers[containerIdx].id);
  Object.entries(sectionContent.variables).forEach(([key, value]) => {
    if (!value.notUsed && draft.variables[key]?.notUsed) {
      delete draft.variables[key].notUsed;
    }
    if (
      !draft.variables[key]?.notUsed &&
      draft.currentDocument.type !== 'template'
    ) {
      if (
        areItemsChangedByTriggers &&
        !draft.itemsChangedByTriggers.some(({ key: iKey }) => key === iKey)
      ) {
        draft.itemsChangedByTriggers.push({ key });
      }
      mutateExecuteParentTriggers({
        draft,
        key: key,
      });
      mutateSearchAndLaunchTrigger({
        triggerId: key,
        draft,
      });
    }
  });
  boxesToAdd.forEach((b) => {
    if (isAVariableBox(b.type) && draft.currentDocument.type !== 'template') {
      if (
        areItemsChangedByTriggers &&
        !draft.itemsChangedByTriggers.some(({ key: iKey }) => b.id === iKey)
      ) {
        draft.itemsChangedByTriggers.push({ key: b.id });
      }
      mutateExecuteParentTriggers({
        draft,
        key: b.id,
      });
      mutateSearchAndLaunchTrigger({
        triggerId: b.id,
        draft,
      });
    }
    if (b.type === ItemTypes.SECTION_LINK && !draft.fromPdf) {
      draft.sectionsToActivate.unshift({
        boxId: b.id,
        fromSectionId: box.sectionId,
        sectionId: b.sectionId,
        action: 'inject',
        linked_section: true,
        dateTimestamp,
        isFirstInjection: false,
        fromBoxId,
        isSecondInjection: fromBoxId === boxId,
        from_linked_section,
        sectionsIds: [...sectionsIds, b.sectionId],
        wasDocumentLoading: draft.isDocumentLoading,
      });
    }
  });
};

const linkSectionContainer = ({ draft, sectionId, box }) => {
  if (box.type === ItemTypes.SECTION_LINK) return;
  const sectionTitle = draft.sections.find((s) => s.id === sectionId).name;
  if (box) {
    box.sectionId = sectionId;
    box.type = ItemTypes.SECTION_LINK;
    box.sectionTitle = sectionTitle;
  }
};

export const mutateDeleteSection = ({ draft, boxId, optionId }) => {
  const sectionsBoxesToDelete = draft.boxes.filter(
    (b) =>
      (b.fromBoxId === boxId || b.sectionVariableBoxId === boxId) &&
      b.type === ItemTypes.SECTION_VARIABLE
  );
  sectionsBoxesToDelete.forEach((sB) => {
    sB.answer = optionId;
    mutateDeleteSection({ draft, boxId: sB.id, optionId });
  });
  const containerToDelete = draft.containers.find(
    (c) => c.fromBoxId === boxId || c.sectionVariableBoxId === boxId
  );

  mutateRemmoveSectionContainers(draft, boxId, {
    sectionId: containerToDelete?.sectionId,
    dateTimestamp: containerToDelete?.dateTimestamp,
  });
  draft.variableOrderArray
    .filter(({ parentId }) => parentId === boxId)
    .forEach(({ id, parentId }) => {
      if (
        draft.variableOrderArray.some(
          ({ id: vId, parentId: vParentId }) =>
            id === vId && parentId !== vParentId
        )
      )
        mutateTakeOutItem(draft, id, parentId);
    });
};

export const getSelectedSection = (sectionId, sectionList) => {
  return (sectionList || []).find((s) => s.id === sectionId);
};

export const shouldHideSectionVariable = ({
  type,
  sectionId,
  sectionList,
  isActivatedByTrigger,
}) =>
  type === ItemTypes.SECTION_VARIABLE &&
  (!getSelectedSection(sectionId, sectionList) || isActivatedByTrigger);

export const mutateHandleLinkedSectionRecursive = ({
  sectionsIds,
  box,
  draft,
  fromBoxId,
  boxId,
  sectionContent,
  isSecondInjection,
}) => {
  if (
    sectionsIds.filter((id) => id === box.sectionId).length > 1 ||
    box.sectionId ===
      (draft.currentDocument.type === 'template'
        ? draft.currentDocument.id
        : draft.currentDocument.id)
  ) {
    mutateRemmoveSectionContainers(draft, fromBoxId);
    draft.triggers = { ...draft.oldTriggers };
    draft.variableOrderArray = [...draft.oldVariableOrderArray];
    draft.oldTriggers = undefined;
    draft.oldVariableOrderArray = undefined;
    return true;
  }
  const parentBoxIds = boxId.split('-');
  sectionContent.boxes.forEach((b) => {
    if (!isAVariableBox(b.type)) return;
    let boxParentId = '';
    for (let idx = 0; idx < parentBoxIds.length; idx += 1) {
      const parentId = parentBoxIds[idx];
      boxParentId = idx === 0 ? parentId : `${boxParentId}-${parentId}`;
      const idToUse = `${b.id}-${boxParentId}`;
      const linkedSectionTriggers = draft.triggers[idToUse]?.filter(
        ({ from_linked_section }) => from_linked_section || isSecondInjection
      );
      if (linkedSectionTriggers?.length > 0) {
        if (
          !sectionContent.triggers[b.id] ||
          sectionContent.triggers[b.id]?.length === 0
        )
          sectionContent.triggers[b.id] = [...linkedSectionTriggers];
        draft.triggers[idToUse] = draft.triggers[idToUse].filter(
          ({ from_linked_section }) =>
            !from_linked_section && !isSecondInjection
        );
        if (draft.triggers[idToUse].length === 0)
          delete draft.triggers[idToUse];
      }

      if (
        draft.variableOrderArray?.find(
          ({ id, parentId: vParentId }) =>
            id === idToUse && fromBoxId === vParentId
        ) &&
        sectionContent.variableOrderArray?.find(({ id }) => id === b.id)
      ) {
        draft.variableOrderArray = draft.variableOrderArray?.filter(
          ({ id, parentId: vParentId }) =>
            id !== idToUse || fromBoxId !== vParentId
        );
      }
    }
  });
  Object.keys(sectionContent.variables).forEach((key) => {
    const linkedSectionTriggers = draft.triggers[key]?.filter(
      ({ from_linked_section }) => from_linked_section || isSecondInjection
    );
    if (linkedSectionTriggers?.length > 0) {
      if (
        !sectionContent.triggers[key] ||
        sectionContent.triggers[key]?.length === 0
      )
        sectionContent.triggers[key] = [...linkedSectionTriggers];
      draft.triggers[key] = draft.triggers[key].filter(
        ({ from_linked_section }) => !from_linked_section && !isSecondInjection
      );
      if (draft.triggers[key].length === 0) delete draft.triggers[key];
    }
    if (
      draft.variableOrderArray?.find(
        ({ id, parentId }) => id === key && parentId === fromBoxId
      ) &&
      sectionContent.variableOrderArray?.find(({ id }) => id === key)
    ) {
      draft.variableOrderArray = draft.variableOrderArray?.filter(
        ({ id, parentId }) => id !== key || parentId !== fromBoxId
      );
    }
  });
  return false;
};
