import React, { useState } from 'react'
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { pageBackground, roundButtonWidth } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import AdderMenuDisposition from './AdderMenuDisposition';
import { useZIndexContainer } from '../../hooks';

const selector = (id) => ({
  containers,
  currentProposal
}) => {
  const container = containers.find((c) => c.id === id);
  return {
    currentProposal,
    container: container,
    containerColumnsLength: container.columnsIds.length
  }
};

const RearangeColumnsButton = ({ id, menuIsOpen, setMenuIsOpen }) => {
  const {
    containerColumnsLength,
    currentProposal,
    container
  } = useStore(selector(id));
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const deleteContainer = () => {
    setMenuIsOpen(true)
  }

  const hasPriority = !(containerColumnsLength === 1 || containerColumnsLength === 4) && menuIsOpen

  useZIndexContainer(id, hasPriority)


  if (containerColumnsLength === 1 || containerColumnsLength === 4) return null;
  const disableRearange = currentProposal.id !== null && container?.editable === false

  return (
    <div style={{
      position: "relative",
      display: "flex",
      justifyContent: "center",
    }}>
      <Tooltip
        open={isHovered && !menuIsOpen}
        placement='bottom'
        mouseLeaveDelay='0'
        title={t('container-layoutTooltip')}
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Button shape="circle"
            disabled={disableRearange}
            size={14}
            onClick={deleteContainer}
            data-role={pageBackground}
            style={{
              width: roundButtonWidth,
              height: roundButtonWidth,
              minWidth: roundButtonWidth,
              minHeight: roundButtonWidth,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: 'black',
            }}
            icon={<FontAwesomeIcon
              icon={icon({ name: 'pen-to-square', style: 'light' })}
              style={{ margin: 0, fontSize: 15 }} />}
          />
        </div>
      </Tooltip>
      {menuIsOpen && <AdderMenuDisposition
        containerId={id}
        closeMenu={() => setMenuIsOpen(false)}
        columnIndex={null}
      />}
    </div>)
}

export default RearangeColumnsButton
