import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, message, Popconfirm, Switch, } from 'antd';
// ANT Design
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MASKS_TYPE, PRIMARY_COLOR, zoomList, dynamicZoom, getPrimaryDark } from '../../constants/constants';
import { useModalOpener } from '../../hooks';
import { useStore } from '../../store/store';
import { emptyUrl, updateUrl } from '../../utils/browser';
import { savePagePreview } from '../../utils/savePagePreview';
import { actionButtonBottomMargin } from '../../utils/styles';
import ConfigurationModal from './configurationModal/ConfigurationModal';
import NewTemplateModal from './NewTemplateModal';
import './RightSide.less';
import TemplateModal from './TemplateModal';
import OverlaySider from '../Layout/OverlaySider';
import NavbarSelector from '../Layout/NavbarSelector';
import BoxButtonsWrapper from '../common/BoxButtonsWrapper';

const menuItemStyle = {
  textAlign: 'center',
  margin: `${actionButtonBottomMargin}px 0`,
}

const selector = (state) => ({
  getTemplates: state.getTemplates,
  currentDocument: state.currentDocument,
  loadingData: state.loadingData,
  currentProposal: state.currentProposal,
  templates: state.templates,
  postTemplate: state.postTemplate,
  updateTemplate: state.updateTemplate,
  saveProposal: state.saveProposal,
  isAdmin: state?.user?.isAdmin,
  user: state?.user,
  createTemplateInStore: state.createTemplateInStore,
  gridConfiguration: state.gridConfiguration,
  copy: state.copy,
  handleUnSelectBox: state.handleUnSelectBox,
  handleUnSelectContainer: state.handleUnSelectContainer,
  setDisplayMargins: state.setDisplayMargins,
  displayMargins: state.displayMargins,
  PDFRenderState: state.PDFRenderState,
  handleProposalList: state.handleProposalList,
  handleMaskList: state.handleMaskList,
  maskEditing: state.maskEditing,
  fetchDatas: state.fetchDatas,
  setMaskEditing: state.setMaskEditing,
  saveAndGeneratePdf: state.saveAndGeneratePdf,
  landscape: state.landscape,
  setConfiguration: state.setConfiguration,
  configuration: state.configuration
});

const RightSide = () => {
  const { t } = useTranslation();
  const {
    currentProposal,
    currentDocument,
    loadingData,
    templates,
    updateTemplate,
    saveProposal,
    isAdmin,
    createTemplateInStore,
    postTemplate,
    displayMargins,
    handleUnSelectBox,
    handleUnSelectContainer,
    setDisplayMargins,
    PDFRenderState,
    handleProposalList,
    fetchDatas,
    saveAndGeneratePdf,
    landscape,
    setConfiguration,
  } = useStore(selector);
  const [handleTemplateOpen, setHandleTemplateOpen] = useModalOpener(false);
  const [configurationPanelVisible, setConfigurationPanelVisible] =
    useModalOpener(false);
  const [NewTemplateModalVisible, setNewTemplateModalVisible] = useModalOpener(false);
  const [saveDocumentLoading, setSaveDocumentLoading] = useState(false);

  useEffect(() => {
    if (!currentProposal?.id) {
      if (currentDocument.type === 'template' && !currentDocument.id) {
        setHandleTemplateOpen(true);
      }
    }
  }, [currentProposal, currentDocument, setHandleTemplateOpen])
  const handleUpdateZoom = (value) => {
    if (value < 24 || value > 500) return;
    setConfiguration({
      zoom: value
    })
  }

  const saveDocument = useCallback(async () => {
    const key = "saveDocument";
    message.open({
      type: 'loading',
      content: 'Enregistrement en cours',
      duration: 0,
      key
    })
    setSaveDocumentLoading(true);
    if (isAdmin && currentDocument.type === 'template') {
      try {
        await updateTemplate();
        savePagePreview(currentDocument.id, currentDocument.type, landscape);
        message.open({
          type: 'success',
          content: 'Enregistré.',
          duration: 1,
          key
        })
      } catch (error) {
        message.open({
          type: 'error',
          content: "Une erreur est survenue lors de l'enregistrement.",
          duration: 2,
          key
        })
      }
    } else {
      try {
        await saveProposal();
        savePagePreview(currentProposal.id, currentDocument.type, landscape);
        message.open({
          type: 'success',
          content: 'Enregistré.',
          duration: 1,
          key
        })
      } catch (error) {
        message.open({
          type: 'error',
          content: "Une erreur est survenue lors de l'enregistrement.",
          duration: 2,
          key
        })
      }
    }
    setSaveDocumentLoading(false);
    setNewTemplateModalVisible(false);
    setHandleTemplateOpen(false);
  }, [isAdmin,
    currentDocument?.type,
    currentDocument?.id,
    setNewTemplateModalVisible,
    setHandleTemplateOpen,
    updateTemplate,
    landscape,
    saveProposal,
    currentProposal?.id]);

  const saveNewTemplate = useCallback(
    async ({ name, templatable, templatePattern, landscape, work, documentTypeIds }) => {
      const key = "saveNewTemplate";
      message.open({
        type: 'loading',
        content: 'Création du document en cours',
        duration: 0,
        key
      })
      createTemplateInStore();
      setSaveDocumentLoading(true);
      if (isAdmin && currentDocument.type === 'template') {
        try {
          await postTemplate({
            name,
            templatable,
            templatePattern,
            landscape,
            work,
            documentTypeIds
          });
          message.open({
            type: 'success',
            content: 'Document créé.',
            duration: 2,
            key
          })
        } catch (error) {
          message.open({
            type: 'error',
            content: "Une erreur est survenue lors de la création.",
            duration: 4,
            key
          })
        }
      }
      setSaveDocumentLoading(false);
      setNewTemplateModalVisible(false);
      setHandleTemplateOpen(false);
    },
    [createTemplateInStore, isAdmin, currentDocument.type, setNewTemplateModalVisible, setHandleTemplateOpen, postTemplate]
  );

  const downLoadPdf = async (isArchive) => {
    const key = "pdfKey";
    message.open({
      type: 'loading',
      content: 'Création du PDF en cours.',
      duration: 0,
      key
    })
    handleUnSelectBox();
    handleUnSelectContainer();
    try {
      await saveAndGeneratePdf(isArchive);
    } catch (error) {
      message.open({
        type: 'error',
        content: "Une erreur est survenu lors de la génération du PDF.",
        duration: 2,
        key
      })
    }
  }

  const handleCreateTemplate = (e) => {
    setNewTemplateModalVisible(true);
  };

  const GeneratePDFButton = ({ isArchive }) => {
    const disabled = isArchive ? PDFRenderState === "visualize" : PDFRenderState === "archive"
    return (
      <Button
        className='style_btn_right_side'
        onClick={() => downLoadPdf(isArchive)}
        icon={isArchive ? < FontAwesomeIcon icon={icon({
          name: 'cloud-arrow-down', style: 'light'
        })} style={{ marginRight: "8px" }} /> : < FontAwesomeIcon icon={icon({
          name: 'eye', style: 'light'
        })} style={{ marginRight: "8px" }} />}
        type='primary'
        loading={isArchive ? PDFRenderState === "archive" : PDFRenderState === "visualize"}
        disabled={disabled}
        style={{
          ...(disabled ? { backgroundColor: PRIMARY_COLOR, opacity: 0.65, borderColor: PRIMARY_COLOR } : {})
        }}
      >
        <span style={{ width: 100 }}>{t(isArchive ? 'archive_pdf' : 'visualize_pdf')}</span>
      </Button>
    );
  };

  const salesmanChoseTemplate =
    !isAdmin &&
    (currentDocument.type === 'offer' ||
      currentDocument.type === 'opportunity');

  const canSave = isAdmin || salesmanChoseTemplate;

  const { getTemplates, setMaskEditing } = useStore(selector);

  const [zoomSelected, setZoomSelected] = useState(zoomList.find(z => z.value === dynamicZoom()) || zoomList[6]);

  return (
    <>
      {!loadingData && canSave && (
        <TemplateModal
          open={handleTemplateOpen}
          handleCancel={() => setHandleTemplateOpen(false)}
          handleOk={() => {
            setNewTemplateModalVisible(false);
            setHandleTemplateOpen(false);
          }}
          data={templates}
          isAdmin={isAdmin}
          newTemplateOnClick={handleCreateTemplate}
        />
      )}
      {isAdmin && !loadingData && NewTemplateModalVisible && (
        <NewTemplateModal
          type={isAdmin ? 'template' : 'proposition'}
          open={true}
          handleCancel={() => setNewTemplateModalVisible(false)}
          handleOk={saveNewTemplate}
        />
      )}
      {isAdmin && !loadingData && configurationPanelVisible && (
        <ConfigurationModal
          open={true}
          handleOk={() => setConfigurationPanelVisible(false)}
        />
      )}
      <OverlaySider
        side={'right'}
      >
        <div className='logo' />

        <div style={{ backgroundColor: getPrimaryDark(currentDocument.type === 'template') }} id='global-menu'>
          <div className='menu_wrapper' style={{
            height: 'calc(100vh - 54px)',
            overflow: 'hidden scroll'
          }}>
            <div className='menu_item-main_block'>
              <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: getPrimaryDark(currentDocument.type === 'template'),
                zIndex: 999,
                display: 'flex',
                flexDirection: 'column'
              }}>
                <div>
                  {canSave && (
                    <div key='sub2' style={menuItemStyle}>
                      {currentDocument.type === 'template' ? (
                        <Button
                          className='style_btn_right_side'
                          icon={< FontAwesomeIcon icon={icon({ name: 'folder-open', style: 'light' })} style={{ marginRight: "8px" }} />}
                          onClick={() => {
                            setHandleTemplateOpen(true);
                            getTemplates();
                          }}
                          ghost
                        >
                          {t('documents')}
                        </Button>
                      ) : (
                        <Popconfirm
                          title={
                            'Votre progression non sauvegardée sera perdue. Continuer ?'
                          }
                          cancelText='Annuler'
                          okText='Continuer'
                          placement='bottom'
                          getPopupContainer={(trigger) => trigger.parentElement}
                          onConfirm={() => {
                            handleProposalList(true);
                            emptyUrl();
                            if (currentDocument.type === 'opportunity') {
                              updateUrl({ oid: currentDocument.id });
                            } else if (currentDocument.type === 'offer') {
                              updateUrl({ ofid: currentDocument.id });
                              fetchDatas();
                            }
                          }}
                        >
                          <Button
                            className='style_btn_right_side'
                            icon={< FontAwesomeIcon icon={icon({ name: 'folder-open', style: 'light' })} style={{ marginRight: "8px" }} />}
                            ghost
                          >
                            {t('documents')}
                          </Button>
                        </Popconfirm>
                      )}
                    </div>
                  )}
                  {canSave && (
                    <div key='9' style={menuItemStyle}>
                      <Button
                        className='style_btn_right_side'
                        onClick={saveDocument}
                        icon={< FontAwesomeIcon icon={icon({ name: 'floppy-disk', style: 'light' })} style={{ marginRight: "8px" }} />}
                        loading={saveDocumentLoading}
                        disabled={saveDocumentLoading}
                        ghost={!saveDocumentLoading}
                      >
                        {t('save')}
                      </Button>
                    </div>
                  )}
                  {!(currentDocument.type === 'template' && isAdmin) && !landscape && (
                    <div key='header-footer-editor' style={menuItemStyle}>
                      <Button
                        className='style_btn_right_side'
                        icon={< FontAwesomeIcon icon={icon({ name: 'memo-pad', style: 'regular' })} style={{ marginRight: "8px" }} />}
                        ghost
                        onClick={() => setMaskEditing([MASKS_TYPE.HEADER.id, MASKS_TYPE.FOOTER.id])}
                      >
                        {t('header-footer')}
                      </Button>
                    </div>
                  )}
                  <div key='grille' style={menuItemStyle}>
                    <Switch
                      checkedChildren={t('hide-margins')}
                      unCheckedChildren={t('show-margins')}
                      checked={displayMargins}
                      onClick={(e) => {
                        setDisplayMargins();
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', gap: 2 }}>
                    <Button
                      icon={<FontAwesomeIcon icon={icon({ name: 'magnifying-glass-minus', style: 'solid', family: "duotone" })} style={{ fontSize: 20 }} />}
                      onClick={() => {
                        const zoomValue = zoomList.find(z => z.index === zoomSelected?.index - 1)
                        if (zoomValue === undefined) return;
                        setZoomSelected(zoomValue);
                        handleUpdateZoom(zoomValue.value)
                      }}
                      size='medium'
                    />

                    <NavbarSelector
                      key="fontSizeSelector"
                      tooltipContent={t('zoom-size')}
                      onSelect={(e) => {
                        const zoomValue = zoomList.find(z => z.value === e)

                        setZoomSelected(zoomValue);
                        handleUpdateZoom(zoomValue.value)
                      }}
                      selectedValue={zoomSelected?.value}
                      options={zoomList}
                      searchable={false}
                      arrow={false}
                      customStyle={{
                        borderRadius: 4,
                        width: 75,
                        margin: 0,
                        height: 32,
                        textAlign: "center",
                      }}
                    />

                    <Button
                      icon={<FontAwesomeIcon icon={icon({ name: 'magnifying-glass-plus', style: 'solid', family: "duotone" })} flip={"horizontal"} style={{ fontSize: 20 }} />}
                      onClick={() => {
                        const zoomValue = zoomList.find(z => z.index === zoomSelected?.index + 1)
                        if (zoomValue === undefined) return;

                        setZoomSelected(zoomValue);
                        handleUpdateZoom(zoomValue.value)
                      }
                      }
                      size='medium'
                    />
                  </div>
                </div>
              </div>
              <div style={{
                marginTop: 16,
                marginBottom: 16

              }}>
                <BoxButtonsWrapper />
              </div>
            </div>
            <div className='menu_item-generate_pdf'>
              <div key='8' id='generate-pdf-button' style={menuItemStyle}>
                <GeneratePDFButton />
              </div>
              {(currentDocument.type === 'opportunity' ||
                currentDocument.type === 'offer') && <div style={menuItemStyle} id='generate-pdf-button'>
                  <GeneratePDFButton isArchive />
                </div>}
            </div>
          </div>
        </div>
      </OverlaySider >
    </>
  );
};

export default React.memo(RightSide);
