import { produce } from 'immer';
import {
  mutateRemoveBox,
  mutateSelectBox,
  boxBounding,
  alignedBoxValues,
  mutateMoveBoxInfinite,
  mutateMoveBoxesBatchInfinite,
  boxesToMoveInfinite,
  getAlignedBoxTargetValue,
  isASelectionBox,
  isAVariableBox,
  mutateVariablesBoxes,
  mutateAddBoxes,
} from '../utils/boxes';
import { ItemTypes, uuid } from '../constants/constants';

import { gridUnit } from '../constants/gridConfig';
import { computeColumnWidth } from '../utils/columns';
import { mutateTakeOutItem } from '../utils/variableOrderArray';
import {
  mutateSearchAndLaunchTrigger,
  mutateRemoveTriggersWithTriggerId,
} from '../utils/triggers';
import { mutateMoveBoxesIndex, mutateTitlesIndexes } from '../utils/titles';

export const boxesActions = (set, get) => ({
  boxHideInFormSwitcher: (id) => {
    set(
      produce((draft) => {
        const box = draft.boxes.find((b) => b.id === id);
        if (!box.hideInForm) {
          box.hideInForm = true;
        } else {
          delete box.hideInForm;
        }
      }),
      false,
      `boxHideInFormSwitcher ${id}`
    );
  },
  boxVariableSwitcher: (id) => {
    set(
      produce((draft) => {
        const box = draft.boxes.find((b) => b.id === id);
        const getType = (type) => {
          switch (type) {
            case ItemTypes.IMG:
              return ItemTypes.IMG_VARIABLE;
            case ItemTypes.TABLE:
              return ItemTypes.TABLE_VARIABLE;
            case ItemTypes.FILE_PDF:
              return ItemTypes.FILE_PDF_VARIABLE;
            case ItemTypes.TEXT:
              return ItemTypes.TEXT_VARIABLE;
            case ItemTypes.IMG_VARIABLE:
              return ItemTypes.IMG;
            case ItemTypes.TABLE_VARIABLE:
              return ItemTypes.TABLE;
            case ItemTypes.FILE_PDF_VARIABLE:
              return ItemTypes.FILE_PDF;
            case ItemTypes.TEXT_VARIABLE:
              return ItemTypes.TEXT;
            default:
              return null;
          }
        };
        const variableType = getType(box.type);
        box.type = variableType;
        if (isAVariableBox(variableType)) {
          const newBox = mutateVariablesBoxes(draft, null, [box])[0];
          box.name = newBox.name;
        } else {
          if (box.name) delete box.name;
          if (box.remark) delete box.remark;
          if (box.hideInForm) delete box.hideInForm;
          mutateRemoveTriggersWithTriggerId({ triggerId: box.id, draft });
          mutateTakeOutItem(draft, box.id);
        }
      }),
      false,
      `boxVariableSwitcher ${id}`
    );
  },
  updateBox: (id, fn, options = {}) => {
    set(
      produce((draft) => {
        const boxInMask = draft.masks
          .map((m) => m.boxes)
          .flat()
          .find((b) => b.id === id);
        if (boxInMask) {
          fn(boxInMask, true);
          options.actionNameSuffix = options.actionNameSuffix || 'from_mask';
        } else {
          const box = draft.boxes.find((b) => b.id === id);
          if (!box.onlyForm) {
            const column = draft.columns.find((c) => c.id === box.columnId);
            const columnWidth = computeColumnWidth({
              landscape: draft.landscape,
              drawMode: column.drawMode,
              marginLeft: draft.configuration.margins.left * gridUnit,
              marginRight: draft.configuration.margins.right * gridUnit,
              size: column.size,
              containerColumnsLength: draft.containers.find((c) =>
                c.columnsIds.includes(column.id)
              ).columnsIds.length,
            });
            fn(box, column, columnWidth);
          } else {
            fn(box);
          }
          if (draft.currentDocument.type !== 'template') {
            mutateSearchAndLaunchTrigger({ triggerId: box.id, draft });
          }
        }
      }),
      false,
      `updateBox${id}_${
        options.actionNameSuffix ? '_' + options.actionNameSuffix : ''
      }`
    );
  },
  setCopy: (copy) => set({ copy }, false, 'setCopy'),
  getBox: (id) =>
    [
      ...get().boxes,
      ...get()
        .masks.map((m) => m.boxes)
        .flat(),
    ].find((box) => box.id === id),
  moveBoxInfinite: (left, top, boxId) => {
    set(
      produce((draft) => {
        mutateMoveBoxInfinite(draft, left, top, boxId);
      }),
      false,
      'move Box'
    );
  },
  removeBox: (id) => {
    set(
      produce((state) => {
        const selectedBox =
          state.selectedBoxId &&
          [...state.boxes, ...state.masks.map((m) => m.boxes).flat()].find(
            (b) => b.id === state.selectedBoxId
          );
        const maskId =
          selectedBox &&
          state.maskEditing?.length > 0 &&
          state.maskEditing.includes(selectedBox.maskId)
            ? selectedBox.maskId
            : null;
        mutateRemoveBox(state, id, maskId);
      }),
      false,
      'remove Box'
    );
  },
  updateIndexTitle: () => {
    set(
      produce((draft) => {
        mutateTitlesIndexes(draft);
      }),
      false,
      `updateIndexTitle`
    );
  },
  moveBoxesIndex: ({
    oldDragTitle,
    aboveDropTitle,
    dropPosition,
    boxesInColumn,
    forDelete,
  }) => {
    set(
      produce((draft) => {
        const succes = mutateMoveBoxesIndex(draft, {
          oldDragTitle,
          aboveDropTitle,
          dropPosition,
          boxesInColumn,
          forDelete,
        });
        if (succes === false) {
          return false;
        }
      }),
      false,
      `moveBoxesIndex`
    );
  },
  handleSelectBox: ({ box, group }) => {
    set(
      produce((draft) => {
        if (draft.boxVariableSelectedId && isAVariableBox(box.type)) {
          draft.boxVariableSelectedId = box.id;
        }
        draft.eventManagerEnabled = true;
        mutateSelectBox(draft, box, group);
      }),
      false,
      `Select Box${group ? ' in group' : ''} `
    );
  },
  handleUnSelectBox: (from = 'anonymous') => {
    if (get().selectedBoxId) {
      set(
        produce((draft) => {
          draft.eventManagerEnabled = false;
          draft.selectedBoxId = null;
          draft.boxIsMovable = false;
          draft.groupSelection = [];
        }),
        false,
        `unselectBox from ${from}`
      );
    }
  },
  handleBoxVariableSelectedId: (boxId) => {
    set(
      produce((draft) => {
        const isACustomTextVariable = !draft.boxes.some((b) => b.id === boxId);
        draft.boxVariableSelectedId = boxId;
        if (draft.currentDocument.type === 'template')
          draft.selectedBoxId = null;
        if (
          !isACustomTextVariable &&
          (!boxId ||
            !isASelectionBox(draft.boxes.find((b) => b.id === boxId).type)) &&
          draft.currentDocument.type !== 'template'
        )
          draft.selectedBoxId = boxId;
      }),
      false,
      `${!!boxId ? 'selectBoxVariable' : 'unselectBoxVariable'} id ${boxId}`
    );
  },
  setSlider: (obj) => {
    set(
      produce((draft) => {
        let directionChange = false;
        if (obj.direction && obj.direction !== draft.slider.direction) {
          directionChange = true;
        }
        Object.keys(obj).forEach((k) => {
          draft.slider[k] = obj[k];
        });
        if (directionChange) {
          draft.slider.boxes = boxesToMoveInfinite(
            draft.boxes,
            draft.slider.from.value
          ).map((b) => b.id);
        }
      }),
      false,
      `Set Slider`
    );
  },
  moveBoxesInfinite: (moveObject) => {
    set(
      produce((draft) => {
        mutateMoveBoxesBatchInfinite(draft, moveObject);
      }),
      false,
      'moveBoxes'
    );
  },
  alignBoxesDrawMode: (side) => {
    set(
      produce((draft) => {
        const boxes = [
          ...draft.boxes,
          ...draft.masks.map((m) => m.boxes).flat(),
        ].filter((b) => draft.groupSelection.includes(b.id));
        const selectedBox = [
          ...draft.boxes,
          ...draft.masks.map((m) => m.boxes).flat(),
        ].find((b) => b.id === draft.selectedBoxId);
        const boundingSelectedBox = boxBounding(selectedBox);
        let newSideValue;
        newSideValue = boundingSelectedBox[side];
        boxes.forEach((box) => {
          const { propertyToAlign, value } = alignedBoxValues({
            side,
            box,
            targetValue: newSideValue,
          });
          box[propertyToAlign] += value;
          box.alignToken = uuid();
        });
      }),
      false,
      `Align boxes on ${side}`
    );
  },
  alignBoxAbsolutDrawMode: (side) => {
    set(
      produce((draft) => {
        const selectedBox = [
          ...draft.boxes,
          ...draft.masks.map((m) => m.boxes).flat(),
        ].find((b) => b.id === draft.selectedBoxId);
        const targetValue = getAlignedBoxTargetValue({
          draft,
          selectedBox,
          side,
        });
        const { propertyToAlign, value } = alignedBoxValues({
          side,
          box: selectedBox,
          targetValue,
        });
        selectedBox[propertyToAlign] += value;
        selectedBox.alignToken = uuid();
      }),
      false,
      `Absolut Align box at ${side} margin`
    );
  },
  alignBoxDefault: (positions) => {
    set(
      produce(
        (draft) => {
          const selectedBox = draft.boxes.find(
            (b) => b.id === draft.selectedBoxId
          );
          selectedBox.positions = { ...selectedBox.positions, ...positions };
        },
        false,
        `Default Align box at vertical : ${positions.vertical} and horizontal : ${positions.horizontal}`
      )
    );
  },
  setBoxMovable: (value) => {
    set(
      produce((state) => {
        state.boxIsMovable = value;
      }),
      false,
      `setBoxMovable to ${value}`
    );
  },
  addBox: (box, callback) => {
    set(
      produce((draft) => {
        mutateAddBoxes({ draft, newBoxes: [box] });
        if (callback) {
          callback(box, draft);
        }
      }),
      false,
      `add box`
    );
  },
});
