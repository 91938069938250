import React, { useEffect, useRef, useState } from 'react'
import { pageBackground, VARIABLE_COLOR } from '../../../constants/constants';
import ItemName from './ItemName';
import FormBox from './FormBox';
import { useStore } from '../../../store/store';
import { scrollToItemInVariableEditor } from './FormViewer';


const FormItem = ({
  hasVariableSelected,
  formItem,
  zoom,
  formItems
}) => {
  const ref = useRef(null)
  const areSectionsActivating = useStore(({ areSectionsActivating }) => areSectionsActivating)
  const selectedBoxId = useStore(({ selectedBoxId }) => selectedBoxId)
  const [sectionsInjectionStatus, setSectionsInjectionStatus] = useState(areSectionsActivating ? "loading" : "none")

  useEffect(() => {
    if (areSectionsActivating) {
      setSectionsInjectionStatus("loading")
    }
    if (!areSectionsActivating) {
      setSectionsInjectionStatus((prev) => prev === "loading" && formItem.variablesBoxes.some((v) => v.id === selectedBoxId) ? "done" : "none")
    }
  }, [areSectionsActivating, formItem.variablesBoxes, selectedBoxId])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (sectionsInjectionStatus === "done") {
        setSectionsInjectionStatus("none")
        if (!entry.isIntersecting)
          scrollToItemInVariableEditor(selectedBoxId, formItems)
      }
    }, {
      root: ref.current.parentNode.parentNode.parentNode.parentNode.parentNode,
      threshold: 0.8,
    })

    observer.observe(ref.current);
    return () => { observer.disconnect(); }
  }, [formItem, selectedBoxId, areSectionsActivating, formItems, sectionsInjectionStatus])

  const getStyle = (variableGroupBox) => {
    const style = {}

    let gridTemplateColumns = "";
    let dispo;
    if (variableGroupBox.disposition) dispo = variableGroupBox.disposition
    else dispo = style.disposition
    for (let i = 0; i < dispo; i++) {
      gridTemplateColumns += "1fr "
    }
    return {
      display: "grid",
      gridTemplateColumns,
    }
  }

  return (
    <div style={{ backgroundColor: 'white' }} ref={ref}>
      <fieldset data-role={pageBackground} style={{
        border: `1px dashed ${hasVariableSelected ? VARIABLE_COLOR : "#b1b1b1"}`,
        marginBottom: '15px',
        fontStyle: 'italic',
      }}>
        <legend style={{
          zIndex: 0,
          position: 'relative',
          width: "fit-content",
          marginLeft: 16,
          fontSize: 14,
          padding: '0 8px',
          fontWeight: "bold",
          display: 'flex',
          marginBottom: 24
        }}>
          {formItem.variableGroupBox ?
            <ItemName
              formItem={formItem}
              variableBox={formItem.variableGroupBox}
              hasVariableSelected={hasVariableSelected}
              isLast
              zoom={zoom}
            /> : formItem.variablesBoxes.map((v, idx) =>
              <ItemName
                key={v.id}
                variableBox={v}
                formItem={formItem}
                hasVariableSelected={hasVariableSelected}
                isLast={idx + 1 === formItem.variablesBoxes.length}
                zoom={zoom}
              />
            )
          }
        </legend>
        {formItem.variableGroupBox ?
          <div data-role={pageBackground} style={{
            ...getStyle(formItem.variableGroupBox)
          }}>
            {formItem.variablesBoxes.filter((vB) => !vB.notUsed)
              .map((v) =>
                <div key={v.id} style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div style={{
                    padding: "0 16px"
                  }}>
                    <ItemName
                      variableBox={v}
                      formItem={formItem}
                      hasVariableSelected={hasVariableSelected}
                      isLast
                    />
                  </div>
                  <FormBox boxId={v.id} />
                </div>)
            }
          </div>
          :
          <FormBox boxId={formItem.variablesBoxes[0].id} />
        }
      </fieldset>
    </div>
  )
}

export default FormItem
