import React, { useCallback } from 'react'
import FormBoxName from './FormBoxName'
import { useStore } from '../../../store/store';
import { isVariableCustom, mutateInjectVariablesInDocument } from '../../../utils/variables';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useUpdateCustomTextVariable, useUpdateVariableGroup } from '../../../hooks';
import FormBoxRemark from './FormBoxRemark';
import { convertToRaw } from 'draft-js';
import VariableSelectionGroup from '../VariableSelectionGroup';
import { constructVariableIds, mutateHandleLinkedVariable } from '../../../utils/variableGroups';

const selector = (id) =>
  ({ selectedBoxId, configuration, updateVariable, variables }) => {
    return {
      isSelected: selectedBoxId === id,
      updateVariable,
      configuration,
      variables,
    };
  };

const FormCustomTextInfos = ({ box, form, validateFormAndRetrieveValues }) => {
  const { updateVariable, variables } = useStore(selector(box.id));

  const {
    mutate: updateCustomTextVariable,
  } = useUpdateCustomTextVariable();

  const handleOnBlur = useCallback(async () => {
    if (box.id === 'create-variable') return
    try {
      const values = await validateFormAndRetrieveValues();
      updateCustomTextVariable({ id: box.id.substring(box.id.lastIndexOf('.') + 1), name: values.name }, {
        onSuccess: ({ id }) => {
          updateVariable(box.id, (variable, draft) => {
            draft.defaultVariablesApi.custom_text_variables[`${values.name}.${id}`] = draft.defaultVariablesApi.custom_text_variables[`${variable.name}.${id}`]
            delete draft.defaultVariablesApi.custom_text_variables[`${variable.name}.${id}`]
            variable.name = values.name;
            variable.remark = JSON.stringify(convertToRaw(values.remark.getCurrentContent()));
            mutateInjectVariablesInDocument({
              variables,
              variable,
              variableKey: box.id,
              draft
            })
          })
        },
        onError: (err) => {
          form.setFieldValue("name", box.name)
        }
      })
    } catch (errors) {
    }
  }, [box.id, box.name, form, updateCustomTextVariable, updateVariable, validateFormAndRetrieveValues, variables])


  const handleOnBlurRemark = async () => {
    if (box.id === 'create-variable') return

    try {
      const values = await validateFormAndRetrieveValues();
      updateVariable(box.id, (variable) => {
        variable.remark = JSON.stringify(convertToRaw(values.remark.getCurrentContent()));
      })
    } catch (errors) {
    }
  }
  const updateVariableGroup = useStore(({ updateVariableGroup }) => updateVariableGroup)
  const variableGroups = useStore(({ variableGroups }) => variableGroups)

  const {
    mutate: updateVariableGroupMutation,
  } = useUpdateVariableGroup({
    onSuccess: (updatedVariableGroup, { variableKey, id }) => {
      updateVariableGroup(id,
        (variableGroup, draft) => {
          mutateHandleLinkedVariable({
            variableGroup,
            draft,
            variableIds: updatedVariableGroup.variable_ids,
            variableId: variableKey
          })
        })
    }
  });

  const handleOnBlurGroup = useCallback(async (oldGroupId) => {
    if (box.id === 'create-variable') return
    try {
      await validateFormAndRetrieveValues();
      const newGroupId = form.getFieldValue("linked-group")
      const variableKey = box.id;

      if (oldGroupId && oldGroupId !== newGroupId) {
        const variableGroup = variableGroups.find(
          (v) => v.id === oldGroupId
        )
        const variableIds = constructVariableIds({ variableGroup, variableId: variableKey })
        updateVariableGroupMutation({ id: variableGroup.id, variableIds, variableKey })
      }
      if (newGroupId && oldGroupId !== newGroupId) {
        const variableGroup = variableGroups.find(
          (v) => v.id === newGroupId
        )
        const variableIds = constructVariableIds({ variableGroup, variableId: variableKey })
        updateVariableGroupMutation({ id: variableGroup.id, variableIds, variableKey })
      }
    } catch (errors) {
    }

  }, [box.id, form, updateVariableGroupMutation, validateFormAndRetrieveValues, variableGroups])

  return (
    <div className='variable-editor__left'>
      <div style={{
        display: 'flex',
        height: 'fit-content',
        alignItems: 'center'
      }}>
        <div style={{ flex: 1 }}>
          <FormBoxName
            box={box}
            onBlur={handleOnBlur}
            disabled={!isVariableCustom(box.id)}
          />
        </div>
        {!isVariableCustom(box.id) && <Tooltip
          trigger="hover"
          placement='bottom'
          mouseLeaveDelay='0'
          title="Variable en provenance de l'API">
          <FontAwesomeIcon
            icon={icon({ name: 'circle-info', style: 'solid' })}
            style={{ marginLeft: 8, marginBottom: 24, color: 'white' }}
          />
        </Tooltip>
        }
      </div>
      <VariableSelectionGroup box={box} onBlur={handleOnBlurGroup} form={form} />
      <FormBoxRemark box={box} onBlur={handleOnBlurRemark} form={form} />
    </div>
  )
}

export default FormCustomTextInfos
