
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  Button,
  Input,
  Radio,
  Row,
  Switch,
  Table,
  Col,
  Tooltip,
  Popconfirm,
  InputNumber,
} from 'antd';
import { useState } from 'react';
import { useStore } from '../../../store/store';
import { convertPadding, getCellStyle, getHeaderStyle, tableBorderStyle } from '../../../utils/table';
import ColorSelector from '../../common/ColorSelector';
import './tableConfig.css';
import { defaultTableConfiguration } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import { maxFontSize, minFontSize } from '../../../constants/gridConfig';
import FontPicker from '../../common/FontPicker';
import TableFooter from '../../Table/TableFooter';

const selector = ({
  templates,
  configuration,
  setSelectedStyle,
  currentTemplate,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
}) => ({
  tableStyles: templates.filter((t) => t.pattern === 'style'),
  templates,
  configuration,
  setSelectedStyle,
  templatable: currentTemplate.templatable,
  createTemplateInStore,
  setConfiguration,
  postTemplate,
});

export const TabsTables = () => {
  const { configuration, setConfiguration } = useStore(selector);
  const { t } = useTranslation();
  const [selectedStyle, setSelectedStyle] = useState(configuration.tableStyles[0]);
  const [newStyleName, setNewStyleName] = useState('');
  const [isEditingStyleName, setIsEditingStyleName] = useState(false);
  let styleList = [...configuration.tableStyles];
  const styleData = [
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: (18 + 16) * 3 + 16,
    },
    {
      title: 'Style',
      dataIndex: 'style',
      key: 'style',
      onCell: (element, rowIndex) => {
        return {
          key: `style-config-${rowIndex}`,
          onClick: (event) => {
            handleSelectedStyle(element);
          },
        };
      },
    }
  ];

  const handleRemoveStyle = (ID) => {
    styleList.forEach((element, index) => {
      if (element.id === ID) {
        styleList.splice(index, 1);
        setConfiguration({
          ...configuration.tableStyles,
          tableStyles: styleList,
        });
      }
    });
  };

  const handleRenameStyle = (ID, event) => {
    let styleToRename = {
      ...configuration.tableStyles.find((element) => element.id === ID),
      name: event.target.value,
    };
    setConfiguration({
      tableStyles: [
        ...configuration.tableStyles.map((tableStyle) => {
          if (tableStyle.id === ID) {
            return styleToRename;
          }
          return tableStyle;
        }),
      ],
    });
    setIsEditingStyleName(null);
    setEditedName('');
    setSelectedStyle(styleToRename);
  };

  const handleCopyStyle = (copiedId) => {
    const copiedStyle = configuration.tableStyles.find((s) => s.id === copiedId);
    let newStyle = {
      ...copiedStyle,
      id: configuration.tableStylesIdCount,
      name: `${copiedStyle.name} - Copie`,
    };
    let tableStyles = [
      ...configuration.tableStyles,
      newStyle,
    ];

    setConfiguration({
      ...configuration,
      tableStyles,
      tableStylesIdCount: configuration.tableStylesIdCount + 1,
    });
  };

  const [editedName, setEditedName] = useState('');
  const dataSourceMapped = [];

  const iconStyle = {
    margin: '.5rem',
    fontSize: '1.5em',
  }
  configuration.tableStyles.forEach((element) => {
    dataSourceMapped.push({
      key: `tableStyle-${element.id}`,
      id: element.id,
      style:
        isEditingStyleName === element.id ? (
          <Input
            value={editedName}
            id='styleNameInput2'
            style={{ width: '60%' }}
            onChange={(e) => setEditedName(e.target.value)}
            onPressEnter={(e) => {
              if (element.id !== 0 && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
            onBlur={(e) => {
              if (element.id !== 0 && e.target.value.length > 0) {
                handleRenameStyle(element.id, e);
              }
            }}
          />
        ) : (
          element.name
        ),
      actions: (
        <div>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Renommer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'edit', style: 'light' })}
              style={{
                ...iconStyle,
                color: element.id === 0 ? 'lightgray' : 'black',
              }}
              onClick={element.id === 0 ? null : (e) => {
                setIsEditingStyleName(element.id);
                setEditedName(element.name);
              }}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Dupliquer'}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'copy', style: 'light' })}
              style={iconStyle}
              onClick={(e) => handleCopyStyle(element.id)}
            />
          </Tooltip>
          <Tooltip
            placement='bottom'
            mouseLeaveDelay='0'
            title={'Supprimer'}
          >
            {element.id === 0 ? (
              <FontAwesomeIcon
                icon={icon({ name: 'trash', style: 'light' })}
                style={{
                  ...iconStyle,
                  color: 'lightgray'
                }}
              />
            ) : (
              <Popconfirm
                title='Voulez vous vraiment supprimer ce style ?'
                onConfirm={() => { handleRemoveStyle(element.id); }}
                okText='Oui'
                cancelText='Non'
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'trash', style: 'light' })}
                  style={{
                    ...iconStyle,
                    color: 'red'
                  }}
                />
              </Popconfirm>
            )}
          </Tooltip>
        </div>
      ),
    });
  });

  const [isAddTableStyleLoading, setIsAddTableStyleLoading] = useState(false);

  const handleSelectedStyle = (e) => {
    configuration.tableStyles.forEach((element) => {
      if (element.id === e.id) {
        setSelectedStyle(element);
      }
    });
    return selectedStyle;
  };

  const loadingAnimation = () => {
    setIsAddTableStyleLoading(true);
    setTimeout(() => {
      setIsAddTableStyleLoading(false);
    }, 1000);
  };

  const handleSaveChanges = (updatedTableStyle) => {
    const updatedTableStyleIndex = configuration.tableStyles.findIndex(
      (element) => element.id === updatedTableStyle.id
    );
    let newTableStyles = [...configuration.tableStyles];
    newTableStyles[updatedTableStyleIndex] = updatedTableStyle;
    setSelectedStyle(updatedTableStyle);
    setConfiguration({
      ...configuration.tableStyles,
      tableStyles: newTableStyles
    });
  };

  const handleAddTableStyle = (e) => {
    loadingAnimation();
    if (newStyleName.length > 0) {
      const newStyle = {
        ...defaultTableConfiguration,
        id: configuration.tableStylesIdCount,
        name: newStyleName,
      };
      let tableStyles = [
        ...configuration.tableStyles,
        newStyle,
      ];
      setConfiguration({
        ...configuration,
        tableStyles,
        tableStylesIdCount: configuration.tableStylesIdCount + 1,
      });
      setSelectedStyle({ ...newStyle });
      setNewStyleName('');
    } else {
      alert('Un nom de template est obligatoire');
    }
  };

  const handleCustomTextColor = (newColor, strippedNumber) => {
    const color = configuration.colors.find(
      (color) => color.label === newColor
    );
    if (strippedNumber === 1) {
      handleSaveChanges({
        ...selectedStyle,
        customTextColor1: color,
      });
    } else if (strippedNumber === 2) {
      handleSaveChanges({
        ...selectedStyle,
        customTextColor2: color,
      });
    }
  };

  const handleCustomBorderColor = (newColor) => {
    const color = configuration.colors.find(
      (color) => color.label === newColor
    );
    handleSaveChanges({ ...selectedStyle, customBorderColor: color });
  };
  const handleFontSizeCells = (newFontSize) => {
    handleSaveChanges({ ...selectedStyle, fontSizeCells: newFontSize });
  }
  const handleFontSizeHeader = (newFontSize) => {
    handleSaveChanges({ ...selectedStyle, fontSizeHeader: newFontSize });
  }
  const handleFontFamilyCells = (newFontFamily) => {
    handleSaveChanges({ ...selectedStyle, fontFamilyCells: newFontFamily });
  }
  const handleFontFamilyHeader = (newFontFamily) => {
    handleSaveChanges({ ...selectedStyle, fontFamilyHeader: newFontFamily });
  }
  const handleCustomHeaderColor = (newColor) => {
    const color = configuration.colors.find(
      (color) => color.label === newColor
    );
    handleSaveChanges({ ...selectedStyle, customHeaderColor: color });
  };

  const handleCustomStrippedClr = (newColor, strippedNumber) => {
    const color = configuration.colors.find(
      (color) => color.label === newColor
    );
    if (selectedStyle.stripped1 === false) {
      handleSaveChanges({ ...selectedStyle, customCellColor: color });
    } else if (strippedNumber === 1 && selectedStyle.stripped1) {
      handleSaveChanges({ ...selectedStyle, strippedCustomClr1: color });
    } else if (strippedNumber === 2 && selectedStyle.stripped1) {
      handleSaveChanges({ ...selectedStyle, strippedCustomClr2: color });
    }
  };

  const handleCustomHeaderTextColor = (newColor) => {
    const color = configuration.colors.find(
      (color) => color.label === newColor
    );
    handleSaveChanges({ ...selectedStyle, customHeaderTextColor: color });
  };

  const tableData = {
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: '30%',
      },
      {
        title: 'Age',
        dataIndex: 'age',
        width: '20%',
        key: 'age',
      },
      {
        title: 'Address',
        dataIndex: 'address',
        width: '50%',
        key: 'address',
      },
    ],
    data: [
      {
        key: 'sample-1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street',
      },
      {
        key: 'sample-2',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
      },
      {
        key: 'sample-3',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
      },
      {
        key: 'sample-4',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
      },
      {
        key: 'sample-5',
        name: 'John',
        age: 42,
        address: '10 Downing Street',
      },
    ],
  };

  tableData.columns.forEach((element, index) => {
    element.onHeaderCell = ({ title }) => ({
      title,
      column: { title },
      width: "33%",
      editable: false,
      tableIsSelected: false,
      style: {
        backgroundColor: selectedStyle.customHeaderColor?.style,
        color: selectedStyle.customHeaderTextColor?.style,
        fontSize: selectedStyle?.fontSizeHeader || configuration.fontSize,
        fontFamily: selectedStyle?.fontFamilyHeader || configuration.fontFamily,
        ...tableBorderStyle(selectedStyle, { isLastColumn: index === tableData.columns.length - 1 })
      },
      disabled: true
    });
    element.onCell = (record) => ({
      dataIndex: element.dataIndex,
      cellContent: record[element.dataIndex],
      width: "33%",
      type: 'text',
      tableIsSelected: false,
      style: {
        backgroundColor: selectedStyle.stripped1
          ? null
          : selectedStyle.customCellColor?.style,
        color: selectedStyle.customTextColor1?.style,
        fontSize: selectedStyle?.fontSizeCells || configuration.fontSize,
        fontFamily: selectedStyle?.fontFamilyCells || configuration.fontFamily,
        ...tableBorderStyle(selectedStyle, { isLastColumn: index === tableData.columns.length - 1 })
      },
      disabled: true
    });
  });

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h5>Liste des styles de tableaux</h5>
        <div
          style={{
            margin: '2rem 0',
            padding: '0 2rem',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Input
            placeholder='Nom du nouveau Style'
            value={newStyleName}
            onChange={(e) => {
              e.preventDefault();
              setNewStyleName(e.target.value);
            }}
            onPressEnter={handleAddTableStyle}
          />
          <Button
            type='primary'
            htmlType='submit'
            loading={isAddTableStyleLoading}
            style={{ marginLeft: '.5rem' }}
            onMouseDown={(e) => e.preventDefault()}
            onClick={handleAddTableStyle}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'plus', style: 'light' })}
              style={{ fontSize: '1.5em' }}
            />
          </Button>
        </div>
        <Table
          columns={styleData}
          dataSource={dataSourceMapped}
          rowClassName={(record, _rowIndex) => { return record.style === selectedStyle.name ? 'tableSelectedRow' : '' }}
          pagination={false}
        />
      </div>
      <div style={{ margin: '2rem 0' }}>
        <Row justify="space-around">
          <Col span={10}>
            <div>En-tête :</div>
            <div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
              <div style={{ marginTop: '1rem' }}>{t('font-size')} :</div>
              <div style={{ marginTop: '.5rem' }}>
                <InputNumber
                  min={minFontSize}
                  max={maxFontSize}
                  value={selectedStyle?.fontSizeHeader || configuration.fontSize}
                  onChange={handleFontSizeHeader}
                  style={{ marginLeft: '5px' }}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>{t('font-family')} :</div>
              <div style={{ marginTop: '.5rem' }}>
                <FontPicker
                  onChange={handleFontFamilyHeader}
                  forcedValue={selectedStyle?.fontFamilyHeader || configuration.fontFamily} />
              </div>
              <div style={{ marginTop: '1rem' }}>Couleur de fond :</div>
              <div style={{ marginTop: '.5rem' }}>
                <ColorSelector
                  style={{ marginTop: '1rem' }}
                  forcedValue={selectedStyle.customHeaderColor?.label}
                  onChange={handleCustomHeaderColor}
                />
              </div>
              <div style={{ marginTop: '1rem' }}>Couleur du texte :</div>
              <div style={{ marginTop: '.5rem' }}>
                <ColorSelector
                  style={{ marginTop: '1rem' }}
                  forcedValue={selectedStyle.customHeaderTextColor?.label}
                  onChange={handleCustomHeaderTextColor}
                />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
              <div style={{ marginRight: '1rem' }}>Pied de tableau :</div>
              <Switch
                checked={selectedStyle.footer}
                onChange={() =>
                  handleSaveChanges({
                    ...selectedStyle,
                    footer: !selectedStyle.footer,
                  })
                }
              />
            </div>
            <div style={{ marginTop: '1rem', marginLeft: '1rem' }}>
              <div>Texte du pied de tableau :</div>
              <Input
                style={{ marginTop: '.5rem' }}
                placeholder='Texte'
                value={selectedStyle.footerData}
                disabled={!selectedStyle.footer}
                onPressEnter={(e) => {
                  e.preventDefault();
                  handleSaveChanges({
                    ...selectedStyle,
                    footerData: e.target.value,
                  });
                }}
                onChange={(e) => {
                  e.preventDefault();
                  handleSaveChanges({
                    ...selectedStyle,
                    footerData: e.target.value,
                  });
                }}
              />
            </div>
            <div style={{ marginTop: '2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '.5rem' }}>
                <div style={{ marginRight: '1rem' }}>Bordure :</div>
                <Switch
                  checked={selectedStyle.bordered}
                  onChange={() => {
                    let newStyle = {
                      ...selectedStyle,
                      bordered: !selectedStyle.bordered
                    };
                    if (!selectedStyle.bordered) {
                      const color = configuration.colors.find(
                        (color) => color.label === 'white'
                      );
                      newStyle.customBorderColor = color;
                    }
                    handleSaveChanges(newStyle);
                  }}
                />
              </div>
              <div style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                <div style={{ marginRight: '1rem' }}>
                  Couleur de la bordure :
                </div>
                <div style={{ marginTop: '.5rem' }}>
                  <ColorSelector
                    forcedValue={selectedStyle.customBorderColor?.label}
                    onChange={handleCustomBorderColor}
                    disabled={!selectedStyle.bordered}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div>Cellules :</div>
            <div style={{ marginLeft: '1rem' }}>
              <div style={{ marginTop: '1rem' }}>{t('font-size')} :</div>
              <div style={{ marginTop: '.5rem' }}>
                <InputNumber
                  min={minFontSize}
                  max={maxFontSize}
                  value={selectedStyle?.fontSizeCells || configuration.fontSize}
                  onChange={handleFontSizeCells}
                  style={{ marginLeft: '5px' }}
                />
              </div>

              <div style={{ marginTop: '1rem' }}>{t('font-family')} :</div>
              <div style={{ marginTop: '.5rem' }}>
                <FontPicker
                  onChange={handleFontFamilyCells}
                  forcedValue={selectedStyle?.fontFamilyCells || configuration.fontFamily} />
              </div>

              <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                <div style={{ marginRight: '1rem' }}>
                  Alternance de couleurs de lignes :
                </div>
                <Switch
                  checked={selectedStyle.stripped1}
                  onChange={() => {
                    let newStyle = { ...selectedStyle, stripped1: !selectedStyle.stripped1 };
                    if (!selectedStyle.stripped1) {
                      const color = configuration.colors.find(
                        (color) => color.label === 'white'
                      );
                      newStyle.strippedCustomClr1 = color;
                      newStyle.strippedCustomClr2 = color;
                    }
                    handleSaveChanges(newStyle)
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: '.5rem'
                }}
              >
                <div style={{ marginRight: '1rem' }}>
                  <ColorSelector
                    forcedValue={
                      selectedStyle.stripped1
                        ? selectedStyle.strippedCustomClr1?.label
                        : selectedStyle.customCellColor?.label
                    }
                    onChange={(e) => handleCustomStrippedClr(e, 1)}
                  />

                </div>
                {selectedStyle.stripped1 ? (
                  <ColorSelector
                    forcedValue={selectedStyle.strippedCustomClr2?.label}
                    onChange={(e) => handleCustomStrippedClr(e, 2)}
                  />
                ) : null}
              </div>
              <div style={{ marginTop: '1rem' }}>
                Couleur du texte de cellules :
              </div>
              <div style={{ marginTop: '.5rem' }}>
                <ColorSelector
                  forcedValue={selectedStyle.customTextColor1?.label}
                  onChange={(e) => handleCustomTextColor(e, 1)}
                />
              </div>
              <div style={{ marginTop: '1rem', marginRight: '1rem' }}>Taille du tableau :</div>
              <Radio.Group
                style={{ marginTop: '.5rem' }}
                value={selectedStyle?.size}
                onChange={(e) => {
                  handleSaveChanges({
                    ...selectedStyle,
                    size: e.target.value,
                  });
                }}
              >
                <Radio.Button value='small'>Petit</Radio.Button>
                <Radio.Button value='middle'>Moyen</Radio.Button>
                <Radio.Button value='default'>Grand</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>
      </div>
      <h4 style={{ textAlign: 'center' }}>Aperçu :</h4>
      <div
        label='table-holder'
        style={{
          width: '80%',
          margin: 'auto',
          fontFamily: configuration.fontFamily,
          colorTextBase: configuration.baseFontColor.style,
          fontSize: configuration.fontSize
        }}
      >
        <table style={{ width: "100%", tableLayout: 'fixed', }}>
          <thead>
            <tr>
              {tableData.columns.map((column, colIdx) => (
                <th
                  key={column.key}
                  style={getHeaderStyle({ tableStyle: selectedStyle, configuration, width: column.width, isLastColumn: tableData.columns.length === colIdx + 1 })}
                >
                  <div style={{
                    padding: convertPadding(selectedStyle?.size).padding,
                    fontWeight: 'normal',
                    textAlign: 'left'
                  }}>
                    {column.title}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.data.map((row, rowIdx) =>
              <tr key={row.key}>
                {Object.entries(row)
                  .filter(([key]) => key !== 'key')
                  .map(([key, value], colIdx) =>
                    <td key={key} style={getCellStyle({
                      tableStyle: selectedStyle,
                      isEven: rowIdx % 2 === 0,
                      configuration,
                      width: tableData.columns[colIdx].width,
                      isLastColumn: tableData.columns.length === colIdx + 1,
                    })}>
                      <div style={{
                        padding: convertPadding(selectedStyle.size).padding,
                        minHeight: 32
                      }}>
                        {value}
                      </div>
                    </td>
                  )}
              </tr>
            )}
          </tbody>
          <TableFooter
            columnsLength={tableData.columns.length}
            tableStyle={selectedStyle}
          />
        </table>
      </div>
    </div>
  );
};
