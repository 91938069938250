import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useMemo, useState } from 'react'
import { antdFormError } from '../../constants/constants'
import { useStore } from '../../store/store'

const DeleteButton = ({
  isFromHeader,
  setControlRowClicked,
  boxId,
  parentKey,
  ...ButtonProps
}) => {
  const updateBox = useStore(({ updateBox }) => updateBox)
  const [isHovered, setIsHovered] = useState(false)

  const basePosition = useMemo(() => {
    return isFromHeader ? "left" : "top"
  }, [isFromHeader])

  const oppositeBasePosition = useMemo(() => {
    return isFromHeader ? "right" : "bottom"
  }, [isFromHeader])

  const secondBasePosition = useMemo(() => {
    return isFromHeader ? "top" : "left"
  }, [isFromHeader])

  const fillValue = useMemo(() => {
    return isFromHeader ? "width" : "height"
  }, [isFromHeader])

  const handleOnMouseEnter = () => {
    setIsHovered(true);
    setControlRowClicked((oldValue) => ({ ...oldValue, isToDelete: true }))
  }

  const handleOnMouseLeave = () => {
    setIsHovered(false);
    setControlRowClicked((oldVal) => ({ ...oldVal, isToDelete: false }))
  }

  const handleOnClick = () => {
    const type = isFromHeader ? "columns" : "data"
    updateBox(boxId, (box) => {
      const targetedElement = box.content[type].find((c) => parentKey === c.key);
      if (targetedElement) {
        if (type === 'columns' && box.content.columns.length > 1) {
          const columnKey = targetedElement.key;
          box.content.data.forEach((row) => delete row[columnKey]);
          const removedColumnWidth = targetedElement.width * -1;
          if (box.content[type].length > 1) {
            const index = box.content[type].findIndex((c) => c.key === parentKey);
            box.content[type].splice(index, 1);
          }
          box.content.columns.forEach((col) => {
            const toAdd = (-removedColumnWidth) / box.content.columns.length;
            const newColumnWidth = col.width + toAdd;
            col.width = newColumnWidth;
          });
        } else if (box.content[type].length > 1) {
          const index = box.content[type].findIndex((c) => c.key === parentKey);
          box.content[type].splice(index, 1);
        }
      }
    });
  }

  return (
    <div style={{
      position: "absolute",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [`${basePosition}`]: 0,
      [`${oppositeBasePosition}`]: 0,
      [`margin-${basePosition}`]: 'auto',
      [`margin-${oppositeBasePosition}`]: 'auto',
      [`${secondBasePosition}`]: -15,
      [`${fillValue}`]: "fit-content",
    }}>
      <Button
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
        icon={<FontAwesomeIcon icon={faXmark}
          style={{
            transition: "color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)",
            color: isHovered ? "white" : "rgba(0, 0, 0, 0.88)",
          }} />}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 20,
          height: 20,
          padding: 0,
          backgroundColor: isHovered ? antdFormError : undefined,
          borderColor: isHovered ? "white" : undefined,
          borderRadius: 20
        }}
        {...ButtonProps}
      />
    </div>
  )
}

export default DeleteButton
