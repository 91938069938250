import ReactDOM from 'react-dom';
import { useStore } from '../../store/store';
// Common
import './Utils.css';
import NavbarSelector from '../Layout/NavbarSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import { ItemTypes, VARIABLE_COLOR, viewerTypes } from '../../constants/constants';
import { useMemo, useState } from 'react';
import { useHandleBoxSelect, useQueryDocumentSections } from '../../hooks';
import { initializeBoxContent, isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { Spin, Radio } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { postPdfInTemplate } from '../../requests/templates';
import VariableSwitcher from './VariableSwitcher';
import { getSelectedSection } from '../../utils/sections';
import HideInFormSwitcher from './HideInFormSwitcher';
import BoxButton from './BoxButton';
const iconFontSize = 25

const selector =
  (id) =>
    ({
      boxes,
      masks,
      injectMask,
      injectSection,
      landscape,
      currentDocument,
      currentProposal,
      updateBox,
      handleSectionAction,
      setLoaderPdfToImage,
      setTempContainerPdfToImage,
      selectedContainer,
      handleUnSelectBox,
      handleUnSelectContainer
    }) => {
      const box = boxes.find((b) => b.id === id);
      const maskList = masks.filter(({ id }) => !["header", "footer"].includes(id))
      let inject;
      let selectedValue;
      if (box.type === ItemTypes.MASK) {
        inject = injectMask
      } else if (box.type === ItemTypes.SECTION_VARIABLE) {
        if (currentDocument.type === 'template') {
          inject = ({ id: optionId, boxId: id }) => updateBox(id, (box) => {
            box.answer = optionId
          })
        } else {
          inject = ({ id: optionId, boxId }) => handleSectionAction({
            optionId,
            boxId,
            sectionId: box.sectionId,
            action: box.questions.find((q) => q.id === optionId).label === 'Oui' ? "inject" : "remove"
          })
        }
        selectedValue = box.answer
      } else {
        inject = injectSection
      }
      return {
        maskList,
        box,
        inject,
        landscape,
        setLoaderPdfToImage,
        setTempContainerPdfToImage,
        selectedContainer,
        documentType: currentDocument.type,
        documentId: currentDocument.type === 'template' ? currentDocument.id : currentProposal.id,
        currentDocument,
        selectedValue,
        currentProposal,
        handleUnSelectBox,
        handleUnSelectContainer
      }
    };

const SelectionBox = ({ id }) => {
  const {
    inject,
    box,
    maskList,
    landscape,
    documentType,
    documentId,
    selectedValue,
    setLoaderPdfToImage,
    setTempContainerPdfToImage,
    selectedContainer,
    currentDocument,
    currentProposal,
    handleUnSelectBox,
    handleUnSelectContainer
  } = useStore(selector(id));
  const { data: sectionList, isLoading } = useQueryDocumentSections({ documentType, documentId })
  const { t } = useTranslation();
  const { updateLeftShownStatus, overlayProps } = useOverlay();
  const shownStatus = overlayProps?.left?.shownStatus
  const [drawPdfToImage, setDrawPdfToImage] = useState(true)
  const [linkedSection, setLinkedSection] = useState(currentDocument.type !== "template" ? false : true)
  const handleBoxSelect = useHandleBoxSelect({ box })
  const isSelected = useStore(({ selectedBoxId }) => selectedBoxId === id)
  const dropBoxInColumn = useStore(({ dropBoxInColumn }) => dropBoxInColumn)
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration)
  const configuration = useStore(({ configuration }) => configuration)
  const column = useStore(({ columns }) => columns.find(({ id }) => id === box.columnId))

  const selectionList = useMemo(() => {
    let list = []
    if (box.type === ItemTypes.MASK) {
      list = maskList
    } else if (box.type === ItemTypes.SECTION_VARIABLE) {
      list = box.questions.map((q) => ({ ...q, name: q.label }))
    }
    else {
      list = sectionList?.filter(({ id }) => id !== (currentDocument.type === 'template'
        ? currentDocument.id
        : currentProposal.id)) || []
    }
    return list.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      }
    });
  }, [box.type, box.questions, maskList, sectionList, currentDocument, currentProposal]);

  const selectedSectionVariable = useMemo(() => {
    if (box.type === ItemTypes.SECTION_VARIABLE) return getSelectedSection(box.sectionId, sectionList)
    return null
  }, [box?.sectionId, box.type, sectionList])


  const handleSelect = (optionId) => {
    inject({ id: optionId, boxId: id, linked_section: box.type !== ItemTypes.SECTION_VARIABLE && linkedSection })
    handleUnSelectBox();
    handleUnSelectContainer();
  };

  const handlePdfSelect = (event) => {
    setTempContainerPdfToImage({ id: selectedContainer.id, drawMode: drawPdfToImage })
    postPdfInTemplate({ id: documentId, boxId: id, file: event.target.files[0], setLoaderPdfToImage, currentDocumentType: documentType })
  }

  const iconElement = () => {
    if (box.type === ItemTypes.MASK) return < FontAwesomeIcon
      icon={icon({ name: 'masks-theater', style: 'regular' })}
      style={{ marginRight: "8px" }} />
    if (box.type === ItemTypes.SECTION_VARIABLE) return < FontAwesomeIcon
      icon={icon({ name: 'question', style: 'regular' })}
      style={{ marginRight: "8px" }} />
    return < FontAwesomeIcon
      icon={icon({ name: 'rectangle-history-circle-plus', style: 'regular' })}
      style={{ marginRight: "8px" }} />
  }

  const placeholder = useMemo(() => {
    if (box.type === ItemTypes.SECTION_VARIABLE) {
      if (documentType === 'template') return t("answer-only-proposal")
      return t("answer")
    }
    return t(box.type)
  }, [box.type, documentType, t])


  const renderPdfSelector = () => <div style={{ display: "flex" }}>
    <input style={{ width: 300 }} type='file' accept='application/pdf' onChange={handlePdfSelect} />
    <div style={{ display: 'flex' }}>
      <Radio.Group name="radiogroup" defaultValue={1} onChange={() => setDrawPdfToImage(prev => !prev)}>
        <Radio value={1}>Avec En-tête et pied de page</Radio>
        <Radio value={2}>Pleine page</Radio>
      </Radio.Group>
    </div>
    {isSelected && (shownStatus !== "variables" || box.fromViewer === viewerTypes.VARIABLES) &&
      ReactDOM.createPortal(
        <>
          <HideInFormSwitcher boxId={box.id} />
          <VariableSwitcher boxId={box.id} />
        </>,
        document.getElementById('ComponentPortal')
      )}
  </div>

  const renderNavbarSelector = () => <div style={{ display: "flex" }
  }>
    <NavbarSelector
      width={(landscape ? pageHeight : pageWidth) / 1.5}
      tooltipContent={t(box.type)}
      suffixIcon={iconElement()}
      onSelect={handleSelect}
      options={selectionList}
      placeholder={placeholder}
      selectedValue={selectedValue}
      disabled={box.type === ItemTypes.SECTION_VARIABLE && documentType === 'template'}
    />
    {currentDocument.type === 'template' && box.type !== ItemTypes.SECTION_VARIABLE && box.type !== ItemTypes.MASK &&
      <div style={{ alignItems: "center", display: "flex", marginLeft: 10, width: "50%" }}>
        <Radio.Group name="radiogroup" defaultValue={1} onChange={() => setLinkedSection(prev => currentDocument.type === "opportunity" ? false : !prev)} >
          <Radio value={1}>Liée</Radio>
          <Radio value={2}>Édition</Radio>
        </Radio.Group>
        <BoxButton
          type={ItemTypes.SECTION_VARIABLE}
          text={t('section_variable')}
          icon={< FontAwesomeIcon icon={icon({ name: 'rectangle-history-circle-plus', style: 'light' })} style={{ fontSize: iconFontSize }} />}
          bgcolor={VARIABLE_COLOR}
          disp='inline-block'
          color='#ffff'
          canDrag={false}
          marginBottom={0}
          marginLeft='auto'
          onClick={() => {
            const newBox = initializeBoxContent({
              box: {
                type: ItemTypes.SECTION_VARIABLE,
                ...gridConfiguration[ItemTypes.SECTION_VARIABLE],
                copied: false,
              },
              configuration,
              column,
              parentId: box.columnId
            });
            dropBoxInColumn({
              id: box.columnId,
              newBox,
              updateContainerHeight: true,
              drawMode: true,
              boxIdToReplace: id
            })
          }}
        />
      </div>
    }
  </div>

  return (
    <div
      id={`box-${box?.id ? box.id : 'new'}`}
      onDoubleClick={(event) => {
        if (isAVariableBox(box?.type)) {
          updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
        }
        event.stopPropagation()
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        backgroundColor: "#bfbfbf",
        flexDirection: "column",
        rowGap: 8
      }}
      onClick={(event) => {
        handleBoxSelect(event)
      }}
    >
      {box.type === ItemTypes.SECTION_VARIABLE && <>
        <div>
          {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
            <>
              {selectedSectionVariable && `Voulez-vous affichez la section ${selectedSectionVariable.name} ?`}
              {!selectedSectionVariable && box.sectionId && "La section à afficher n'est plus disponible"}
              {!selectedSectionVariable && !box.sectionId && box?.name?.getCurrentContent().getPlainText()}
            </>
          }
        </div>
        {selectedSectionVariable && renderNavbarSelector()}
      </>}
      {box.type !== ItemTypes.SECTION_VARIABLE && box.type !== ItemTypes.FILE_PDF && box.type !== ItemTypes.FILE_PDF_VARIABLE && box.type !== ItemTypes.SECTION_LINK && renderNavbarSelector()}
      {(box.type === ItemTypes.FILE_PDF || box.type === ItemTypes.FILE_PDF_VARIABLE) && renderPdfSelector()}
    </div>
  );
};

export default SelectionBox;
