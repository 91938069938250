import React from 'react'
import { useStore } from '../../store/store';
import { columnDefaultDisposition, ItemTypes, MASKS_TYPE } from '../../constants/constants';
import { initializeBoxContent, isADrawBox, isATitleBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { computeColumnWidth } from '../../utils/columns';
import { gridUnit } from '../../constants/gridConfig';
import BoxButtonsWrapper from '../common/BoxButtonsWrapper';
const selector = (columnId, containerId) => ({
  addContainers,
  updateColumnsInContainer,
  containers,
  gridConfiguration,
  configuration,
  currentDocument,
  boxes,
  landscape,
  copy
}) => {
  const container = columnId || containerId ? containers.find((c) => containerId ?
    c.id === containerId : c.columnsIds.includes(columnId))
    : undefined
  return {
    addContainers,
    updateColumnsInContainer,
    container,
    gridConfiguration,
    configuration,
    titles: boxes.filter((box) => isATitleBox(box.type)),
    currentDocument,
    marginRight: configuration.margins.right * gridUnit,
    marginLeft: configuration.margins.left * gridUnit,
    landscape,
    copy
  }
};

const AdderMenu = ({ containerIndex, closeMenu, columnId, columnIndex, containerId }) => {
  const { addContainers, updateColumnsInContainer, container, gridConfiguration, configuration, titles, landscape, marginLeft, marginRight, copy } = useStore(selector(columnId, containerId));
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))

  const onAdderClick = ({ type, config, columnId, fromCopied = false }) => {
    let index;
    if (containerIndex === 0) { index = 0 } else {
      index = containerIndex
    }
    if (fromCopied && copy.type === ItemTypes.CONTAINER_COPIED) {

      let newBoxes = copy.boxes.map((b) => initializeBoxContent({
        box: b,
        configuration,
        titles,
      }));

      addContainers({
        index,
        drawMode: copy.drawMode,
        columnsSizes: copy.drawMode ? [1] : copy.boxes.map((b) => b.colSize),
        box: newBoxes.map(({ colSize, ...rest }) => rest),
        fromCopiedContainer: { container_height: copy.copiedContainer.height, maskId: copy.maskId }
      })
      closeMenu();
      return
    }
    const box = (copy && fromCopied) ? { ...copy } : { type, ...config[type], copied: false }

    if (columnId || containerId) {
      updateColumnsInContainer({
        columnsSizes: columnDefaultDisposition[container.columnsIds.length + 1],
        index: columnIndex,
        containerId: container.id,
        newBoxes: [box]
      });
      closeMenu();
      return;
    }

    let newBox = initializeBoxContent({
      box,
      configuration,
      titles,
    });
    const drawMode = isADrawBox(box.type);

    if (!drawMode) {
      newBox = reinitializeBoxWidthAndHeight({
        box: newBox,
        columnWidth: computeColumnWidth({
          landscape,
          marginRight,
          marginLeft
        }),
        onCreate: true,
        header,
        footer
      })
    }

    addContainers({
      index,
      drawMode,
      box: newBox,
    })
    closeMenu();
  }

  const onClick = ({ type, fromCopied = false }) => {
    onAdderClick({ type, config: gridConfiguration, columnId, fromCopied })
  }

  return (
    <div style={{
      position: "absolute",
      // top: "calc(100%)",
      padding: 16,
      boxShadow: "rgb(94, 94, 94) 0px 0px 15px -4.6px",
      display: "flex",
      flexDirection: "column",
      gap: 32,
      borderRadius: 12,
      width: 250,
      zIndex: 1001,
      backgroundColor: "#0B2239"
    }}>
      <div style={{
        display: "flex", alignItems: "baseline", whiteSpace: "nowrap", gap: 10, flexDirection: "column"
      }}>
        <div style={{ fontSize: 15, display: "flex", justifyContent: "center", width: "100%", color: "white" }}>{columnId ? "Ajouter une colonne" : "Ajouter une ligne"}</div>

        <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
          <BoxButtonsWrapper onClick={onClick} showCopy={
            (copy != null && columnId &&
              (copy.type === ItemTypes.SHAPE || copy.type === ItemTypes.LINE || copy.type === ItemTypes.IMG || copy.type === ItemTypes.TEXT ||
                copy.type === ItemTypes.CHECKBOXS_VARIABLE || copy.type === ItemTypes.TEXT_VARIABLE || copy.type === ItemTypes.SELECTOR_VARIABLE)) ||
            (copy !== null && !columnId)}
            columnId={columnId}
            canDrag={false} />
        </div>
      </div>
    </div >
  )
}

export default AdderMenu
