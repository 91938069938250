import React from 'react'
import { Divider, } from 'antd';



const GenerateDivider = ({ text }) => {
  return (
    <Divider
      style={{
        borderColor: 'white',
        color: 'white',
        width: '212px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginTop: 8,
        marginBottom: 8,
      }}
      plain
    >
      {text}
    </Divider>
  );
};

export default GenerateDivider
