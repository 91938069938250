import { useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import { rotatedBoxDelta } from '../../utils/boxes';
import { gridWidth } from '../../constants/gridConfig';
import { MIN_COLUMN_WIDTH } from '.';
import { useStore } from '../../store/store';
import { defaultZoom, dynamicZoom } from '../../constants/constants';

const selector = ({ updateBox, setEventManagerEnabled, configuration }) => ({
  updateBox,
  setEventManagerEnabled,
  configuration
})

const ColumnResizer = ({
  rotation,
  setColumnSizeVars,
  columnKey,
  nextColKey,
  boxId,
  resizeEnabled,
  computeMaxColumnWidth,
  setColumnLineHovered
}) => {
  const resizableRef = useRef(null);
  const headersSizesRef = useRef()
  const [hasClicked, setHasClicked] = useState(false)
  const { updateBox, configuration } = useStore(selector)

  const resizeColumn = (...args) => {
    setColumnSizeVars((oldColumnSizeVars) => {
      const zoomFactor = (configuration?.zoom || dynamicZoom() || defaultZoom) / 100;

      let colSize = oldColumnSizeVars[`--${columnKey}-size`];
      let nextColSize = oldColumnSizeVars[`--${nextColKey}-size`];
      const x = args[0].movementX / zoomFactor;
      const y = args[0].movementY / zoomFactor;
      const resizeMove = rotatedBoxDelta({ rotation, direction: args[1], x, y });
      const nextColTooSmall = nextColSize && resizeMove.width > 0 && nextColSize <= MIN_COLUMN_WIDTH;
      const colTooSmall = resizeMove.width < 0 && colSize <= MIN_COLUMN_WIDTH;

      if (nextColTooSmall || colTooSmall || resizeMove.width === 0) {
        headersSizesRef.current = oldColumnSizeVars
        return oldColumnSizeVars;
      }
      const newWidth = Math.max(colSize + resizeMove.width, gridWidth);
      const widthDelta = newWidth - colSize;
      if (nextColSize) {
        if (nextColSize <= MIN_COLUMN_WIDTH && widthDelta > 0) {
          headersSizesRef.current = oldColumnSizeVars
          return oldColumnSizeVars;
        }
        if (widthDelta > 0) {
          if (nextColSize <= MIN_COLUMN_WIDTH) {
            headersSizesRef.current = oldColumnSizeVars
            return oldColumnSizeVars;
          }
          const allowedDelta = nextColSize - MIN_COLUMN_WIDTH;
          if (allowedDelta >= 0) {
            if (allowedDelta >= widthDelta) {
              nextColSize -= widthDelta;
              colSize += widthDelta;
            } else {
              nextColSize -= allowedDelta;
              colSize += allowedDelta;
            }
          } else {
            headersSizesRef.current = oldColumnSizeVars
            return oldColumnSizeVars;
          }
        } else {
          if (colSize <= MIN_COLUMN_WIDTH) {
            headersSizesRef.current = oldColumnSizeVars
            return oldColumnSizeVars;
          }
          const allowedDelta = colSize - MIN_COLUMN_WIDTH;
          if (allowedDelta >= 0) {
            if (allowedDelta >= - widthDelta) {
              nextColSize -= widthDelta;
              colSize += widthDelta;
            } else {
              nextColSize -= allowedDelta;
              colSize += allowedDelta;
            }
          } else {
            headersSizesRef.current = oldColumnSizeVars
            return oldColumnSizeVars;
          }
        }
        const newColumnSizeVars = {
          ...oldColumnSizeVars,
          [`--${nextColKey}-size`]: nextColSize,
          [`--${columnKey}-size`]: colSize,
        }
        headersSizesRef.current = newColumnSizeVars
        return newColumnSizeVars
      }
      headersSizesRef.current = oldColumnSizeVars
      return oldColumnSizeVars
    }
    )
  }

  const handleResizeStop = () => {
    setHasClicked(false)
    if (headersSizesRef.current) {
      updateBox(boxId, (box) => {
        box.content.columns.forEach(({ key }, idx) => {
          const col = box.content.columns[idx];
          if (headersSizesRef.current && headersSizesRef.current[`--${key}-size`]) {
            col.width = headersSizesRef.current[`--${key}-size`]
          }
        })
        headersSizesRef.current = undefined
        box = { ...box }
      });
    }
  }

  const handleOnMouseOver = () => {
    setColumnLineHovered({ headerId: columnKey, fromLeft: false })
  }

  const handleOnMouseLeave = () => {
    if (!hasClicked)
      setColumnLineHovered({ headerId: null, fromLeft: null })
  }

  const handleOnClick = () => {
    setHasClicked(true)
  }

  return (

    <Resizable
      ref={resizableRef}
      enable={{
        top: false,
        right: resizeEnabled,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }}
      handleStyles={{
        right: { zIndex: 1000 },
      }}
      minWidth={MIN_COLUMN_WIDTH}
      maxWidth={computeMaxColumnWidth(columnKey)}
      style={{ position: 'absolute', top: 0 }}
      size={{ width: "100%", height: "100%" }}
      onResize={resizeColumn}
      onResizeStop={handleResizeStop}
      onClick={handleOnClick}
      onResizeStart={handleOnClick}
      handleComponent={{
        right: <div
          onMouseOver={handleOnMouseOver}
          onMouseLeave={handleOnMouseLeave}
          style={{
            width: "100%",
            height: "100%"
          }} />
      }}
    />
  )
};

export default ColumnResizer;
