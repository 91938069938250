import React from 'react'
import { variableStyle } from '../../../utils/styles'
import TriggerArray from './TriggerArray'
import FormCheckboxInfos from './FormCheckboxInfos'
import DefaultValueBox from './DefaultValueBox'
import HideInFormSwitcher from '../../common/HideInFormSwitcher'
import { ItemTypes } from '../../../constants/constants'

const LeftPart = ({ box, onBlur, form, updateBoxFn }) => {

  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      {box.type !== ItemTypes.QUESTION &&
        <div style={{ marginBottom: 16, fontWeight: "bold" }}>
          <HideInFormSwitcher boxId={box.id} />
        </div>
      }
      <FormCheckboxInfos box={box} onBlur={onBlur} form={form} updateBoxFn={updateBoxFn} />
    </div>
  )
}

const RightPart = ({ box, variablesGroupedByContainer }) => {
  return (
    <div className='variable-editor__middle' style={variableStyle["middle"]}>
      <DefaultValueBox box={box} />
      <TriggerArray box={box} variablesGroupedByContainer={variablesGroupedByContainer} />
    </div>
  )
}

const FormCheckbox = ({ box, onBlur, form, formPart, variablesGroupedByContainer }) => {

  return (
    <>
      {formPart === 'left' && <LeftPart box={box} onBlur={onBlur} form={form} />}
      {formPart === 'right' && <RightPart box={box} variablesGroupedByContainer={variablesGroupedByContainer} />}
    </>
  )
}

export default FormCheckbox
