import React, { useMemo, useRef } from 'react'
import { useOutsideClickHandler } from '../../utils/browser';
import AdderButton from './AdderButton';
import DeleteButton from './DeleteButton';

const ControlRow = ({
  isControlRowHovered,
  isControlRowClicked,
  setControlRowHovered,
  setControlRowClicked,
  parentRowId,
  isFirstElement,
  isLastElement,
  isFromHeader = true,
  parentRowType,
  isControlRowAfterHovered,
  isControlRowAfterClicked,
  setParentLineHovered,
  rowLineHovered,
  columnLineHovered,
  zIndex,
  columnLength,
  boxId,
  placementIndex,
  parentKey
}) => {
  const borderLeftTable = isFirstElement ? 2 : 1
  const borderRightTable = isLastElement ? 2 : 1
  const ref = useRef()
  useOutsideClickHandler(ref, (event) => {
    if (isControlRowClicked && !event.target.closest(".pop-menu-btn")) {
      setControlRowClicked({ parentRowId: null, parentRowType: null })
    }
  });

  const wrapperStyle = useMemo(() => {
    const firstBasePosition = isFromHeader ? "top" : "left"
    const secondBasePosition = isFromHeader ? "left" : "top"
    const sizedValue = isFromHeader ? "height" : "width"
    const fullValue = isFromHeader ? "width" : "height"

    return {
      position: 'absolute',
      [`${sizedValue}`]: 18,
      [`${firstBasePosition}`]: -20,
      [`${secondBasePosition}`]: -borderLeftTable,
      [`${fullValue}`]: `calc(100% + ${borderLeftTable}px + ${borderRightTable}px)`,
      zIndex: zIndex ?? 101
    }
  }, [borderLeftTable, borderRightTable, isFromHeader, zIndex])

  const rowBorders = useMemo(() => {
    const borderBase = `solid black`
    const fullBorder = isFromHeader ? "borderTop" : "borderLeft"
    const firstHalfBorder = isFromHeader ? "borderRight" : "borderBottom"
    const firstRadiusBorder = isFromHeader ? "borderTopRightRadius" : "borderBottomLeftRadius"
    const secondHalfBorder = isFromHeader ? "borderLeft" : "borderTop"
    const hiddenBorder = isFromHeader ? "borderBottom" : "borderRight"

    return {
      [`${fullBorder}`]: `1px ${borderBase}`,
      [`${firstHalfBorder}`]: `${borderRightTable / 2}px ${borderBase}`,
      [`${firstRadiusBorder}`]: isLastElement ? 4 : 0,
      [`${secondHalfBorder}`]: `${borderLeftTable / 2}px ${borderBase}`,
      borderTopLeftRadius: isFirstElement ? 4 : 0,
      [`${hiddenBorder}`]: 'none',
    }
  }, [isFromHeader, borderRightTable, isLastElement, borderLeftTable, isFirstElement])

  const rowStyle = useMemo(() => {
    const basePosition = isFromHeader ? "bottom" : "right"
    const fullValue = isFromHeader ? "width" : "height"
    const sizedValue = isFromHeader ? "height" : "width"

    return {
      backgroundColor: "#efefef",
      position: 'absolute',
      [`${fullValue}`]: "100%",
      [`${sizedValue}`]: 10,
      [`${basePosition}`]: 0,
      ...rowBorders
    }
  }, [isFromHeader, rowBorders])

  const handleOnMouseOver = () => {
    setControlRowHovered({ parentRowId, parentRowType })
  }

  const handleOnMouseLeave = () => {
    setControlRowHovered({ parentRowId: null, parentRowType: null })
  }

  const handleOnClick = () => {
    setControlRowClicked({ parentRowId, parentRowType })
  }

  return (
    <div style={wrapperStyle}>
      {!isControlRowClicked && !isControlRowAfterClicked && <>
        {isFirstElement && <AdderButton
          isLeftButton
          isFromHeader={isFromHeader}
          isControlRowHovered={isControlRowHovered}
          isControlRowAfterHovered={isControlRowAfterHovered}
          setParentLineHovered={setParentLineHovered}
          isRowLineHovered={!isFromHeader && rowLineHovered?.rowId === parentRowId && rowLineHovered?.fromTop}
          isRowLineClicked={!isFromHeader && rowLineHovered?.rowId === parentRowId && rowLineHovered?.fromTop && rowLineHovered?.isClicked}
          isColumnLineHovered={isFromHeader && columnLineHovered?.headerId === parentRowId && columnLineHovered?.fromLeft}
          columnLength={columnLength}
          boxId={boxId}
          placementIndex={0}
        />}
        <AdderButton
          isLeftButton={false}
          isFromHeader={isFromHeader}
          isControlRowHovered={isControlRowHovered}
          isControlRowAfterHovered={isControlRowAfterHovered}
          setParentLineHovered={setParentLineHovered}
          isRowLineHovered={!isFromHeader && rowLineHovered?.rowId === parentRowId && !rowLineHovered?.fromTop}
          isRowLineClicked={!isFromHeader && rowLineHovered?.rowId === parentRowId && !rowLineHovered?.fromTop && rowLineHovered?.isClicked}
          isColumnLineHovered={isFromHeader && columnLineHovered?.headerId === parentRowId && !columnLineHovered?.fromLeft}
          columnLength={columnLength}
          boxId={boxId}
          placementIndex={placementIndex}
        />
      </>
      }
      <div style={{
        width: "100%",
        height: "100%",
        cursor: 'pointer'
      }}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
        ref={ref}
      >
        {isControlRowClicked && !(isFirstElement && isLastElement) && <DeleteButton
          isFromHeader={isFromHeader}
          setControlRowClicked={setControlRowClicked}
          boxId={boxId}
          parentKey={parentKey}
        />}
        <div style={rowStyle} className={`${isControlRowHovered || isControlRowClicked ? "control-row-hovered" : ""}`}>
          {!isFromHeader && <div style={{
            fontSize: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: 1,
            height: "100%"
          }}>{placementIndex.toString()?.split('').map((ch) => <div>{ch}</div>)}</div>}
        </div>
      </div>
    </div>
  )
}

export default ControlRow
