import React from 'react'
import { useStore } from '../../store/store';
import { ItemTypes, ItemTypesForTraduction, variablesIcons } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import BoxButton from './BoxButton';

const DynamicContainerIcon = ({ containerType, iconFontSize = 25, secondIconFontSizeReduction = 10 }) => {
  if (containerType === "drawMode") {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      <FontAwesomeIcon icon={icon({ name: 'scribble', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, color: "white" }} />
    </span>
  }
  else if (containerType === "mask") {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      <FontAwesomeIcon icon={icon({ name: 'masks-theater', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, color: "white" }} />
    </span>
  }
  else {
    return <span className="fa-layers" style={{ width: '21px' }}>
      <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: "white" }} />
      < FontAwesomeIcon icon={variablesIcons[ItemTypes.CONTAINER_COPIED]} style={{ fontSize: iconFontSize - 12, color: 'white' }} />
    </span>
  }
}

const dynamicTextIcon = ({ containerType }) => {
  if (containerType === "drawMode") return "drawing"
  if (containerType === "mask") return ItemTypes.MASK
  return ItemTypes.CONTAINER_COPIED
}

const WhatIsCopied = ({ type, containerType = null, iconFontSize = 25, onClick, secondIconFontSizeReduction = 10, copy }) => {
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration);
  const { t } = useTranslation();

  switch (type) {
    case 'text': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TEXT}
          text={t('text')}
          icon={< FontAwesomeIcon icon={icon({ name: 'pen-fancy', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'image': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.IMG}
          text={t('image')}
          icon={< FontAwesomeIcon icon={icon({ name: 'image', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'shape': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.SHAPE}
          text={t('shape')}
          icon={< FontAwesomeIcon icon={icon({ name: 'star', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'line': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.LINE}
          text={t('line')}
          icon={< FontAwesomeIcon icon={icon({ name: 'dash', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'table': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TABLE}
          text={t('table')}
          icon={< FontAwesomeIcon icon={icon({ name: 'table', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'title1': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TITLE}
          text={t('title1')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'title2': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TITLE_2}
          text={t('title2')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'title3': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TITLE_3}
          text={t('title3')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'title4': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TITLE_4}
          text={t('title4')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 't', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: '1', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction, position: 'relative', left: '8px' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case 'summary': {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.SUMMARY}
          text={t('summary')}
          icon={<span className="fa-layers" style={{ width: '21px', color: 'white' }}>
            <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
            <FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={{ fontSize: iconFontSize - secondIconFontSizeReduction }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.LINE_BREAK: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.LINE_BREAK}
          text={t(ItemTypes.LINE_BREAK)}
          icon={< FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.CHECKBOXS_VARIABLE: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.CHECKBOXS_VARIABLE}
          text={t(ItemTypes.CHECKBOXS_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.CHECKBOXS_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.SELECTOR_VARIABLE: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.SELECTOR_VARIABLE}
          text={t(ItemTypes.SELECTOR_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.SELECTOR_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.TEXT_VARIABLE: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TEXT_VARIABLE}
          text={t(ItemTypes.TEXT_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.TEXT_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.IMG_VARIABLE: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.IMG_VARIABLE}
          text={t(ItemTypes.IMG_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.IMG_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.TABLE_VARIABLE: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.TABLE_VARIABLE}
          text={t(ItemTypes.TABLE_VARIABLE)}
          icon={< FontAwesomeIcon icon={variablesIcons[ItemTypes.TABLE_VARIABLE]} style={{ fontSize: iconFontSize, color: 'white' }} />}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.SPACE: {
      return (
        <BoxButton
          onClick={onClick}
          type={ItemTypes.SPACE}
          text={t(ItemTypes.SPACE)}
          icon={<span className="fa-layers" style={{ width: '21px' }}>
            <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize, color: 'white' }} />
            <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-and-arrow-down-left-from-center', style: 'regular' })} style={{ fontSize: iconFontSize - 14, transform: 'rotate(135deg)', color: 'white' }} />
          </span>}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
          copy={copy}
        />
      );
    }
    case ItemTypes.CONTAINER_COPIED: {
      return (
        <BoxButton
          onClick={onClick}
          copy={copy}
          type={ItemTypes.CONTAINER_COPIED}
          text={t(dynamicTextIcon({ containerType }))}
          icon={DynamicContainerIcon({ containerType })}
          config={gridConfiguration}
          bgcolor='#306CC8'
          disp='inline-block'
        />
      );
    }
    default:
      break;
  }
};

const CopyItem = ({ copy, onClick, size = 38 }) => {

  let containerType = null
  if (copy?.drawMode) containerType = 'drawMode'
  if (copy?.maskId) containerType = 'mask'

  return (
    <div
      style={{
        width: size,
        height: size,
        marginLeft: '5px',
      }}
    >
      {WhatIsCopied({ type: ItemTypesForTraduction[copy.type], containerType, onClick, copy })}
    </div>
  );
};

export default CopyItem
