import { message } from 'antd';
import { instance } from '../../requests/axios.instance';

export const deleteProposal = async (id) => {
  try {
    const res = await instance.delete(`/proposal/${id}`);
    if (res.status === 204) {
      message.success('Le proposal a bien été supprimé.');
      return res.data;
    }
    throw new Error(`DELETE proposal ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le proposal n'a pas pu être supprimé.");
    return false;
  }
};

export const renameProposal = async (id, newName) => {
  try {
    const res = await instance.post(`/proposal/${id}/updatename`, {
      name: newName,
    });
    if (res.status === 200) {
      message.success(`Le proposal ${res.name} a bien été renommé.`);
      return res.data;
    }
    throw new Error(`RENAME proposal ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le proposal n'a pas pu être renommé.");
    return false;
  }
};

export const duplicateProposal = async (id, newName, oldName) => {
  try {
    const res = await instance.post(`/proposal/${id}/duplicate`, {
      name: newName,
    });
    if (res.status === 200) {
      message.success(`Le proposal ${oldName} a bien été dupliqué.`);
      return res.data;
    }
    throw new Error(`DUPLICATE proposal ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le proposal n'a pas pu être dupliqué.");
    return false;
  }
};

export const createProposal = async (typeId, proposalContent, type) => {
  let res = '';
  try {
    if (type === 'opportunity')
      res = await instance.post(
        `/opportunities/${typeId}/proposal`,
        proposalContent
      );
    else if (type === 'offer')
      res = await instance.post(`/offers/${typeId}/proposal`, proposalContent);
    if (res.status === 200) {
      message.success(`Le proposal a bien été créé.`, 1);
      return res.data;
    }
    throw new Error(`CREATE proposal status code ${res.status}`);
  } catch (error) {
    message.success("Le proposal n'a pas pu être créé.", 1);
    return false;
  }
};
