import { Switch } from 'antd'
import React from 'react'
import { isAVariableBox } from '../../utils/boxes'
import { useStore } from '../../store/store'
import { useOverlay } from '../../contexts/OverlayContext'

const VariableSwitcher = ({ boxId }) => {
  const box = useStore(({ boxes, }) => boxes.find((b) => b.id === boxId))
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  const boxVariableSwitcher = useStore(({ boxVariableSwitcher }) => boxVariableSwitcher)
  const boxVariableSelectedId = useStore((state) => state.boxVariableSelectedId)
  const { overlayProps } = useOverlay();
  const shownStatus = overlayProps.left.shownStatus

  if (!isTemplate || !box) return null

  return (
    <div style={{
      display: 'flex',
      marginRight: 4,
      alignItems: 'center',
      color: 'white'
    }}>
      <span>Valeurs conditionnelles : </span>
      <Switch
        disabled={(shownStatus === "variables" && boxVariableSelectedId) || (isAVariableBox(box.type) && !box.hideInForm)}
        checked={isAVariableBox(box.type)}
        style={{
          backgroundColor: isAVariableBox(box.type) ? undefined : 'gray'
        }}

        onChange={() =>
          boxVariableSwitcher(box.id)
        }
      />
    </div>
  )
}

export default VariableSwitcher
